import {
  CardContent,
  ErrorBoundary,
  ScrollBarWithFade,
} from '@payler/ui-components';
import {
  Flex,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { tableStyleConfig } from '../tableStyleConfig';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { TableSkeleton } from '../TableSkeleton';
import { TextStyles } from '@payler/ui-theme';
import { TableFooter } from '../TableFooter';
import { SegmentedPicker } from '@payler/ui-components';
import {
  usePrefetchTransfersQuery,
  useSetFxTransferIdToUrl,
  useSetIncomingTransferIdToUrl,
  useSetOutgoingTransferIdToUrl,
  useTransfersQuery,
  useTransfersQueryPagerProps,
} from '../../hooks/transfers/queries';
import { LoaderBox, useBankBreakpointValue } from '@payler/ui/client-office';
import { TTransferDto, TTransfersQueryParams } from '@payler/api/client-office';
import { useLanguageFeatures } from '@payler/ui/client-office';
import { useTranslation } from 'react-i18next';
import { useCloseAllAccountsDrawer } from '../../components/AllAccountsDrawer/AllAccountsDrawer';
import { useTransferFiltersDrawer } from '../../drawers/TransferFiltersDrawer/transfer-filters-state';
import { useSelectedTransfersFiltersCount } from '../../hooks/transfers/url';
import { ListIcon } from '../../components/ListIcon/ListIcon';
import { EmptyWallet } from '@payler/ui-icons';
import {
  useSetURLTransferTableType,
  useTransferTableType,
} from '../../hooks/use-transfer-table-type';
import { TRANSFER_TABLE_TYPES, TTransferTableType } from '../../const';
import { getLegalEntityName } from '../../helpers/getLegalEntityName';

export const ActivitiesTable = () => {
  return (
    <CardContent
      p={0}
      bg="primary.25"
      borderRadius="md"
      overflow="hidden"
      width="100%"
      boxShadow="segmentedPickerOption"
    >
      <ErrorBoundary>
        <React.Suspense fallback={<LoaderBox variant="table" />}>
          <Transfers />
        </React.Suspense>
      </ErrorBoundary>
    </CardContent>
  );
};

const Transfers = () => {
  const paginationProps = useTransfersQueryPagerProps();
  usePrefetchTransfersQuery(paginationProps.totalPages);
  return (
    <>
      <TransferTableTypeSwitcher />
      <TransfersTableWithData />
      <TableFooter paginationProps={paginationProps} />
    </>
  );
};

const TransferTableTypeSwitcher = () => {
  const setType = useSetURLTransferTableType();
  const direction = useTransferTableType();
  const isMobile = useBankBreakpointValue({ base: true, md: false });

  const handleChange = (idx: number) =>
    setType(TRANSFER_TABLE_TYPES[idx] as TTransferTableType);

 const pickerOptions = TRANSFER_TABLE_TYPES.map((type) => type === 'Fx' ? `Exchange` : type);

  return (
    <SegmentedPicker
      options={pickerOptions}
      onSelectionChange={handleChange}
      selection={TRANSFER_TABLE_TYPES.indexOf(direction)}
      divider
      isFullWidth
      optionPaddingX={isMobile ? 0 : undefined}
    />
  );
};

export const TransfersTableWithData: FC<{
  params?: TTransfersQueryParams;
  enabled?: boolean;
}> = ({ params, enabled }) => {
  const { status, data, error } = useTransfersQuery(params, enabled);
  const { setError, open } = useTransferFiltersDrawer();
  const filtersCount = useSelectedTransfersFiltersCount();

  useEffect(() => {
    setError(error);
    if (error && filtersCount > 0) {
      open();
    }
  }, [error, filtersCount, open, setError]);

  switch (status) {
    case 'pending':
      return <TableSkeleton />;
    case 'error':
      return <EmptyTransfersList />;
    case 'success':
    default: {
      const empty = data?.data.length === 0;
      if (!data || empty) {
        return <EmptyTransfersList />;
      } else {
        return (
          <ScrollBarWithFade>
            <TableContainer position="relative">
              <Table styleConfig={tableStyleConfig}>
                <Tbody>
                  {data.data.map((row, i) => (
                    <Row data={row} key={i} />
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </ScrollBarWithFade>
        );
      }
    }
  }
};

const EmptyTransfersList = () => {
  const { t } = useTranslation(['transfers']);
  return (
    <VStack w="full" spacing={3} py="174px">
      <Icon as={EmptyWallet} w="150px" h="150px" color="brands.500" />
      <Text textStyle={TextStyles.BodyText16Semibold} color="primary.350">
        {t('noTransactionsToShow')}
      </Text>
    </VStack>
  );
};

const Row = ({ data }: { data: TTransferDto }) => {
  const { t } = useTranslation(['accounts', 'transfers']);
  const { serverDatetimeToDisplay } = useLanguageFeatures();
  const transferEntity = getTransferEntity(data);
  const { formatAmountByCurrency } = useLanguageFeatures();
  const setIncomingTransferId = useSetIncomingTransferIdToUrl();
  const setOutgoingTransferId = useSetOutgoingTransferIdToUrl();
  const setFxTransferId = useSetFxTransferIdToUrl();
  const closeAllAccountsDrawer = useCloseAllAccountsDrawer();
  const { close: closeFilters } = useTransferFiltersDrawer();
  const handleClick = useCallback(() => {
    closeAllAccountsDrawer();
    closeFilters();
    switch (transferEntity.transferType) {
      case 'incoming':
        setIncomingTransferId(transferEntity.transfer?.id);
        break;
      case 'outgoing':
        setOutgoingTransferId(transferEntity.transfer?.id);
        break;
      default:
        setFxTransferId(transferEntity.transfer?.id);
        break;
    }
  }, [
    closeAllAccountsDrawer,
    closeFilters,
    transferEntity.transferType,
    transferEntity.transfer?.id,
    setIncomingTransferId,
    setOutgoingTransferId,
    setFxTransferId,
  ]);

  const isSelfTransfer = transferEntity.transfer?.operationType === 'self';

  const rowTitle = useMemo(() => {
    if (isSelfTransfer) {
      return t('transfers:transferToSelf');
    }
    switch (transferEntity.transferType) {
      case 'incoming':
        return getLegalEntityName(
          transferEntity?.transfer?.sender?.legalEntity,
        );
      case 'outgoing':
        return getLegalEntityName(
          transferEntity?.transfer?.sender?.legalEntity,
        );
      case 'fx':
        return t('accounts:exchange.selectStep.exchangeDirection', {
          senderCurrency:
            transferEntity.transfer?.senderSide.currency.toUpperCase(),
          receiverCurrency:
            transferEntity.transfer?.recipientSide.currency.toUpperCase(),
        });
      default:
        return;
    }
  }, [transferEntity.transfer, t, transferEntity.transferType, isSelfTransfer]);

  const transferAmount = useMemo(() => {
    const { transfer, transferType } = transferEntity;
    if (!transfer) return '';
    let amount: number;
    let currency: string;
    let sign: string;
    switch (transferType) {
      case 'incoming':
        amount = transfer.recipientSide.totalAmount || 0;
        currency = transfer.recipientSide.currency;
        sign = '+';
        break;
      case 'outgoing':
        amount = transfer.senderSide.totalAmount || 0;
        currency = transfer.senderSide.currency;
        sign = '-';
        break;
      case 'fx':
        amount = transfer.recipientSide.totalAmount || 0;
        currency = transfer.recipientSide.currency;
        sign = '+';
        break;
    }
    return `${sign} ${formatAmountByCurrency(amount, currency)}`;
  }, [formatAmountByCurrency, transferEntity]);

  return (
    <Tr onClick={handleClick}>
      <Td>
        <Flex as="span" alignItems="center">
          <ListIcon
            type={transferEntity.transferType}
            status={transferEntity.transfer?.state}
            isSelfTransfer={isSelfTransfer}
          />
          <VStack
            as="span"
            spacing="2px"
            alignItems="baseline"
            flexDirection="column"
            ml={2}
          >
            <Text
              textStyle={TextStyles.tables}
              color="primary.500"
              maxW={{ base: '400px', xs: '110px', xssm: '160px' }}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {rowTitle}
            </Text>
            <Text textStyle={TextStyles.Caption12Regular} color="primary.350">
              {serverDatetimeToDisplay(data.created, 'datetime')}
            </Text>
          </VStack>
        </Flex>
      </Td>
      <Td>
        <VStack
          as="span"
          spacing="2px"
          alignItems="flex-end"
          flexDirection="column"
        >
          <Text as="span" color="primary.500" textStyle={TextStyles.tables}>
            {transferAmount}
          </Text>
          {transferEntity.transfer && transferEntity.transferType === 'fx' && (
            <Text color="primary.350" textStyle={TextStyles.Caption12Regular}>
              {`- ${formatAmountByCurrency(
                transferEntity.transfer?.senderSide.amount || 0,
                transferEntity.transfer?.senderSide.currency,
              )}`}
            </Text>
          )}
        </VStack>
      </Td>
    </Tr>
  );
};

interface TFxTransfer {
  transferType: 'fx';
  transfer: TTransferDto['fx'];
  entityId: string;
}

interface TOutgoingTransfer {
  transferType: 'outgoing';
  transfer: TTransferDto['outgoing'];
  entityId: string;
}

interface TIncomingTransfer {
  transferType: 'incoming';
  transfer: TTransferDto['incoming'];
  entityId: string;
}

type TTransferEntity = TFxTransfer | TOutgoingTransfer | TIncomingTransfer;

const getTransferEntity = (data: TTransferDto): TTransferEntity => {
  const transferType = data.incoming
    ? 'incoming'
    : data.outgoing
      ? 'outgoing'
      : 'fx';

  switch (transferType) {
    case 'incoming':
      return {
        transferType,
        transfer: data.incoming,
        entityId: data.id,
      };
    case 'outgoing':
      return {
        transferType,
        transfer: data.outgoing,
        entityId: data.id,
      };
    default:
      return {
        transferType,
        transfer: data.fx,
        entityId: data.id,
      };
  }
};
