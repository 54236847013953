import { MayBe, TCurrency } from '@payler/bank-utils';
import { TCreateRecipientDto, TRecipientDto } from './recipients';
import {
  TransferStateValues,
  TransferSystemValues,
  TransferTypeValues,
} from '../constants';
import { TParticipantDto } from './participant';
import { TBaasProviderNameDto } from './accounts';

export type TReceiverTypeDto = 'individual' | 'business';
export type TTransferSystemDto = (typeof TransferSystemValues)[number];
export type TTransferType = (typeof TransferTypeValues)[number];
export type TDocumentDto = {
  name: string;
};
export type TTransferStateDto = (typeof TransferStateValues)[number];
export type TOperationType =
  | 'incoming'
  | 'outgoing'
  | 'fx'
  | 'internal'
  | 'self'
  | 'paymentRequest';
export type TFeeAllocationDto = 'our' | 'sha';
export enum EFeeAllocationEnum {
  our,
  sha,
}

export type TCreateOutgoingTransferDto = {
  senderAccountId: string;
  sendingAmount: number;
  description?: MayBe<string>;
  documents?: File[];
  system?: MayBe<TTransferSystemDto>;
  feeAllocation?: MayBe<TFeeAllocationDto>;
  recipientParticipantId?: string;
};

export type TTransferSideDto = {
  amount: MayBe<number>;
  currency: string;
  feeAllocation: MayBe<TFeeAllocationDto>;
  feeAmount: MayBe<number>;
  totalAmount: MayBe<number>;
};

export type TOutgoingTransferDto = {
  id: string;
  created: string;
  modified: string;
  operationType: TOperationType;
  clientId: string;
  sender: TParticipantDto;
  senderSide: TTransferSideDto;
  recipient: TRecipientDto;
  system: TTransferSystemDto;
  description: MayBe<string>;
  reason: MayBe<string>;
  documents: MayBe<TDocumentDto[]>;
  state: TTransferStateDto;
};

export type TIncomingTransferDto = {
  id: string;
  created: string;
  modified: string;
  operationType: TOperationType;
  clientId: string;
  sender: TParticipantDto;
  recipient: TParticipantDto;
  recipientSide: TTransferSideDto;
  system: TTransferSystemDto;
  description: string;
  reason: MayBe<string>;
  state: TTransferStateDto;
};

export type TFxTransferDto = {
  id: string;
  created: string;
  modified: string;
  operationType: TOperationType;
  clientId: string;
  sender: TParticipantDto;
  senderSide: TTransferSideDto;
  recipient: TParticipantDto;
  recipientSide: TTransferSideDto;
  exchangeRate: MayBe<number>;
  reason: MayBe<string>;
  state: TTransferStateDto;
};

export type TConvertToCNYTransferDto = {
  buyAccountIban: string;
  buyAmount: number;
  buyCurrency: TCurrency;
  created: string;
  externalTransferId: string;
  id: string;
  rate: number;
  rejectReason: MayBe<string>;
  sellAccountIban: string;
  sellAmount: number;
  sellCurrency: TCurrency;
  status: string;
};

export type TCreateFxTransferCommand = {
  amount: number;
  senderAccountId: string;
  receiverAccountId: string;
};
export type TCreateConvertToCNYTransferCommand = {
  sellAmount: number;
  sellAccountId: string;
  buyAccountId: string;
};

export type TTransferFeeParams = {
  BaasProvider: TBaasProviderNameDto;
  TransferType: TTransferType;
  Amount?: number;
  Currency?: string;
  System?: MayBe<TTransferSystemDto>;
  FeeAllocation?: MayBe<TFeeAllocationDto>;
};

export type TTransferTypeParams = {
  RecipientId?: string;
  Currency?: string;
  SenderAccountId?: string;
};

export type TTransfersFeeDto = {
  feeAmount: number;
  fxPercentFee?: number;
  tariffVersionId: string;
};
