import {
  TParticipantAccountDto,
  TParticipantAddressDto,
  TParticipantDto,
  TRecipientDto,
  TRecipientType,
} from '@payler/api/client-office';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export type TRecipientNameInfo = {
  recipientType: TRecipientType;
  legalName?: string;
  firstName?: string;
  lastName?: string;
};

/** Получить у реципиента его локализованный тип  */
export const useLocalizedRecipientType = (type: TRecipientType | undefined) => {
  const { t } = useTranslation(['recipients']);
  switch (type) {
    case 'business':
      return t('recipients:business');
    case 'individual':
      return t('recipients:individual');
    case 'internal':
      return t('recipients:internal');
    default:
      return '';
  }
};

const isRecipientDto = (
  recipient: TParticipantDto | TRecipientNameInfo,
): recipient is TParticipantDto => _.has(recipient, 'id');

/** Получить у реципиента его имя  */
export const getRecipientTitle = (
  recipient: TParticipantDto | TRecipientNameInfo | undefined | null,
) => {
  if (!recipient) return '';
  if (isRecipientDto(recipient)) {
    switch (recipient?.legalEntity?.type) {
      case 'individual':
        return `${recipient?.legalEntity.firstName} ${recipient?.legalEntity.lastName}`;
      case 'business':
      default:
        return recipient?.legalEntity?.companyName;
    }
  } else {
    return recipient.recipientType === 'business'
      ? recipient.legalName
      : `${recipient.firstName} ${recipient.lastName}`;
  }
};

export const beautifyIban = (iban: string) => iban?.replace(/(.{4})/g, '$1 ');

export const getRecipientAccountIdentifier = (
  account: TParticipantAccountDto | undefined,
) => {
  if (!account) return '';
  return account.iban
    ? beautifyIban(account.iban)
    : (account.accountNumber ?? account.ukAccountNumber ?? undefined);
};
