import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  forwardRef,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  Text,
  Tooltip,
  useClipboard,
  VStack,
} from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { getCurrencyIcon } from '../../currency';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import {
  useAccountByUrl,
  useAccountIdFromUrl,
  useAccountsQuery,
  useSetAccountIdToUrl,
} from '../../hooks/accounts/queries';
import { useBankBreakpointValue } from '../../hooks/use-bank-breakpoint-value';
import { ActionsPopover } from '../ActionsPopover/ActionsPopover';
import { MobileMoreDrawer } from '../MobileMoreDrawer/MobileMoreDrawer';
import { useShowTransferModal } from '../../modals/TransferModal/TransferModal';
import { useShowAddMoneyModal } from '../../modals/AddMoney/AddMoney';
import { usePermissions } from '../../hooks/use-permissions';
import { useShowExchangeModal } from '../../modals/Exchange/ExchangeModal';
import { useClientStatus } from '../../hooks/clients/helpers';
import { isAccountCanFx } from '../../modals/Exchange/helpers';
import { IonIcon } from '@ionic/react';
import {
  add,
  arrowUp,
  caretDownOutline,
  copy,
  ellipsisVertical,
  syncCircle,
} from 'ionicons/icons';
import { useAccountIdentifier } from '../../helpers/use-account-identifier';
import { useShowAllAccountsDrawer } from '../AllAccountsDrawer/AllAccountsDrawer';
import { useLayoutContext } from '../../context/LayoutContextProvider';

export const AccountInfo: React.FC = () => {
  const account = useAccountByUrl();
  const { isActive: isActiveClient, isPreActive: isPreActiveClient } =
    useClientStatus();
  const { formatAmountByCurrency } = useLanguageFeatures();
  const onOpenTransferModal = useShowTransferModal();
  const onOpenAddMoneyModal = useShowAddMoneyModal();
  const onOpenExchangeModal = useShowExchangeModal();

  const { t } = useTranslation(['accounts', 'common']);

  const isDesktop = useBankBreakpointValue({ base: false, md: true });
  const [isMobileMoreShown, setIsMobileMoreShown] = useState<boolean>(false);
  const { isGlobalAccounts } = usePermissions();
  const isCanFx = isAccountCanFx(account);

  const { data: accounts } = useAccountsQuery({
    enabled: true,
    isPollingEnabled: true,
  });
  const selectedAccountId = useAccountIdFromUrl();
  const setSelectedAccountId = useSetAccountIdToUrl();
  useEffect(() => {
    if (!selectedAccountId && accounts?.[0]) {
      setSelectedAccountId(accounts[0].id);
    }
  }, [accounts, selectedAccountId, setSelectedAccountId]);
  const accountIdentifier = useAccountIdentifier(account);
  const { hasCopied, onCopy } = useClipboard(accountIdentifier?.value || '');
  const openAllAccountsDrawer = useShowAllAccountsDrawer();
  const { isMobile } = useLayoutContext();
  const ResponsiveStack = isMobile ? VStack : HStack;
  const hStackProps = {
    justifyContent: 'space-between',
  };
  const vStackProps = {
    spacing: 2,
  };
  if (!account) return null;

  return (
    <Box
      borderRadius="md"
      boxShadow="basic"
      px={2}
      py={3}
      bg="secondary.500"
      w="inherit"
    >
      <ResponsiveStack w="100%" {...(isMobile ? vStackProps : hStackProps)}>
        <VStack alignItems="baseline" spacing={0.5} w="100%">
          <HStack spacing={1}>
            <Icon w="16px" h="16px" as={getCurrencyIcon(account.currency)} />
            <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
              {account.name}
            </Text>
          </HStack>
          <HStack spacing={1}>
            <Text
              textStyle={TextStyles.h2}
              color="primary.500"
              fontWeight="700"
            >
              {formatAmountByCurrency(account.amount, account.currency)}
            </Text>
            <IconButton
              variant="secondary"
              aria-label="open-drawer"
              w="24px"
              h="24px"
              border="none"
              icon={
                <Icon as={IonIcon} icon={caretDownOutline} w="16px" h="16px" />
              }
              onClick={openAllAccountsDrawer}
            />
          </HStack>
          {accountIdentifier && (
            <Flex alignItems="center" gap={1}>
              <Text textStyle={TextStyles.Caption12Regular} color="primary.400">
                {accountIdentifier.fullInfo}
              </Text>
              <Tooltip
                isOpen={hasCopied || undefined}
                label={hasCopied ? t('common:copied') : t('common:clickToCopy')}
                hasArrow
                placement="top"
              >
                <Icon
                  as={IonIcon}
                  icon={copy}
                  w={2}
                  h={2}
                  color="primary.300"
                  _hover={{ color: 'primary.350', cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onCopy();
                  }}
                />
              </Tooltip>
            </Flex>
          )}
        </VStack>
        <HStack spacing={1} mr={1}>
          <ButtonComponent
            variant="primary"
            iconName={add}
            title={t('accounts:accountInfo.topUp')}
            onClick={onOpenAddMoneyModal}
            data-testid="button_open-top-up"
            isDisabled={
              !isGlobalAccounts || (!isActiveClient && !isPreActiveClient)
            }
          />
          <ButtonComponent
            variant="secondary"
            iconName={arrowUp}
            title={t('accounts:accountInfo.send')}
            onClick={onOpenTransferModal}
            data-testid="button_open-send-money"
            isDisabled={
              (!isActiveClient || !account.allowOutgoingOperations) &&
              isGlobalAccounts
            }
          />
          <ButtonComponent
            variant="secondary"
            iconName={syncCircle}
            title={t('accounts:accountInfo.exchange')}
            onClick={onOpenExchangeModal}
            isDisabled={
              !isGlobalAccounts ||
              !isActiveClient ||
              !account.allowOutgoingOperations ||
              !isCanFx
            }
            data-testid="button_open-exchange"
          />

          {/* TODO: доработать CNY Exchange при разработке флоу создания трансфера
          {isFeatureCNYEnabled &&
              hasCNYAccount &&
              isDesktop &&
              isEURAccount && (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={onOpenConvertToCNYModal}
                  isDisabled={
                    !isGlobalAccounts ||
                    !isActiveClient ||
                    !account.allowOutgoingOperations
                  }
                  borderRadius="xl"
                >
                  <Icon as={ExchangeIcon} mr={0.5} />
                  {t('accounts:accountInfo.convertToCNY')}
                </Button>
              )}
          </HStack>
          {/* В рамках BANK-1136 удалил кнопку Details */}
          {isDesktop ? (
            <ActionsPopover placement="left-start" accountId={account.id}>
              <ButtonComponent
                variant="secondary"
                iconName={ellipsisVertical}
                title={t('accounts:accountInfo.more')}
                data-testId="button_open-more"
              />
            </ActionsPopover>
          ) : (
            <ButtonComponent
              variant="secondary"
              iconName={ellipsisVertical}
              title={t('accounts:accountInfo.more')}
              data-testid="button_account-open-menu"
              onClick={() => setIsMobileMoreShown(true)}
            />
          )}
        </HStack>
      </ResponsiveStack>
      <MobileMoreDrawer
        isOpen={isMobileMoreShown}
        onClose={() => setIsMobileMoreShown(false)}
      />
    </Box>
  );
};

interface IButtonComponentProps extends Omit<IconButtonProps, 'aria-label'> {
  title: string;
  iconName: string;
}

const ButtonComponent = forwardRef<IButtonComponentProps, 'button'>(
  (props, ref) => {
    const { title, iconName } = props;
    return (
      <VStack minW="56px">
        <IconButton
          icon={<Icon as={IonIcon} icon={iconName} w="24px" h="24px" />}
          size="sm"
          w="40px"
          minW="40px"
          border="none"
          borderRadius="28px"
          ref={ref}
          {...props}
          aria-label={title}
        />
        <Text textStyle={TextStyles.Caption12Medium} textColor="primary.350">
          {title}
        </Text>
      </VStack>
    );
  },
);
