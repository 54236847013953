import { useTranslation } from 'react-i18next';
import {
  BankModalBody,
  BankModalTitle,
} from '../../../components/BankModal/BankModal';
import { Text, VStack } from '@chakra-ui/react';
import ExchangeDropdownField from '../../../components/ExchangeDropdown/ExchangeDropdownField';
import { TExchangeDropdownOption } from '../../../components/ExchangeDropdown/ExchangeDropdownOption';
import { useCallback, useEffect, useMemo } from 'react';
import { useAccountsQuery } from '../../../hooks/accounts/queries';
import {
  TTransferDetailsForm,
  useTransferWizardContext,
} from '../TransferWizard';
import { useLanguageFeatures } from '../../../hooks/use-language-features';
import { getCurrencyIcon } from '../../../currency';
import { TextStyles } from '@payler/ui-theme';
import { ChevronCircledIcon } from '../../../icons';
import { useFormContext } from 'react-hook-form';
import { TAccountDto } from '@payler/api/client-office';

export const SelectAccountsStep = () => {
  const { t } = useTranslation(['accounts']);
  const { formatAmountByCurrency } = useLanguageFeatures();
  const {
    initialSenderAccount,
    senderAccount,
    setSenderAccount,
    receiverAccount,
    setReceiverAccount,
    initialStep,
  } = useTransferWizardContext();
  const methods = useFormContext<TTransferDetailsForm>();
  const { setValue, watch } = methods;
  const { data: accounts } = useAccountsQuery({
    enabled: !initialSenderAccount,
  });

  const receiverAccountId = watch('receiverAccountId');
  const senderAccountId = watch('senderAccountId');
  const getSelectedReceiverAccount = useCallback(() => {
    if (receiverAccount) {
      return receiverAccount;
    }
    if (receiverAccountId) {
      return accounts?.find((acc) => acc.id === receiverAccountId);
    }

    return getDefaultReceiverAccount(
      senderAccount?.id,
      senderAccount?.currency,
      accounts,
    );
  }, [
    receiverAccount,
    receiverAccountId,
    accounts,
    senderAccount?.id,
    senderAccount?.currency,
  ]);
  useEffect(() => {
    const selectedReceiverAccount = getSelectedReceiverAccount();
    setReceiverAccount(selectedReceiverAccount);
    setValue('receiverAccountId', selectedReceiverAccount?.id);
  }, [senderAccountId]);

  const receiverAccountOptions: TExchangeDropdownOption[] = useMemo(() => {
    if (!accounts) return [];
    return accounts?.reduce((accum, account) => {
      if (
        account.currency.toLowerCase() ===
          senderAccount?.currency.toLowerCase() &&
        account.id !== senderAccount.id
      ) {
        accum.push({
          label: formatAmountByCurrency(account.amount, account.currency),
          value: account.id,
          caption: account.name,
          icon: getCurrencyIcon(account.currency),
        });
      }
      return accum;
    }, [] as TExchangeDropdownOption[]);
  }, [
    accounts,
    formatAmountByCurrency,
    senderAccount?.currency,
    senderAccount?.id,
  ]);

  const senderAccountOptions: TExchangeDropdownOption[] =
    accounts?.map((account) => ({
      label: formatAmountByCurrency(account.amount, account.currency),
      value: account.id,
      caption: account.name,
      icon: getCurrencyIcon(account.currency),
    })) || [];

  const onReceiverAccountChange = useCallback(
    (accountId: string) => {
      const selectedAccount = accounts?.find(
        (account) => account.id === accountId,
      );
      setReceiverAccount(selectedAccount);
    },
    [accounts, setReceiverAccount],
  );

  const onSenderAccountChange = useCallback(
    (accountId: string) => {
      const selectedAccount = accounts?.find(
        (account) => account.id === accountId,
      );
      setSenderAccount(selectedAccount);
      setReceiverAccount(undefined);
      setValue('receiverAccountId', undefined);
    },
    [accounts, setReceiverAccount],
  );

  return (
    <>
      <BankModalTitle
        title={t('accounts:transferMoney.chooseYourAccounts')}
        description={t('accounts:transferMoney.inSameCurrency')}
      />
      <BankModalBody w="100%">
        <VStack spacing={1.5} alignItems="start">
          <ExchangeDropdownField
            fieldName="senderAccountId"
            options={senderAccountOptions}
            triggerWrapperProps={{
              bgColor: 'secondary.500',
            }}
            optionLabelProps={{
              textStyle: TextStyles.Subtitle14Medium,
            }}
            dropdownIcon={{
              component: ChevronCircledIcon,
              props: { h: '26px', w: '26px', color: 'primary.500' },
            }}
            onChange={(value) => onSenderAccountChange(value as string)}
            isDisabled={initialStep !== 'select'}
          />
          <Text textStyle={TextStyles.Subtitle14Semibold}>
            {t('accounts:transferMoney.transferTo')}
          </Text>
          <ExchangeDropdownField
            fieldName="receiverAccountId"
            options={receiverAccountOptions}
            triggerWrapperProps={{
              bgColor: 'secondary.500',
            }}
            optionLabelProps={{
              textStyle: TextStyles.Subtitle14Medium,
            }}
            dropdownIcon={{
              component: ChevronCircledIcon,
              props: { h: '26px', w: '26px', color: 'primary.500' },
            }}
            onChange={(value) => onReceiverAccountChange(value as string)}
            isDisabled={initialStep !== 'select'}
          />
        </VStack>
      </BankModalBody>
    </>
  );
};

const getDefaultReceiverAccount = (
  senderAccountId: string | undefined,
  senderAccountCurrency: string | undefined,
  accounts: TAccountDto[] | null,
) =>
  accounts?.find(
    (account) =>
      account.id !== senderAccountId &&
      account.currency.toLowerCase() === senderAccountCurrency?.toLowerCase(),
  );
