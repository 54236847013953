import {
  Box,
  Button,
  Center,
  Container,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Flex,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ThinDivider } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import { Suspense, useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import { getCurrencyIcon } from '../../currency';
import {
  useAccountsQuery,
  useSetAccountIdToUrl,
} from '../../hooks/accounts/queries';
import { useBankBreakpointValue } from '@payler/ui/client-office';
import { useLanguageFeatures } from '@payler/ui/client-office';
import { useRecoilBoolean, useRecoilBooleanValue } from '@payler/bank-utils';
import { ActionsPopover } from '../ActionsPopover';
import { BankDrawer } from '../BankDrawer/BankDrawer';
import { BankDrawerContent } from '../BankDrawer/BankDrawerContent';
import { CloseButton } from '../CloseButton/CloseButton';
import { useCheckAppFlagEnabled } from '@payler/ui/client-office';
import { TAccountDto } from '@payler/api/client-office';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import {
  AccountsReorderContextProvider,
  useAccountsReorderContext,
} from '../../context/AccountsReorderContext';
import { useChangeAccountsOrderMutation } from '../../hooks/accounts/mutations';
import { useFirstAccountWarning } from '../../hooks/use-account-warning';
import { LoaderBox } from '../LoaderBox/LoaderBox';
import { ListIcon } from '../ListIcon/ListIcon';
import { IonIcon } from '@ionic/react';
import { SortUp } from '@payler/ui-icons';
import { useAccountIdentifier } from '../../helpers/use-account-identifier';
import { ellipsisVertical, grid, menu, warning } from 'ionicons/icons';

const KEY = 'drawers:AllAccountsDrawer';

export const useShowAllAccountsDrawer = () => useRecoilBoolean(KEY).on;
export const useCloseAllAccountsDrawer = () => useRecoilBoolean(KEY).off;
export const useIsOpenAllAccountsDrawer = () => useRecoilBooleanValue(KEY);

export const AllAccountsDrawer = () => {
  const isOpen = useIsOpenAllAccountsDrawer();
  const close = useCloseAllAccountsDrawer();
  const { data: accounts } = useAccountsQuery();

  if (!accounts) {
    return null;
  }
  return (
    <BankDrawer isOpen={isOpen} onClose={close}>
      <AccountsReorderContextProvider>
        <DrawerContent />
      </AccountsReorderContextProvider>
    </BankDrawer>
  );
};

const DrawerContent = () => {
  return (
    <BankDrawerContent drawerId="all-accounts-drawer">
      <Header />
      <ThinDivider />
      <DrawerBody p={0}>
        <AccountsList />
      </DrawerBody>
      <DrawerFooter p={0} display="block">
        <Suspense fallback={<LoaderBox />}>
          <FooterContent />
        </Suspense>
      </DrawerFooter>
    </BankDrawerContent>
  );
};

const Header = () => {
  const { t } = useTranslation(['accounts']);
  const close = useCloseAllAccountsDrawer();
  const { data: accounts } = useAccountsQuery();

  return (
    <DrawerHeader p={3}>
      <Flex justifyContent="space-between">
        <HStack spacing={2}>
          <Box bgColor="brands.100" w="40px" h="40px" borderRadius="104px">
            <Icon
              as={IonIcon}
              icon={grid}
              w="16px"
              h="16px"
              color="brands.500"
              padding="12px"
            />
          </Box>
          <Box>
            <Text textStyle={TextStyles.h4} color="primary.500">
              {t('accounts:allAccounts')}
            </Text>
            <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
              {t('accounts:accountsDrawer.accounts', {
                number: accounts?.length ?? 0,
              })}
            </Text>
          </Box>
        </HStack>
        <CloseButton onClick={close} />
      </Flex>
    </DrawerHeader>
  );
};

const AccountsList = () => {
  const { reorderedAccounts, setReorderedAccounts } =
    useAccountsReorderContext();

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    if (active.id && over && active.id !== over.id) {
      setReorderedAccounts((items) => {
        if (!items) return null;
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  if (!reorderedAccounts) return null;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext
        items={reorderedAccounts}
        strategy={verticalListSortingStrategy}
      >
        <VStack m={0} spacing={0} divider={<ThinDivider />}>
          {reorderedAccounts.map((account) => {
            return <AccountItem key={account.id} account={account} />;
          })}
        </VStack>
        <ThinDivider />
      </SortableContext>
    </DndContext>
  );
};

const FooterContent = () => {
  const isFeatureAddAccountsEnabled = useCheckAppFlagEnabled('accounts.add');
  const { t } = useTranslation(['accounts']);
  const { data: accounts, isLoading: isAccountsLoading } = useAccountsQuery();
  const { isPending: isChangeOrderLoading, mutate: changeOrderMutation } =
    useChangeAccountsOrderMutation();
  const { isReorderMode, setIsReorderMode, reorderedAccounts } =
    useAccountsReorderContext();

  const handleReorderClick = useCallback(() => {
    if (isReorderMode) {
      // отключаем режим, и применяем новый порядок
      const oldOrder = accounts?.map((acc) => acc.id);
      const newOrder = reorderedAccounts?.map((acc) => acc.id);
      if (newOrder && !isEqual(oldOrder, newOrder)) {
        changeOrderMutation({ accountIds: newOrder });
      }
    }
    setIsReorderMode(!isReorderMode);
  }, [
    accounts,
    isReorderMode,
    changeOrderMutation,
    reorderedAccounts,
    setIsReorderMode,
  ]);

  if (!isFeatureAddAccountsEnabled) {
    return null;
  }

  return (
    <Container p={3} pt={2}>
      <Button
        variant={isReorderMode ? 'primary' : 'secondary'}
        isLoading={isChangeOrderLoading || isAccountsLoading}
        onClick={handleReorderClick}
        w="100%"
        data-testid={`button_account-${
          isReorderMode ? 'save-changes' : 'organize-accounts'
        }`}
        border="none"
      >
        <HStack spacing={1}>
          <Icon as={SortUp} w="24px" h="24px" />
          <Text textStyle={TextStyles.Buttons16Medium}>
            {isReorderMode
              ? t('accounts:accountsDrawer.saveChanges')
              : t('accounts:accountsDrawer.organizeAccounts')}
          </Text>
        </HStack>
      </Button>
    </Container>
  );
};

const AccountItem = ({ account }: { account: TAccountDto }) => {
  const { t } = useTranslation(['accounts']);
  const { isReorderMode } = useAccountsReorderContext();
  const isDesktop = useBankBreakpointValue({ base: false, md: true });
  const { formatAmountByCurrency } = useLanguageFeatures();
  const setSelectedAccountId = useSetAccountIdToUrl();
  const close = useCloseAllAccountsDrawer();
  const accountWarning = useFirstAccountWarning(account);

  const accountIdentifier = useAccountIdentifier(account);
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: account.id, data: account, disabled: !isReorderMode });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isReorderMode
      ? ({
          opacity: isDragging ? 0.8 : 1,
          position: 'relative',
          zIndex: isDragging ? 1 : 0,
        } as const)
      : {}),
  };

  return (
    <Box
      w="100%"
      p={0}
      cursor={isReorderMode ? undefined : 'pointer'}
      style={style}
      ref={setNodeRef}
      bg="secondary.500"
    >
      <Flex
        justifyContent="space-between"
        w="100%"
        px={3}
        py={2}
        _hover={isReorderMode ? undefined : { bg: 'primary.25' }}
        onClick={
          isReorderMode
            ? undefined
            : () => {
                setSelectedAccountId(account.id);
                close();
              }
        }
        data-testid="account-item"
      >
        <HStack spacing={2}>
          {isReorderMode && (
            <Icon
              w="24px"
              h="24px"
              color="primary.350"
              as={IonIcon}
              icon={menu}
              cursor="move"
              outline="none"
              {...attributes}
              {...listeners}
            />
          )}
          <ListIcon icon={getCurrencyIcon(account.currency)} />
          <Box>
            <Text textStyle={TextStyles.Subtitle14Semibold} color="primary.500">
              {account.name}
            </Text>
            {accountWarning && (
              <HStack spacing={0.5}>
                <Icon
                  as={IonIcon}
                  icon={warning}
                  w="14px"
                  h="14px"
                  color="red.500"
                />
                <Text
                  textStyle={TextStyles.Caption12Regular}
                  color="red.500"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  maxW="120px"
                >
                  {accountWarning.message}
                </Text>
              </HStack>
            )}
            {!accountWarning && accountIdentifier && (
              <Text textStyle={TextStyles.Buttons12Small} color="primary.500">
                {accountIdentifier.value}
              </Text>
            )}
          </Box>
        </HStack>
        <HStack spacing={2}>
          <Text textStyle={TextStyles.Subtitle14Semibold} color="primary.500">
            {formatAmountByCurrency(account.amount, account.currency)}
          </Text>
          {isDesktop && !isReorderMode && (
            <ActionsPopover placement="left-start" accountId={account.id}>
              <Center onClick={(e) => e.stopPropagation()}>
                <Icon
                  aria-label={t(
                    'accounts:accountsDrawer.ariaLabels.showOptions',
                  )}
                  as={IonIcon}
                  icon={ellipsisVertical}
                  data-testid="button_account-context-menu"
                />
              </Center>
            </ActionsPopover>
          )}
        </HStack>
      </Flex>
    </Box>
  );
};
