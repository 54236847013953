import React, { useEffect, useMemo } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { useColorMode } from '@chakra-ui/react';
import { GlobalFonts } from '@payler/ui-theme';
import { BrowserRouter, useLocation, useRoutes } from 'react-router-dom';
import { DevPanel, ErrorBoundary } from '@payler/ui-components';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  ApiContext,
  AppState,
  ConfigContextProvider,
  ConfigProvider,
  LayoutContextProvider,
  LoaderBox,
  getSamSubOnboardingRoutes,
  queryClient,
  useClientOfficeConfig,
  useCompanyInfo,
  useConfigContext,
  useDefaultSideNavLinks,
  usePermissions,
} from '@payler/ui/client-office';
import { theme, purchaseRoutesConfig } from '@payler/spush';
import { useTranslation } from 'react-i18next';

import * as Sentry from '@sentry/react';
import { AuthProvider, useAuth } from '@payler/auth';
import {
  SpushApiClientOffice,
  SpushDemoApiClientOffice,
} from '@payler/api-spush';
import { TToken } from '@payler/api/client-office';
import { WhiteLabelThemeProvider } from '@payler/utils';
import '@ionic/react/css/core.css';
import { getSpushAppRoutesConfig } from 'libs/bank/ui/spush/src/lib/routes/main';

const HelmetComponent = () => {
  const { t } = useTranslation(['menu']);
  const { companyName } = useCompanyInfo();
  return <Helmet title={t('menu:title', { companyName })} />;
};

const whitelabeledComponents = {};

export const App: React.FC = () => {
  const { t } = useTranslation();
  const isDevtoolsShown = localStorage.getItem('showDevtools');

  return (
    <ConfigContextProvider path="assets/config">
      <WLWithPath>
        <GlobalFonts prefix="assets/fonts/" />
        <ErrorBoundary>
          <EnvComp>
            <AppApiWrapper>
              <AppState>
                <QueryClientProvider client={queryClient}>
                  <HelmetProvider>
                    <HelmetComponent />
                    <LayoutContextProvider>
                      <AppContent />
                      <DevPanel />
                    </LayoutContextProvider>
                  </HelmetProvider>
                  {isDevtoolsShown === 'true' && <ReactQueryDevtools />}
                </QueryClientProvider>
              </AppState>
            </AppApiWrapper>
          </EnvComp>
        </ErrorBoundary>
      </WLWithPath>
    </ConfigContextProvider>
  );
};

const WLWithPath: FCC = ({ children }) => {
  const { environment, isLoading } = useConfigContext();
  if (isLoading) return null;
  return (
    <WhiteLabelThemeProvider staticPath={environment.staticPath} theme={theme}>
      {children}
    </WhiteLabelThemeProvider>
  );
};

const EnvComp: FCC = ({ children }) => {
  const { environment, isLoading } = useConfigContext();
  if (isLoading) return <LoaderBox />;
  return (
    <ConfigProvider
      env={environment}
      whiteLabeledComponents={whitelabeledComponents}
    >
      <AuthProvider config={environment.auth.config}>{children}</AuthProvider>
    </ConfigProvider>
  );
};

const AppApiWrapper: FCC = ({ children }) => {
  const { apiServer } = useClientOfficeConfig();
  const auth = useAuth<TToken>();
  const api = useMemo(() => {
    return auth.tokenParsed?.GlobalAccounts
      ? new SpushApiClientOffice(apiServer, auth)
      : new SpushDemoApiClientOffice(apiServer, auth);
  }, [apiServer, auth]);

  return <ApiContext api={api}>{children}</ApiContext>;
};

const DebugRouter = ({ children }: React.PropsWithChildren<unknown>) => {
  const location = useLocation();

  // alert(
  //   `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(
  //     location.state,
  //   )}`,
  // );

  return children;
};

const AppContent = () => {
  const { isAuthLoading, isAuthenticated } = useAuth();
  const { colorMode, toggleColorMode } = useColorMode();
  const { isDarkThemeAllowed } = useClientOfficeConfig();

  useEffect(() => {
    if (colorMode === 'dark' && !isDarkThemeAllowed) {
      toggleColorMode();
    }
  }, [colorMode, isDarkThemeAllowed, toggleColorMode]);

  if (isAuthLoading || !isAuthenticated) return <LoaderBox />;

  return (
    <BrowserRouter>
      <DebugRouter>
        <AppRoutes />
      </DebugRouter>
    </BrowserRouter>
  );
};

const AppRoutes = () => {
  const { isGlobalAccounts } = usePermissions();
  const links = useDefaultSideNavLinks();
  const mainRoutes = isGlobalAccounts
    ? getSpushAppRoutesConfig(links)
    : getSamSubOnboardingRoutes();
  const routes = [...mainRoutes, ...purchaseRoutesConfig];

  return useRoutes(routes);
};

export default Sentry.withProfiler(App);
