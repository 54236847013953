import { albums, timeSharp } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { TMenuLinkItem } from '../Menu/Menu';

export const useDefaultSideNavLinks = (): TMenuLinkItem[] => {
  const { t } = useTranslation(['menu']);

  return useMemo(
    () => [
      {
        icon: albums,
        route: '/',
        title: t('accounts'),
        dataTestId: 'menu_accounts',
      },
      {
        icon: timeSharp,
        route: '/activity',
        title: t('activity'),
        dataTestId: 'menu_activity',
      },
    ],
    [],
  );
};
