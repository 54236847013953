import { TParticipantAddressDto } from '@payler/api/client-office';

/** Преобразовать адрес participant'a в читаемый вид  */
export const useParticipantAddress = (
  address: TParticipantAddressDto | undefined,
) => {
  if (!address) return '';
  const { city, country, postalCode, street } = address;
  if (!(city || country || postalCode || street)) {
    return '';
  }

  const calcAddress = [
    street,
    city && postalCode ? `${city} ${postalCode}` : city || postalCode,
    country,
  ]
    .filter((k) => !!k)
    .join(', ');

  return calcAddress;
};
