import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  chakra,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';
import { CloseCircle16Icon } from '@payler/ui-icons';
import { RequiredStyledProvider } from '../required-styled-provider';
import omit from 'lodash/omit';

export interface InputFieldProps
  extends Omit<InputProps, 'defaultValue' | 'type'>,
    Partial<Pick<NumberFormatProps, 'allowNegative'>> {
  showClearButton?: boolean;
  name: string;
  label: string;
  helpText?: string;
  /**
   * Строка / карта / деньги
   */
  variant?: 'string' | 'card' | 'currency';
  currencySuffix?: string;
  currencyDecimals?: number;
  type?: 'password' | 'text' | 'tel';
  testId?: string;
  isAllowed?: (values: NumberFormatValues) => boolean;
  showErrorMessageOnDisabled?: boolean;
}

export const FloatingInputFieldComp: React.FC<InputFieldProps> = ({
  name,
  label,
  className,
  variant = 'string',
  currencySuffix,
  currencyDecimals,
  type,
  helpText,
  testId,
  showClearButton,
  isAllowed,
  showErrorMessageOnDisabled,
  ...rest
}) => {
  const { control, register, getFieldState, formState, watch, setValue } =
    useFormContext();
  const errorMessage = getFieldState(name, formState).error?.message as string;
  const isError = !!errorMessage && !!formState.touchedFields;
  const value = watch(name);
  const isShowClearButton =
    showClearButton && !!value && !(rest.isReadOnly || rest.isDisabled);
  const handleClear = useCallback(() => {
    setValue(name, '', { shouldDirty: true });
  }, [setValue, name]);
  const bg = value ? 'secondary.500' : 'primary.50';
  const borderColor = value ? 'brands.500' : 'transparent';

  return (
    <FormControl isInvalid={isError} {...omit(rest, 'allowNegative')}>
      <RequiredStyledProvider>
        <InputGroup>
          {variant === 'string' && (
            <Input
              variant="floating"
              placeholder=" "
              {...register(name)}
              {...rest}
              type={type}
              data-testid={testId}
            />
          )}
          {variant === 'card' && (
            <Controller
              name={name}
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <NumberFormat
                  getInputRef={ref}
                  customInput={Input}
                  format="#### #### #### ####"
                  variant="floating"
                  placeholder=" "
                  {...rest}
                  name={name}
                  value={value ?? ''}
                  onValueChange={(v) => onChange(v.floatValue ?? '')}
                  type={type}
                />
              )}
            ></Controller>
          )}
          {variant === 'currency' && (
            <Controller
              name={name}
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <NumberFormat
                  getInputRef={ref}
                  customInput={Input}
                  variant="floating"
                  suffix={currencySuffix ? ` ${currencySuffix}` : ''}
                  decimalScale={currencyDecimals}
                  thousandSeparator={' '}
                  placeholder=" "
                  {...rest}
                  name={name}
                  value={value ?? ''}
                  allowEmptyFormatting
                  onValueChange={(v) => onChange(v.floatValue ?? '')}
                  type={type}
                  allowNegative={false}
                  isAllowed={isAllowed}
                  bg={bg}
                  outline="1px solid"
                  outlineColor={borderColor}
                />
              )}
            ></Controller>
          )}
          {label && (
            <FormLabel variant="floating" sx={{ top: '12px' }}>
              {label}
            </FormLabel>
          )}
          {isShowClearButton && (
            <InputRightElement cursor="pointer" onClick={handleClear}>
              <CloseCircle16Icon />
            </InputRightElement>
          )}
          {helpText && (
            <FormHelperText
              color="primary.350"
              mt={1}
              textStyle={TextStyles.Caption12Regular}
              px={1.5}
              data-testid="floating-input-help-text"
            >
              {helpText}
            </FormHelperText>
          )}
        </InputGroup>

        {isError &&
          errorMessage &&
          (!rest.isDisabled || showErrorMessageOnDisabled) && (
            <FormHelperText
              color="red.500"
              mt={1}
              textStyle={TextStyles.Caption12Regular}
              px={1.5}
              data-testid="floating-input-error-message"
            >
              {errorMessage}
            </FormHelperText>
          )}
      </RequiredStyledProvider>
    </FormControl>
  );
};
FloatingInputFieldComp.displayName = 'FloatingInputField';

/**
 * Input c floating label для использования с react-hook-form
 */
export const FloatingInputField = chakra(FloatingInputFieldComp);
FloatingInputField.displayName = 'FloatingInputField';
