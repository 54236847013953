import { Box, Icon } from '@chakra-ui/react';
import {
  ArrowDownCircled,
  ArrowUpCircled,
  Failed18Icon,
  Pending18Icon,
  RepeatCircled,
  SwapHorizontalCircled,
} from '@payler/ui-icons';
import { DepositIcon } from '@payler/ui/client-office';
import { ListIconProps } from './types';
import { TTransferStateDto, TTransferType } from '@payler/api/client-office';

const getStatusIcon = (status?: TTransferStateDto) => {
  switch (status) {
    case 'failed':
    case 'technicalProblem':
      return Failed18Icon;
    case 'processing':
      return Pending18Icon;
    case 'completed':
      return DepositIcon;
    default:
      return undefined;
  }
};

const getDefaultColor = (type?: TTransferType, isSelfTransfer?: boolean) => {
  if (isSelfTransfer) return 'brands.500';
  switch (type) {
    case 'incoming':
      return 'green.500';
    case 'outgoing':
      return 'orange.500';
    case 'fx':
      return 'yellow.500';
    default:
      return undefined;
  }
};

const getTypeIcon = (type?: TTransferType, isSelfTransfer?: boolean) => {
  if (isSelfTransfer) return SwapHorizontalCircled;
  switch (type) {
    case 'incoming':
      return ArrowDownCircled;
    case 'outgoing':
      return ArrowUpCircled;
    case 'fx':
      return RepeatCircled;
    default:
      return undefined;
  }
};

export const ListIcon = ({
  icon,
  color = 'brands.500',
  overlayIcon,
  overlayIconColor,
  status,
  size = '40px',
  overlaySize = '18px',
  indent = '-2px',
  isSelfTransfer,
  type,
}: ListIconProps) => {
  const statusIcon = getStatusIcon(status);
  const defaultColor = getDefaultColor(type, isSelfTransfer) || color;
  const typeIcon = getTypeIcon(type, isSelfTransfer);

  return (
    <Box position="relative" w={size} h={size}>
      <Icon as={icon || typeIcon} w={size} h={size} color={defaultColor} />
      {(overlayIcon || statusIcon) && (
        <Icon
          position="absolute"
          right={indent}
          bottom={indent}
          w={overlaySize}
          h={overlaySize}
          as={overlayIcon || statusIcon}
          color={overlayIconColor}
        />
      )}
    </Box>
  );
};
