import createLogger from 'debug';
import axios, { AxiosHeaders, AxiosInstance } from 'axios';
import queryString from 'query-string';
import dayjs from 'dayjs';
import Utc from 'dayjs/plugin/utc';
import {
  TAccountDto,
  TAccountDtoPagedDataDto,
  TAccountsQueryPagedParams,
  TAccountsReportQueryParams,
  TAssignTransferParams,
  TBaasProviderDto,
  TBaasProvidersCurrenciesDto,
  TBalanceSheetReportQueryParams,
  TClientDetailsDto,
  TClientProductDto,
  TCloseAccountsParams,
  TCommonTransferQueryParams,
  TCreateClientAccountDto,
  TCreateCredentialsCommandDto,
  TReferencesCurrencyDto,
  TEditClientPayload,
  TExchangeRatesDtoPagedDataDto,
  TExchangeRatesQueryParams,
  TExchangeTransferDto,
  TExchangeTransferDtoPagedDataDto,
  TIncomingTransferDto,
  TIncomingTransferDtoPagedDataDto,
  TMBCalculateExchangeTransferDto,
  TMBCalculateExchangeTransferResultDto,
  TMBCommonTransferQueryParams,
  TMBConfirmExchangeTransferDto,
  TMBConfirmOutgoingTransferDto,
  TMBExchangeTransferDto,
  TMBExchangeTransferDtoPagedDataDto,
  TMBIncomingTransferDto,
  TMBIncomingTransferDtoPagedDataDto,
  TMBNewIncomingTransferDto,
  TMBOutgoinTransferDtoPagedDataDto,
  TMBOutgoingTransferDto,
  TMBRejectExchangeTransferDto,
  TMBRejectOutgoingTransferDto,
  TOnboardingDocumentSummaryDto,
  TOutgoingTransferDto,
  TOutgoingTransferDtoPagedDataDto,
  TPaymentRequestDto,
  TPaymentRequestPagedDataDto,
  TPaymentRequestPagedParams,
  TProfitAndLossReportQueryParams,
  TRailsTransactionsReportQueryParams,
  TReconciliationReportQueryParams,
  TRestrictAccountParams,
  TSaveExchangeRateCommandDto,
  TToken,
  TTransactionsQueryPagedParams,
  TTransferPagedDataDto,
  TTransferStateHistoryDto,
  TTransferStatusDescription,
  TTransfersQueryParams,
  TUpdateClientCommand,
} from './types';
import {
  TClientProfileDocumentSummaryDto,
  TClientDtoPagedDataDto,
  TClientsParams,
  TClientDto,
  TUploadedDocumentsInfoDto,
  TClientStatusDto,
} from './types/clients';
import {
  TCreatePaymentCommandDto,
  TTransactionDto,
  TTransactionDtoPagedDataDto,
  TTransactionsReportQueryParams,
} from './types/transactions';
import {
  TClientTariffDto,
  TCreateTariffDto,
  TEditTariffPlanDto,
  TTariffDto,
  TTariffPlansPagedDataDto,
  TTariffPlansQueryPagedParams,
} from './types/tariffs';
import {
  TTreasuryManagementSummaryPagedDataDto,
  TTreasuryManagementSummaryParams,
} from './types/treasuryManagement';
import { downloadFile, prepareBlob } from '@payler/utils';
import {
  TDailyCheckDetailsParams,
  TDailyCheckDetailedDto,
  TDailyCheckDto,
  TDailyCheckParams,
  TRecalculateDailyAccountBalancesCommand,
} from './types/dailyCheck';
import { TAuth } from '@payler/auth';
import { TCurrencyDto } from './types/currencies';

const MANUAL_BAAS_API = 'api/manual-baas/backoffice';

dayjs.extend(Utc);
const log = createLogger('api:bank');

const paramsSerializer = (data: object): string => {
  return queryString.stringify(data, { arrayFormat: 'none' });
};

export class ApiBankAdmin {
  private _axios: AxiosInstance;

  public get axios(): AxiosInstance {
    return this._axios;
  }

  constructor(
    private baseURL: string,
    private auth?: TAuth<TToken>,
  ) {
    log('ApiBank instance %O', { baseURL, token: auth?.accessToken });
    this._axios = axios.create({
      baseURL,
      paramsSerializer,
    });
    this.axios.interceptors.request.use(
      async function (config) {
        if (auth?.accessToken) {
          let token = auth.accessToken;
          try {
            const refreshed = await auth.updateToken(30);
            if (refreshed) {
              token = refreshed.access_token;
              log('token refreshed');
            }
            if (config.headers) {
              config.headers['Authorization'] = `Bearer ${token}`;
            } else {
              config.headers = new AxiosHeaders({
                Authorization: `Bearer ${token}`,
              });
            }
          } catch (e) {
            auth.logout();
          }
        } else {
          const token = localStorage.getItem('STORYBOOK_TOKEN');
          if (process.env['NODE_ENV'] !== 'production' && config.headers) {
            config.headers['Authorization'] = `Bearer ${token}`;
          } else {
            config.headers.set('Authorization', `Bearer ${token}`);
          }
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      },
    );
  }

  /**
   * Получения списка аккаунтов
   */
  async getAccounts(
    params: TAccountsQueryPagedParams,
  ): Promise<TAccountDtoPagedDataDto> {
    const resp = await this.axios.get('/api/accounts/internal/Accounts', {
      params,
    });
    log('getAccounts success, %o', params);
    return resp.data;
  }

  /**
   * Получение аккаунта
   */
  async getAccount(id: string): Promise<TAccountDto> {
    const resp = await this.axios.get(`/api/accounts/internal/Accounts/${id}`);
    log('getAccount success, id: %o', id);
    return resp.data;
  }

  /** Закрыть аккаунты по фильтрации */
  async closeAccounts(params: TCloseAccountsParams): Promise<void> {
    const resp = await this.axios.post(
      '/api/accounts/internal/Accounts/delete',
      params,
    );
    log('closeAccounts success, %o', params);
    return resp.data;
  }

  /** Ограничить или снять ограничения с аккаунтов по фильтрации */
  async restrictAccounts(params: TRestrictAccountParams): Promise<void> {
    const resp = await this.axios.put(
      '/api/accounts/internal/Accounts/manage',
      params,
    );
    log('restrictAccounts success, %o', params);
    return resp.data;
  }

  /**
   * Получения списка транзакций
   */
  async getTransactions(
    params: TTransactionsQueryPagedParams,
  ): Promise<TTransactionDtoPagedDataDto> {
    const resp = await this.axios.get(
      '/api/generalledger/internal/Transactions',
      {
        params,
      },
    );
    log('getTransactions success, %o', params);
    return resp.data;
  }

  /** Создать корректировочный платеж с транзакциями */
  async executeAdjustingPayment(
    params: TCreatePaymentCommandDto,
  ): Promise<[TTransactionDto]> {
    const resp = await this.axios.post(
      '/api/accounts/internal/Payments/executeAdjustingPayment',
      params,
    );
    log('makeTransaction success, %o', params);
    return resp.data;
  }

  /**
   * Получение списка исходящих переводов
   */
  async getOutgoingTransfers(
    params: TCommonTransferQueryParams,
  ): Promise<TOutgoingTransferDtoPagedDataDto> {
    const resp = await this.axios.get(
      '/api/transferorchestrator/internal/Transfers/outgoing',
      {
        params,
      },
    );
    log('getOutgoingTransfers success, %o', params);
    return resp.data;
  }

  /**
   * Получение списка входящих переводов
   */
  async getIncomingTransfers(
    params: TCommonTransferQueryParams,
  ): Promise<TIncomingTransferDtoPagedDataDto> {
    const resp = await this.axios.get(
      '/api/transferorchestrator/internal/Transfers/incoming',
      {
        params,
      },
    );
    log('getIncomingTransfers success, %o', params);
    return resp.data;
  }

  /**
   * Получение списка переводов обмена
   */
  async getExchangeTransfers(
    params: TCommonTransferQueryParams,
  ): Promise<TExchangeTransferDtoPagedDataDto> {
    const resp = await this.axios.get(
      '/api/transferorchestrator/internal/Transfers/fx',
      {
        params,
      },
    );
    log('getExchangeTransfers success, %o', params);
    return resp.data;
  }

  /**
   * Получение списка трансферов
   */
  async getTransfers(
    params: TTransfersQueryParams,
  ): Promise<TTransferPagedDataDto> {
    const resp = await this.axios.get(
      '/api/transferorchestrator/internal/Transfers',
      {
        params,
      },
    );
    log('getTransfers success');
    return resp.data;
  }

  /**
   * Получение incoming трансфера по id
   */
  async getIncomingTransferById(
    transferId: string,
  ): Promise<TIncomingTransferDto> {
    const resp = await this.axios.get(
      `/api/transferorchestrator/internal/Transfers/incoming/${transferId}`,
    );
    log('getIncomingTransferById success');
    return resp.data;
  }
  /**
   * Получение outgoing трансфера по id
   */
  async getOutgoingTransferById(
    transferId: string,
  ): Promise<TOutgoingTransferDto> {
    const resp = await this.axios.get(
      `/api/transferorchestrator/internal/Transfers/outgoing/${transferId}`,
    );
    log('getOutgoingTransferById success');
    return resp.data;
  }
  /**
   * Получение трансфера обмена по id
   */
  async getExchangeTransferById(
    transferId: string,
  ): Promise<TExchangeTransferDto> {
    const resp = await this.axios.get(
      `/api/transferorchestrator/internal/Transfers/fx/${transferId}`,
    );
    log('getExchangeTransferById success');
    return resp.data;
  }

  /**
   * Метод получения списка клиентов
   */
  async getVendorClients(
    params: TClientsParams,
  ): Promise<TClientDtoPagedDataDto> {
    const resp = await this.axios.get('/api/clientprofile/internal/Clients', {
      params,
    });
    return resp.data;
  }

  /**
   * Получить клиента по идентификатору
   */
  async getClient(clientId: string): Promise<TClientDto> {
    const resp = await this.axios.get(
      `/api/clientprofile/internal/Clients/${clientId}`,
    );
    log('getClient success, %o', clientId);
    return resp.data;
  }

  /**
   * Метод для блокировки клиента
   */
  async clientsBlock(clientId: string) {
    const resp = await this.axios.put(
      `/api/clientprofile/internal/Clients/${clientId}/block`,
    );
    return resp.data;
  }

  /**
   * Метод для разблокировки клиента
   */
  async clientsUnblock(clientId: string) {
    const resp = await this.axios.put(
      `/api/clientprofile/internal/Clients/${clientId}/unblock`,
    );
    return resp.data;
  }

  /**
   * Метод для изменения клиента
   */
  async editClient(clientId: string, data: TEditClientPayload) {
    const resp = await this.axios.put(
      `/api/clientprofile/internal/Clients/${clientId}`,
      data,
    );
    return resp.data;
  }

  /**
   * Метод получения подробной информации о клиенте из онбординга
   */
  async getClientDetails(clientId: string): Promise<TClientDetailsDto> {
    const resp = await this.axios.get(
      `/api/onboarding/internal/Clients/${clientId}`,
    );
    return resp.data;
  }

  /**
   * Метод получения списка продуктов клиента из онбординга
   */
  async getClientProducts(clientId: string): Promise<TClientProductDto[]> {
    const resp = await this.axios.get(
      `/api/onboarding/internal/Clients/${clientId}/products`,
    );
    return resp.data;
  }

  /**
   * Метод получения списка документов клиента из сервиса clientProfile
   */
  async getClientDocuments(
    clientId: string,
  ): Promise<TClientProfileDocumentSummaryDto[]> {
    const resp = await this.axios.get(
      `/api/clientprofile/internal/Clients/${clientId}/Documents`,
    );
    return resp.data;
  }

  /**
   * Метод получения списка документов клиента из сервиса onboarding
   */
  async getOnboardingDocuments(
    clientId: string,
  ): Promise<TOnboardingDocumentSummaryDto[]> {
    const resp = await this.axios.get(
      `/api/onboarding/internal/Clients/${clientId}/Documents`,
    );
    return resp.data;
  }

  /**
   * Метод получения документа клиента
   */
  async getClientDocument({
    clientId,
    documentId,
    fileName,
  }: {
    clientId: string;
    documentId: string;
    fileName: string;
  }) {
    const { blob } = await prepareBlob(
      this.axios,
      `/api/onboarding/internal/Clients/${clientId}/Documents/${documentId}/download`,
    );
    downloadFile(blob, fileName);
    log('getClientDocument success');
  }

  /**
   * Метод получения списка документов связанного физ лица
   */
  async getRelatedIndividualDocuments(
    clientId: string,
    relatedIndividualId: string,
  ): Promise<TOnboardingDocumentSummaryDto[]> {
    const resp = await this.axios.get(
      `/api/onboarding/internal/Clients/${clientId}/related-individuals/${relatedIndividualId}/documents`,
    );
    return resp.data;
  }

  /**
   * Метод получения документа связанного физ лица
   */
  async getRelatedIndividualDocument({
    clientId,
    documentId,
    relatedIndividualId,
    fileName,
  }: {
    clientId: string;
    documentId: string;
    relatedIndividualId: string;
    fileName: string;
  }) {
    const { blob } = await prepareBlob(
      this.axios,
      `/api/onboarding/internal/Clients/${clientId}/related-individuals/${relatedIndividualId}/documents/${documentId}/download`,
    );
    downloadFile(blob, fileName);
    log('getRelatedIndividualDocument success');
  }

  async deleteClientDocument(
    clientId: string,
    documentId: string,
  ): Promise<void> {
    const resp = await this.axios.delete(
      `/api/clientprofile/internal/Clients/${clientId}/Documents/${documentId}`,
    );
    log('deleteClientDocument success');
    return resp.data;
  }

  async uploadClientDocuments(
    clientId: string,
    data: FormData,
  ): Promise<TUploadedDocumentsInfoDto> {
    const resp = await this.axios.post(
      `api/clientprofile/internal/Clients/${clientId}/Documents/upload/bulk`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    log('uploadClientDocuments success');
    return resp.data;
  }

  /**
   * Метод для получения файла, прикрепленного при создании трансфера
   */
  async getTransferDocument(clientId: string, fileName: string) {
    const { blob } = await prepareBlob(
      this.axios,
      `/api/transferorchestrator/internal/Documents/${fileName}/${clientId}`,
    );
    downloadFile(blob, fileName);
    log('getTransferDocument success');
  }

  /**
   * Метод для получения P&L отчета
   */
  async getProfitAndLossReport(
    params: TProfitAndLossReportQueryParams,
  ): Promise<void> {
    const { blob, fileName } = await prepareBlob(
      this.axios,
      '/api/reporter/internal/Reports/profit-and-loss',
      params,
    );
    downloadFile(blob, fileName);
    log('getProfitAndLossReport success');
  }

  /**
   * Метод для получения BS отчета
   */
  async getBalanceSheetReport(
    params: TBalanceSheetReportQueryParams,
  ): Promise<void> {
    const { blob, fileName } = await prepareBlob(
      this.axios,
      '/api/reporter/internal/Reports/balance-sheet',
      params,
    );
    downloadFile(blob, fileName);
    log('getBalanceSheetReport success');
  }

  /**
   * Метод для получения Reconciliation отчета
   */
  async getReconciliationReport(
    params: TReconciliationReportQueryParams,
  ): Promise<void> {
    const { blob, fileName } = await prepareBlob(
      this.axios,
      '/api/generalledger/internal/Reports/ReconciliationReport',
      params,
    );

    downloadFile(blob, fileName);
    log('getReconciliationReport success');
  }

  /**
   * Метод для получения Rails transactions отчета
   */
  async getRailsTransactionsReport(
    params: TRailsTransactionsReportQueryParams,
  ): Promise<void> {
    const { blob, fileName } = await prepareBlob(
      this.axios,
      '/api/reporter/internal/Railsr/transactions-sheet',
      params,
    );
    downloadFile(blob, fileName);
    log('getRailsTransactionsReport success');
  }

  /**
   * Получение списка провайдеров
   */
  async getBaasProviders(): Promise<TBaasProviderDto[]> {
    const resp = await this.axios.get('/api/accounts/internal/BaasProviders');
    log('getBaasProviders success');
    return resp.data;
  }

  /**
   * Получение транзакции
   */
  async getTransaction(id: string): Promise<TTransactionDto> {
    const resp = await this.axios.get(
      `/api/generalledger/internal/Transactions/${id}`,
    );
    log('getTransaction success, id: %o', id);
    return resp.data;
  }

  /**
   * Получение тарифа клиента
   */
  async getClientTariff(clientId: string): Promise<TClientTariffDto> {
    const resp = await this.axios.get(
      `/api/fee/internal/Clients/${clientId}/tariffs`,
    );
    log('getClientTariff success, %o', clientId);
    return resp.data;
  }

  /**
   * Получение списка балансов для управления денежными средствами
   */
  async getTreasuryManagementSummary(
    params: TTreasuryManagementSummaryParams,
  ): Promise<TTreasuryManagementSummaryPagedDataDto> {
    const resp = await this.axios.get(
      '/api/generalledger/internal/TreasuryManagement/summary',
      {
        params,
      },
    );
    log('getTreasuryManagementSummary success');
    return resp.data;
  }

  /**
   * Разблокировать трансфер
   */
  async unholdOutgoingTransfer(transferId: string): Promise<string> {
    const resp = await this.axios.post(
      `/api/transferorchestrator/internal/Transfers/outgoing/${transferId}/continue-processing/`,
    );
    log('unholdOutgoingTransfer with id %s success', transferId);
    return resp.data;
  }

  /**
   * Получение отчета по транзакциям
   */
  async getTransactionsReport(
    params: TTransactionsReportQueryParams,
  ): Promise<void> {
    const { blob, fileName } = await prepareBlob(
      this.axios,
      '/api/generalledger/internal/Transactions/report',
      params,
    );
    downloadFile(blob, fileName);
    log('getTransactionsReport success');
  }

  /**
   * Получения отчёта по аккаунтам
   */
  async getAccountsReport(params: TAccountsReportQueryParams): Promise<void> {
    const { blob, fileName } = await prepareBlob(
      this.axios,
      '/api/accounts/internal/Accounts/report',
      params,
    );
    downloadFile(blob, fileName);
    log('getAccountsReport success');
  }

  async getTariffPlans(
    params: TTariffPlansQueryPagedParams,
  ): Promise<TTariffPlansPagedDataDto> {
    const resp = await this.axios.get('/api/fee/internal/Tariffs', {
      params,
    });
    log('getTariffPlans success');
    return resp.data;
  }

  async getTariffPlan(tariffId: string): Promise<TTariffDto> {
    const resp = await this.axios.get(`/api/fee/internal/Tariffs/${tariffId}`);
    log('getTariffPlan success');
    return resp.data;
  }

  /**
   * Получение списка валют
   */
  async getCurrencies(): Promise<TReferencesCurrencyDto[]> {
    const resp = await this.axios.get('/api/generalledger/internal/Currencies');
    log('getCurrencies success');
    return resp.data;
  }

  /**
   * Получение валюты по коду
   */
  async getCurrencyByCode(
    currencyCode?: string,
  ): Promise<TReferencesCurrencyDto> {
    const resp = await this.axios.get(
      `/api/generalledger/internal/Currencies/${currencyCode}`,
    );
    return resp.data;
  }

  /**
   * Создание новой валюты
   */
  async createNewCurrency(data: FormData): Promise<TReferencesCurrencyDto> {
    const resp = await this.axios.post(
      '/api/generalledger/internal/Currencies',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    log('createNewCurrency success');
    return resp.data;
  }

  /**
   * Метод для изменения валюты
   */
  async editCurrency(currencyCode: string, data: FormData) {
    const resp = await this.axios.put(
      `/api/generalledger/internal/Currencies/${currencyCode}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    log('editCurrency success');
    return resp.data;
  }

  /**
   * Получение данных для Baas Providers в References
   */
  async getBaasProvidersCurrencies(): Promise<TBaasProvidersCurrenciesDto[]> {
    const resp = await this.axios.get(
      '/api/accounts/internal/BaasProviders/currencies',
    );
    log('getBaasProvidersCurrencies success');
    return resp.data;
  }

  /**
   * Создание привязки валюты Baas Providers в References
   */
  async createBaasProvidersCurrency(
    data: TBaasProvidersCurrenciesDto,
  ): Promise<TBaasProvidersCurrenciesDto> {
    const resp = await this.axios.post(
      '/api/accounts/internal/BaasProviders/currencies',
      data,
    );
    log('createBaasProvidersCurrencies success');
    return resp.data;
  }

  /**
   * Удаление привязки валюты Baas Providers в References
   */
  async deleteBaasProvidersCurrency(
    data: TBaasProvidersCurrenciesDto,
  ): Promise<TBaasProvidersCurrenciesDto> {
    const resp = await this.axios.delete(
      '/api/accounts/internal/BaasProviders/currencies',
      { data },
    );
    log('deleteBaasProvidersCurrency success', data);
    return resp.data;
  }

  async createCustomTariffPlan(data: TCreateTariffDto): Promise<string> {
    const resp = await this.axios.post('/api/fee/internal/Tariffs', data);
    log('createCustomTariffPlan success');
    return resp.data;
  }

  async editTariffPlan(
    tariffId: string,
    data: TEditTariffPlanDto,
  ): Promise<string> {
    const resp = await this.axios.put(
      `/api/fee/internal/Tariffs/${tariffId}`,
      data,
    );
    log('editTariffPlan success');
    return resp.data;
  }

  async editClientsTariff(
    tariffId: string,
    clientIds: string[],
  ): Promise<void> {
    const resp = await this.axios.patch(
      `api/fee/internal/Tariffs/${tariffId}/clients`,
      clientIds,
    );
    log('editClientsTariff success');
    return resp.data;
  }
  /**
   * Получение списка ежедневных проверок
   */
  async getDailyChecks(params: TDailyCheckParams): Promise<TDailyCheckDto[]> {
    const resp = await this.axios.get(
      '/api/generalledger/internal/DailyCheck',
      {
        params,
      },
    );
    log('getDailyChecks success');
    return resp.data;
  }

  /**
   * Получение детализированного списка ежедневных проверок
   */
  async getDailyChecksDetails(
    params: TDailyCheckDetailsParams,
  ): Promise<TDailyCheckDetailedDto[]> {
    const resp = await this.axios.get(
      '/api/generalledger/internal/DailyCheck/details',
      {
        params,
      },
    );
    log('getDailyChecksDetails success');
    return resp.data;
  }

  /**
   * Получения отчёта по аккаунтам
   */
  async getTransferStatement(params: {
    transferId: string;
    clientId: string;
  }): Promise<void> {
    const { blob, fileName } = await prepareBlob(
      this.axios,
      '/api/reporter/internal/Reports/transfer-statement',
      params,
    );
    downloadFile(blob, fileName);
    log('getTransferStatement success');
  }

  /** Отправить клиенту отчёт по трансферу */
  async sendTransferReport(params: {
    transferId: string;
    clientId: string;
  }): Promise<void> {
    const resp = await this.axios.post(
      '/api/reporter/internal/Reports/send-transfer-statement-report',
      params,
    );
    log('sendTransferReport success');
    return resp.data;
  }

  async createClientAccount(
    data: TCreateClientAccountDto,
  ): Promise<TAccountDto> {
    const resp = await this.axios.post(
      '/api/accounts/internal/Accounts/createClientAccount',
      data,
    );
    log('createAccount success');
    return resp.data;
  }

  async assignTransferToClient(data: TAssignTransferParams): Promise<void> {
    const resp = await this.axios.put(
      `/api/transferorchestrator/internal/Transfers/incoming/${data.transferId}`,
      {
        clientId: data.clientId,
      },
    );
    return resp.data;
  }

  /**
   * Запуск пересчета балансов опер дня
   */
  async recalculateDailyAccountBalance(
    data: TRecalculateDailyAccountBalancesCommand,
  ): Promise<void> {
    const resp = await this.axios.post(
      '/api/generalledger/internal/DailyAccountBalance/recalculate',
      data,
    );
    log('recalculateDailyAccountBalance success');
    return resp.data;
  }

  async createPaymentRequest(data: FormData): Promise<string> {
    const resp = await this.axios.post(
      'api/PaymentRequest/internal/PaymentRequests',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    log('createPaymentRequest success');
    return resp.data;
  }

  async getPaymentRequests(
    params: TPaymentRequestPagedParams,
  ): Promise<TPaymentRequestPagedDataDto> {
    const resp = await this.axios.get(
      'api/PaymentRequest/internal/PaymentRequests',
      {
        params,
      },
    );
    log('getPaymentRequests success');

    return resp.data;
  }

  async getPaymentRequest(id: string): Promise<TPaymentRequestDto> {
    const resp = await this.axios.get(
      `api/PaymentRequest/internal/PaymentRequests/${id}`,
    );
    log('getPaymentRequest success');
    return resp.data;
  }

  /**
   * Метод для получения файла, прикрепленного при создании платежного требования
   */
  async getPaymentRequestDocument(clientId: string, fileName: string) {
    const { blob } = await prepareBlob(
      this.axios,
      `/api/PaymentRequest/internal/Documents/${fileName}/${clientId}`,
    );
    downloadFile(blob, fileName);
    log('getTransferDocument success');
  }

  /** Выдача реквизитов аккаунту */
  async addCredentials(params: TCreateCredentialsCommandDto): Promise<void> {
    await this.axios.post('/api/accounts/internal/Credentials', params);
    log('addCredentials success, %o', params);
  }

  /**
   * Получение списка ежедневных проверок
   */
  async getExchangeRates(
    params: TExchangeRatesQueryParams,
  ): Promise<TExchangeRatesDtoPagedDataDto> {
    const resp = await this.axios.get(
      '/api/manual-baas/backoffice/exchange-rate',
      {
        params,
      },
    );
    log('getExchangeRates success');
    return resp.data;
  }

  /**
   * Получение списка ежедневных проверок
   */
  async saveExchangeRate(
    data: TSaveExchangeRateCommandDto,
  ): Promise<TExchangeRatesDtoPagedDataDto> {
    const resp = await this.axios.post(
      '/api/manual-baas/backoffice/exchange-rate',
      data,
    );
    log('getExchangeRates success');
    return resp.data;
  }

  /**
   * Получение списка входящих переводов
   */
  async getMBIncomingTransfers(
    params: TMBCommonTransferQueryParams,
  ): Promise<TMBIncomingTransferDtoPagedDataDto> {
    const resp = await this.axios.get(`${MANUAL_BAAS_API}/incoming-transfer`, {
      params,
    });
    return resp.data;
  }

  /**
   * Создание входящего перевода
   */
  async createMBIncomingTransfer(
    data: TMBNewIncomingTransferDto,
  ): Promise<TMBIncomingTransferDto> {
    const resp = await this.axios.post(
      `${MANUAL_BAAS_API}/incoming-transfer`,
      data,
    );
    return resp.data;
  }

  /**
   * Создание входящего перевода
   */
  async calculateMBExchangeTransfer(
    data: TMBCalculateExchangeTransferDto,
  ): Promise<TMBCalculateExchangeTransferResultDto> {
    const resp = await this.axios.post(
      `${MANUAL_BAAS_API}/fx-transfer/calculate`,
      data,
    );
    return resp.data;
  }

  /**
   * Получение входящего перевода по ID
   */
  async getMBIncomingTransferById(
    transferId?: string,
  ): Promise<TMBIncomingTransferDto> {
    const resp = await this.axios.get(
      `${MANUAL_BAAS_API}/incoming-transfer/${transferId}`,
    );
    return resp.data;
  }

  /**
   * Получение списка исходящих переводов
   */
  async getMBOutgoingTransfers(
    params: TMBCommonTransferQueryParams,
  ): Promise<TMBOutgoinTransferDtoPagedDataDto> {
    const resp = await this.axios.get(`${MANUAL_BAAS_API}/outgoing-transfer`, {
      params,
    });
    return resp.data;
  }

  /**
   * Получение списка исходящих переводов
   */
  async getMBExchangeTransfers(
    params: TMBCommonTransferQueryParams,
  ): Promise<TMBExchangeTransferDtoPagedDataDto> {
    const resp = await this.axios.get(`${MANUAL_BAAS_API}/fx-transfer`, {
      params,
    });
    return resp.data;
  }

  /**
   * Получение exchange трансфера по ID
   */
  async getMBExchangeTransferById(
    transferId?: string,
  ): Promise<TMBExchangeTransferDto> {
    const resp = await this.axios.get(
      `${MANUAL_BAAS_API}/fx-transfer/${transferId}`,
    );
    return resp.data;
  }

  /**
   * Получение исходящего перевода по ID
   */
  async getMBOutgoingTransferById(
    transferId?: string,
  ): Promise<TMBOutgoingTransferDto> {
    const resp = await this.axios.get(
      `${MANUAL_BAAS_API}/outgoing-transfer/${transferId}`,
    );
    return resp.data;
  }

  /**
   * Отклонить исходящий перевод
   */
  async rejectMBOutgoingTransfer(
    data: TMBRejectOutgoingTransferDto,
  ): Promise<TMBOutgoinTransferDtoPagedDataDto> {
    const resp = await this.axios.post(
      `${MANUAL_BAAS_API}/outgoing-transfer/reject`,
      data,
    );
    return resp.data;
  }

  /**
   * Отклонить исходящий перевод
   */
  async confirmMBOutgoingTransfer(
    data: TMBConfirmOutgoingTransferDto,
  ): Promise<TMBOutgoinTransferDtoPagedDataDto> {
    const resp = await this.axios.post(
      `${MANUAL_BAAS_API}/outgoing-transfer/confirm`,
      data,
    );
    return resp.data;
  }

  /**
   * Подтвердить fx-перевод
   */
  async confirmMBExchangeTransfer(
    data: TMBConfirmExchangeTransferDto,
  ): Promise<TMBExchangeTransferDto> {
    const resp = await this.axios.post(
      `${MANUAL_BAAS_API}/fx-transfer/confirm`,
      data,
    );
    return resp.data;
  }

  /**
   * Отклонить fx-перевод
   */
  async rejectmMBExchangeTransfer(
    data: TMBRejectExchangeTransferDto,
  ): Promise<TMBExchangeTransferDto> {
    const resp = await this.axios.post(
      `${MANUAL_BAAS_API}/fx-transfer/reject`,
      data,
    );
    return resp.data;
  }

  /**
   * Редактирование анкеты клиента
   */
  async putClient(clientId: string, data: TUpdateClientCommand) {
    const resp = await this.axios.put(
      `/api/onboarding/internal/Clients/${clientId}`,
      data,
    );
    return resp.data;
  }

  /**
   * Перезапуск трансфера
   */
  async restartTransfer(transferId: string): Promise<void> {
    const resp = await this.axios.post(
      `/api/transferorchestrator/internal/Transfers/incoming/${transferId}/restarts`,
    );
    log('restartTransfer success');
    return resp.data;
  }

  /**
   * Получение всех валют пользователя
   */
  async getClientCurrencies(): Promise<TCurrencyDto[]> {
    const resp = await this.axios.get('/api/generalledger/internal/Currencies');
    log('getCurrencies success');
    return resp.data;
  }

  /**
   * Справочник с описанием статусов трансфера
   */
  async getTransferStateDescription(): Promise<TTransferStatusDescription> {
    const resp = await this.axios.get(
      '/api/transferorchestrator/internal/Transfers/state-descriptions',
    );
    return resp.data;
  }

  /**
   * Получение истории изменений статусов трансфера
   */
  async getTransferStateHistory(
    transferId: string,
  ): Promise<TTransferStateHistoryDto[]> {
    const resp = await this.axios.get(
      `/api/transferorchestrator/internal/Transfers/${transferId}/state-history`,
    );
    return resp.data;
  }

  async changeClientStatus(
    clientId: string,
    status: TClientStatusDto,
  ): Promise<void> {
    const resp = await this.axios.patch(
      `/api/clientprofile/internal/Clients/${clientId}/status`,
      { status },
    );
    return resp.data;
  }
}
