import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverContentProps,
  PopoverTrigger,
  Portal,
  useControllableState,
  useDisclosure,
} from '@chakra-ui/react';
import ScrollBar from 'react-perfect-scrollbar';
import { debounce } from 'lodash';
import { DropdownOptions } from './DropdownOptions';
import { TDropdownOption, TRenderValueFunc } from './types';
import { defaultPopoverProps, popoverContentVariants } from './const';
import { MultiDropdownTrigger } from './MultiDropdownTrigger';
import { useTranslation } from 'react-i18next';
import { DropdownSearch } from './DropdownSearch';
import { Loader } from '../Loader/Loader';

export type TMultiDropdownProps = {
  /**
   * Опции выпадающего списка
   * Если есть опция со значением null - она показывается при невыбранном значении
   */
  options: TDropdownOption[];
  /**
   * Плавающий заголовок поля (Если есть то Type2, если нет то Type1)
   */
  floatingLabel?: string;
  /**
   * значение (не использовать undefined!)
   */
  value?: Array<string | number> | null;
  /**
   * Обработчик при выборе значения
   * @param value
   */
  onChange?: (value: Array<string | number> | null) => void;
  /**
   * Выключен
   */
  isDisabled?: boolean;
  /**
   * Ошибка
   */
  isError?: boolean;
  /**
   * Загрузка данных
   */
  isLoading?: boolean;
  /**
   * Доп. стили для PopoverContent
   */
  popoverContentProps?: PopoverContentProps;
  /**
   * не обрезать длинное значение в триггере, а переносить на новую строку
   */
  noEllipsis?: boolean;
  /**
   * Скрывать кнопку очищения
   */
  hideClearButton?: boolean;
  /**
   * Показывать когда значение не выбрано (в Type 1 - не floating)
   */
  placeholder?: string;
  /**
   * Если есть, то показывать в списке опций опцию "Выбрать всё" c заданным текстом
   */
  selectAllLabel?: string;
  /**
   * При потере фокуса
   */
  onBlur?: VoidFunction;
  /**
   * Отображать при пустом значении (вместо самого значения)
   */
  emptyValueText?: boolean;
  /**
   * Функция для отрисовки значения
   */
  renderValue?: TRenderValueFunc;
  /**
   * Считать пустое значение как "выбрано всё"
   */
  nullValueAsAllSelected?: boolean;
  /**
   * Поведение при клике на "Выбрать всё"
   */
  selectAllClickBehaviour?: 'select-all' | 'toggle-selected';
  /**
   * Поведение остальных опций, когда выбрано всё (выбраны или нет)
   */
  selectAllOptionsBehaviour?: 'selected' | 'unselected';
  /**
   * Если выбрано всё - показывать selectAllLabel как значение
   */
  showSelectAllLabelAsValue?: boolean;
  /**
   * Поведение при клике на последнюю выбранную опцию
   */
  lastSelectedOptionBehaviour?: 'keep' | 'deselect';
  /**
   * Отображать поле Поиска?
   */
  isSearchable?: boolean;
  /**
   * Обработчик при изменении значения в поле поиска
   */
  onSearchTextChange?: (value: string) => void;
  /**
   * Время задержки при изменеии значения в поле поиска (по умолчанию 250 миллисекунд)
   */
  onSearchTextChangeDelay?: number;
  /**
   * Подсказки браузера при заполнении формы
   */
  autocomplete?: string;
  /**
   * Атрибут для поиска элемента в автотестах
   */
  testId?: string;
  /**
   * Реф бесконечного лоадера в дропдауне
   */
  infiniteScroll?: {
    isLoading: boolean;
    ref: React.LegacyRef<HTMLDivElement>;
  };
  /**
   * Передан отфильтрованный список опций
   */
  isFilteredOptions?: boolean;
};

export const MultiDropdown = (baseProps: TMultiDropdownProps) => {
  const {
    value: propsValue,
    onChange: propsOnChange,
    onSearchTextChange: propsOnSearchTextChange,
    onSearchTextChangeDelay: propsOnSearchTextChangeDelay,
    testId = 'multidropdown',
    ...props
  } = baseProps;

  const { t } = useTranslation();
  const triggerRef = useRef<HTMLDivElement>(null);
  const didMountRef = useRef(false);
  const searchFieldRef = useRef<HTMLInputElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  const { isOpen, onClose, onOpen, onToggle } = useDisclosure();
  const [value, setValue] = useControllableState({
    value: propsValue,
    onChange: propsOnChange,
    defaultValue: null,
  });
  const [searchList, setSearchList] = useState<TDropdownOption[]>(
    props.options,
  );
  const [isFocused, setIsFocused] = useState(
    !props.isDisabled && triggerRef.current === document.activeElement,
  );

  const handleFocus = useCallback(() => {
    if (!props.isDisabled) {
      setIsFocused(true);
    }
  }, [props.isDisabled]);

  const handleBlur = useCallback(() => {
    if (!props.isDisabled) {
      setIsFocused(false);
    }
  }, [props.isDisabled]);

  const { onBlur: outerOnBlur } = props;

  useEffect(() => {
    if (didMountRef.current && !isFocused && !isOpen) {
      outerOnBlur?.();
    }
  }, [isFocused, isOpen, outerOnBlur]);

  const allSelected = useMemo(() => {
    if (!value || (value.length === 0 && props.nullValueAsAllSelected))
      return true;
    return props.options
      .filter((x) => !x.disabled)
      .every((x) => value?.includes(x.value));
  }, [props.options, props.nullValueAsAllSelected, value]);

  const {
    lastSelectedOptionBehaviour = 'deselect',
    selectAllOptionsBehaviour,
  } = props;

  const debounceOnSearchChange = useMemo(
    () =>
      debounce((val: string) => {
        if (propsOnSearchTextChange) {
          propsOnSearchTextChange(val);
        }
      }, propsOnSearchTextChangeDelay || 250),
    [propsOnSearchTextChange, propsOnSearchTextChangeDelay],
  );

  const handleSearchChange = useCallback(
    (val: string) => {
      setSearchText(val);
      if (propsOnSearchTextChange) {
        debounceOnSearchChange(val);
      }
    },
    [setSearchText, propsOnSearchTextChange, debounceOnSearchChange],
  );

  const handleClear = useCallback(() => {
    setValue(null);
    onClose();
    handleSearchChange('');
    setIsFocused(false);
  }, [onClose, setValue, handleSearchChange, setIsFocused]);

  // клик по опции
  const handleOptionClick = useCallback(
    (val: string | number) => {
      setValue((current) => {
        if (!val) {
          return null;
        }
        if (allSelected && selectAllOptionsBehaviour === 'unselected') {
          return [val];
        }
        if (!current) {
          return [val];
        }
        let newValue: Array<string | number>;
        const optionSelected = current?.includes(val);
        if (optionSelected) {
          newValue = current.filter((x) => x !== val);
        } else {
          newValue = [val, ...current];
        }
        if (newValue?.length === 0 && lastSelectedOptionBehaviour === 'keep') {
          return current;
        }
        return newValue;
      });
      if (!!searchText) {
        handleSearchChange('');
      }
    },
    [
      setValue,
      allSelected,
      selectAllOptionsBehaviour,
      lastSelectedOptionBehaviour,
      handleSearchChange,
    ],
  );

  //клик по "выбрать всё"
  const handleSelectAllClick = useCallback(() => {
    if (props.nullValueAsAllSelected) {
      setValue(null);
    } else {
      setValue((current) => {
        switch (props.selectAllClickBehaviour) {
          case 'select-all': {
            if (allSelected) return current;
            return props.options.filter((x) => !x.disabled).map((x) => x.value);
          }
          case 'toggle-selected':
          default: {
            if (!allSelected) {
              return props.options
                .filter((x) => !x.disabled)
                .map((x) => x.value);
            }
            return null;
          }
        }
      });
    }
  }, [
    allSelected,
    props.options,
    setValue,
    props.nullValueAsAllSelected,
    props.selectAllClickBehaviour,
  ]);

  const defaultOptions = props.options.filter((opt) =>
    value?.includes(opt.value),
  );
  const [selectedOptions, setSelectedOptions] = useState<TDropdownOption[]>(
    defaultOptions || [],
  );

  useEffect(() => {
    if (!value || value.length === 0) {
      const nullOption = props.options.find((x) => x.value === null);
      setSelectedOptions(nullOption ? [nullOption] : []);
      return;
    }
    if (selectedOptions.length === value.length) return;
    if (selectedOptions.length < value.length) {
      const newlyAddedOption = value[0];
      const newSelection = props.options.find(
        (option) => option.value === newlyAddedOption,
      );
      if (newSelection) {
        setSelectedOptions((value) => [...value, newSelection]);
      }
    } else {
      const filteredOptions = selectedOptions.filter((option) =>
        value.includes(option.value),
      );
      setSelectedOptions(filteredOptions);
    }
  }, [value]);

  //текст значения триггера (если есть - то триггер выводит его)
  const valueText = useMemo(() => {
    if (!baseProps.renderValue) {
      if (allSelected && baseProps.showSelectAllLabelAsValue) {
        return baseProps.selectAllLabel;
      }
      return undefined;
    }
    return baseProps.renderValue({
      t,
      props: baseProps,
      isAllSelected: allSelected,
      isEmpty: selectedOptions.length === 0,
      selectedOptions: selectedOptions,
      isOpen,
      isFocused,
    });
  }, [allSelected, baseProps, selectedOptions, t, isOpen, isFocused]);

  const filterOptionsList = useCallback(() => {
    const filteredList = props.isFilteredOptions
      ? props.options
      : props.options.filter((option) =>
          option.label.toLowerCase().includes(searchText.toLowerCase()),
        );
    setSearchList(filteredList);
  }, [props.options, searchText]);

  useEffect(() => {
    filterOptionsList();
    if (searchText) {
      onOpen();
    }
  }, [searchText, filterOptionsList, onOpen]);

  const closeDropdownWithResetSearch = useCallback(() => {
    onClose();
    handleSearchChange('');
    setIsFocused(false);
    searchFieldRef?.current?.blur();
  }, [onClose, handleSearchChange, setIsFocused, searchFieldRef]);

  const handleClick = useCallback(
    (e: Event) => {
      if (
        triggerRef.current &&
        !triggerRef.current.contains(e?.target as Node) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e?.target as Node)
      ) {
        closeDropdownWithResetSearch();
      }
    },
    [closeDropdownWithResetSearch],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Esc') {
        closeDropdownWithResetSearch();
      }
    },
    [closeDropdownWithResetSearch],
  );

  useEffect(() => {
    if (isOpen && props.isSearchable) {
      window.addEventListener('mousedown', handleClick);
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('mousedown', handleClick);
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [isOpen, handleClick, handleKeyDown, props.isSearchable]);

  useEffect(() => {
    didMountRef.current = true;
  }, []);

  return (
    <Popover
      matchWidth
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom-start"
      variant="dropdown"
      isLazy
      lazyBehavior="unmount"
      initialFocusRef={props.isSearchable ? searchFieldRef : undefined}
    >
      <PopoverTrigger>
        <Box
          userSelect="none"
          ref={triggerRef}
          tabIndex={0}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClick={props.isDisabled ? undefined : onToggle}
          position="relative"
          outline="none"
          data-testid={testId}
        >
          <MultiDropdownTrigger
            hideClearButton={props.hideClearButton}
            noEllipsis={!!props.noEllipsis}
            onClear={handleClear}
            floatingLabel={props.floatingLabel}
            selectedOptions={selectedOptions}
            isOpen={isOpen}
            isDisabled={props.isDisabled ?? false}
            isError={props.isError ?? false}
            isActive={isFocused || isOpen || !!searchText}
            placeholder={props.placeholder}
            selectAllLabel={props.selectAllLabel}
            valueText={valueText}
            isFocused={isFocused}
            isSearchable={props.isSearchable}
            onOptionClick={handleOptionClick}
            searchField={
              <DropdownSearch
                ref={searchFieldRef}
                onChange={(val) => handleSearchChange(val)}
                searchText={searchText}
                placeholder={props.placeholder}
                isDisabled={props.isDisabled ?? false}
                floatingLabel={props.floatingLabel}
                isMulti
                autocomplete={props.autocomplete}
                testId={testId}
              />
            }
          />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          variants={popoverContentVariants}
          {...defaultPopoverProps(props.options)}
          {...props.popoverContentProps}
          ref={dropdownRef}
          data-testid={`${testId}-body`}
        >
          {props.isLoading ? (
            <Flex justifyContent="center" alignItems="center" h="55px">
              <Loader />
            </Flex>
          ) : (
            <ScrollBar
              options={{ suppressScrollX: true, wheelPropagation: true }}
            >
              {searchList.length ? (
                <DropdownOptions
                  multi
                  allSelected={allSelected}
                  onSelectAllClick={handleSelectAllClick}
                  selectAllLabel={props.selectAllLabel}
                  options={searchList}
                  selected={value ?? []}
                  onOptionClick={handleOptionClick}
                  selectAllOptionsBehaviour={props.selectAllOptionsBehaviour}
                  isSearchable={props.isSearchable}
                  testId={testId}
                  infiniteScroll={props.infiniteScroll}
                />
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={2}
                  data-testid={`${testId}-no-options`}
                >
                  {t('dropdown.noOptions', 'No options')}
                </Box>
              )}
            </ScrollBar>
          )}
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default MultiDropdown;
