import { Outlet, useLocation } from 'react-router-dom';
import { DEFAULT_MOBILE_HEADER_HEIGHT, MENU_WIDTH } from './const';
import { breakpoints, emotionColors } from '@payler/ui-theme';
import styled from '@emotion/styled';
import 'dayjs/locale/ru';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import { ErrorBoundary } from '@payler/ui-components';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import { useClientOfficeConfig } from '../../config/ConfigProvider';
import { FC, ReactNode } from 'react';

const Wrap = styled.main`
  display: grid;
  grid-template-areas: 'nav' 'content';

  * {
    -webkit-tap-highlight-color: transparent;
  }
`;

interface BaseLayoutProps {
  menu: ReactNode
}

export const BaseLayout: FC<BaseLayoutProps> = ({ menu }) => {
  const location = useLocation();
  const { mobileHeaderHeight = DEFAULT_MOBILE_HEADER_HEIGHT } =
    useClientOfficeConfig();
  return (
    <ErrorBoundary errorPage={<ErrorPage />}>
      <Wrap>
        <Content $mobileHeaderHeight={mobileHeaderHeight}>
          <ErrorBoundary key={location.pathname} errorPage={<ErrorPage />}>
            <Outlet />
          </ErrorBoundary>
        </Content>
        {menu}
      </Wrap>
    </ErrorBoundary>
  );
};

const Content = styled.div<{ $mobileHeaderHeight: number }>`
  grid-area: content;
  min-height: ${({ $mobileHeaderHeight }) =>
    `calc(100vh - ${$mobileHeaderHeight}px)`};
  padding: 16px;
  background-color: ${emotionColors.bg};
  @media (min-width: ${breakpoints.md}) {
    min-height: 100vh;
    padding: 40px 24px 24px ${MENU_WIDTH + 48}px;
  }
`;

export default BaseLayout;
