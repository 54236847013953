import { FC } from 'react';
import { LinkProps, Link as ChakraLink } from '@chakra-ui/react';
import {
  Link as RouterDomLink,
  LinkProps as RouterDomLinkProps,
} from 'react-router-dom';
import { IS_IOS_PLATFORM } from '@payler/bank-utils';

export const MobileFriendlyLink: FC<
  LinkProps & RouterDomLinkProps & { loadIn?: 'inApp' | 'defaultBrowser' }
> = ({ loadIn = 'inApp', ...rest }) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (loadIn === 'defaultBrowser' && IS_IOS_PLATFORM) {
      e.preventDefault();
      const url = typeof rest.to === 'string' ? rest.to : '';
      if (url) {
        window.open(url, '_blank');
      }
    }
  };

  return (
    <ChakraLink
      as={RouterDomLink}
      onClick={handleClick}
      data-load-in={loadIn === 'defaultBrowser' ? 'defaultBrowser' : undefined} // For Android links handling only
      {...rest}
    />
  );
};
