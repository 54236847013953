import {
  Button,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  BankModalBody,
  BankModalFooter,
} from '../../../components/BankModal/BankModal';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { useAddParticipantWizardContext } from './AddParticipantWizard';
import { IonIcon } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { useCloseTransferModal } from '../TransferModal';
import { useParticipantFields } from '../../../hooks/participants/queries';
import {
  TAddCredentialsFields,
  TCredentialFieldName,
  TPaymentMethodDto,
} from '@payler/api/client-office';
import { useCredentialFormFields } from './helpers/use-form-fields';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { assert } from '@payler/utils';
import { useLanguageFeatures } from '../../../hooks/use-language-features';
import { getCurrencyIcon } from '../../../currency';
import ExchangeDropdown from '../../../components/ExchangeDropdown/ExchangeDropdown';

const useResolver = (fields?: TCredentialFieldName[]) => {
  if (!fields || fields.length === 0) {
    return undefined;
  }
  let validationFields = {} as Record<TCredentialFieldName, yup.AnySchema>;
  // TODO: добавить расширенную валидацию
  fields.map((field) => {
    switch (field) {
      case 'iban':
        validationFields.iban = yup.string().required();
        break;
      case 'bic':
        validationFields.bic = yup.string().required();
        break;
      case 'ibanOrAccountNumber':
        validationFields.ibanOrAccountNumber = yup.string().required();
        break;
      case 'bicOrBankCode':
        validationFields.bicOrBankCode = yup.string().required();
        break;
      case 'bankName':
        validationFields.bankName = yup.string().required();
        break;
      case 'bankCountry':
        validationFields.bankCountry = yup.string().required();
        break;
      case 'accountNumber':
        validationFields.accountNumber = yup.string().required();
        break;
      case 'sortCode':
        validationFields.sortCode = yup.string().required();
        break;
      case 'network':
        validationFields.network = yup.string().required();
        break;
      case 'walletId':
        validationFields.walletId = yup.string().required();
        break;
      case 'memo':
        validationFields.memo = yup.string().required();
        break;
      case 'bankCode':
        validationFields.bankCode = yup.string().required();
        break;
    }
  });
  return yupResolver(yup.object().shape(validationFields));
};

export const CredentialsStep = () => {
  const { t } = useTranslation([
    'accounts',
    'common',
    'participants',
    'transfers',
  ]);
  const {
    paymentMethod,
    setStep,
    senderAccount,
    credentialsFields,
    setCredentialsFields,
    participant,
  } = useAddParticipantWizardContext();

  // TODO: проверить нужно ли получать closeModal из пропсов. Если нет необходимости выпилить closeModal из TransferWizard
  const closeModal = useCloseTransferModal();
  const { data: participantFields } = useParticipantFields(senderAccount?.id);
  const credentialFieldNames =
    participantFields?.credentials[paymentMethod as TPaymentMethodDto];
  const fields = useCredentialFormFields(credentialFieldNames);

  const methods = useForm<TAddCredentialsFields>({
    resolver: useResolver(credentialFieldNames),
    mode: 'onChange',
    defaultValues: credentialsFields || participant?.credentials,
  });

  const onSubmit = methods.handleSubmit((values) => {
    setCredentialsFields(values);
    setStep('personalInfo');
  });

  const { formatAmountByCurrency } = useLanguageFeatures();

  assert(senderAccount, 'Cannot find selected account');
  const accountOptions = [
    {
      label: formatAmountByCurrency(
        senderAccount.amount,
        senderAccount.currency,
      ),
      value: senderAccount.id,
      caption: senderAccount.name,
      icon: getCurrencyIcon(senderAccount.currency),
    },
  ];

  return (
    <FormProvider {...methods}>
      <BankModalBody>
        <VStack
          id="credentialsForm"
          spacing={2}
          height="100%"
          flex="1"
          alignItems="stretch"
          as="form"
          onSubmit={onSubmit}
        >
          <VStack
            width="100%"
            spacing={3}
            height="100%"
            flex="1"
            alignItems="stretch"
          >
            <ExchangeDropdown
              options={accountOptions}
              value={senderAccount.id}
              optionLabelProps={{
                textStyle: TextStyles.Subtitle14Medium,
              }}
              isDisabled
            />
            <VStack width="100%" spacing={2} height="100%" alignItems="stretch">
              <Text textStyle={TextStyles.Subtitle14Semibold}>
                {t('participants:selectedPaymentMethod', {
                  method: t(`transfers:mappedSystems.${paymentMethod}`),
                })}
              </Text>
              {fields.map((field) => field)}
            </VStack>
          </VStack>
        </VStack>
      </BankModalBody>
      <BankModalFooter>
        <HStack w="100%" spacing={1}>
          <IconButton
            variant="secondary"
            aria-label="back-step"
            w="56px"
            minW="56px"
            h="56px"
            border="none"
            icon={<Icon as={IonIcon} icon={chevronBack} w="24px" h="24px" />}
            onClick={() => setStep('selectPaymentMethod')}
          />
          <Button
            variant="secondary"
            w="100%"
            data-testid="button_cancel"
            border="none"
            onClick={closeModal}
          >
            {t('common:cancel')}
          </Button>
          <Button
            type="submit"
            form="credentialsForm"
            variant="primary"
            w="100%"
            data-testid="button_continue"
          >
            {t('common:continue')}
          </Button>
        </HStack>
      </BankModalFooter>
    </FormProvider>
  );
};
