import { MayBe } from '@payler/bank-utils';
import { TAddressDto } from './address';

export type TClientStatusDto = 'inactive' | 'active' | 'blocked' | 'preActive';

export type TClientTypeDto = 'company' | 'individual';

interface ICommonClientDto {
  id: string;
  type: TClientTypeDto;
  editorUserId: string;
  onboardingId?: MayBe<string>;
  status: TClientStatusDto;
  address: TAddressDto;
  phoneNumber: string;
  email?: MayBe<string>;
  created: string;
  riskLevel: number;
  isDefaultCompliant: boolean;
}

export type TClientDto = TIndividualClientDto | TCompanyClientDto;

export type TIndividualClientDto = ICommonClientDto & {
  type: 'individual';
  firstName: string;
  lastName: string;
  citizenshipCountry: string;
  passportNumber?: MayBe<string>;
  residenceCountry: string;
  dateOfBirth: string;
};

export type TCompanyClientDto = ICommonClientDto & {
  type: 'company';
  legalName: string;
  companyRegistrationDate?: MayBe<string>;
  countryOfRegistration: string;
  taxResidencyCountry?: MayBe<string>;
  registrationNumber?: MayBe<string>;
  industry?: MayBe<string>;
  industryOther?: MayBe<string>;
  turnover?: MayBe<string>;
  webSite?: MayBe<string>;
  taxNumber?: MayBe<string>;
  payInsCountries: string[];
  payOutsCountries: string[];
  isEngagedGambling10K?: MayBe<boolean>;
  isFoundation?: MayBe<boolean>;
  isPubliclyListed?: MayBe<boolean>;
  isRelevantActivity?: MayBe<boolean>;
  purposesAccountOpening?: MayBe<string[]>;
  purposesAccountOpeningOther?: MayBe<string>;
  businessPartners?: MayBe<string[]>;
  fundFlows?: MayBe<string>;
  complianceEvaluation?: MayBe<string>;
  customOptions?: MayBe<string>;
  companyType?: MayBe<string>;
};

export function isIndividualClient(
  client: TClientDto,
): client is TIndividualClientDto {
  return client.type === 'individual';
}

export function isCompanyClient(
  client: TClientDto,
): client is TCompanyClientDto {
  return client.type === 'company';
}
