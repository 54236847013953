import { useMemo } from 'react';
import { ALL_CURRENCIES, TCurrency } from '@payler/bank-utils';

/**
 * TODO: нужно перейти на получение с бека
 * Добавлено только из-за того, что логика крипты завязана на поля isCrypto,network
 * @deprecated
 */
export const useIsCryptoCurrency = (currency?: TCurrency): boolean => {
  
  return useMemo(() => {
    if (!currency) {
      return false;
    }
    const isCryptoCurrency =
      ALL_CURRENCIES.find((item) => item.currencyCode === currency.toUpperCase())?.isCrypto ??
      false;
    return isCryptoCurrency;
  }, [currency]);
};
