import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  TAccountDto,
  TPaymentMethodDto,
  TAddCredentialsFields,
  TCreateParticipantDto,
} from '@payler/api/client-office';
import { useTranslation } from 'react-i18next';
import { SelectPaymentMethodStep } from './SelectPaymentMethodStep';
import { CredentialsStep } from './CredentialsStep';
import { PersonalInfoStep } from './PersonalInfoStep';
import { BankModalTitle } from '../../../components/BankModal/BankModal';

export type TAddParticipantWizardStep =
  | 'selectPaymentMethod'
  | 'credentials'
  | 'personalInfo';

type TAddParticipantWizardContext = {
  onFinishWizard?: VoidFunction;
  onFirstStepBack?: VoidFunction;
  step: TAddParticipantWizardStep;
  setStep: Dispatch<SetStateAction<TAddParticipantWizardStep>>;
  senderAccount?: TAccountDto;
  setSenderAccount: Dispatch<SetStateAction<TAccountDto | undefined>>;
  paymentMethod: TPaymentMethodDto | undefined;
  setPaymentMethod: Dispatch<SetStateAction<TPaymentMethodDto | undefined>>;
  credentialsFields: TAddCredentialsFields | undefined;
  setCredentialsFields: Dispatch<
    SetStateAction<TAddCredentialsFields | undefined>
  >;
  participant: TCreateParticipantDto | undefined;
  setParticipant: Dispatch<SetStateAction<TCreateParticipantDto | undefined>>;
  setParticipantId: Dispatch<SetStateAction<string | undefined>>;
};

const AddParticipantWizardContext = createContext<TAddParticipantWizardContext>(
  {} as TAddParticipantWizardContext,
);

export type TAddParticipantWizardProps = {
  /** Обработчик завершения последнего шага визарда */
  onFinishWizard?: VoidFunction;
  /** Обработчик выхода с первого шага визарда (если не передан, то не будет кнопки "Назад" на первом шаге) */
  onFirstStepBack?: VoidFunction;
  initialStep?: TAddParticipantWizardStep;
  senderAccount?: TAccountDto;
  setSenderAccount: Dispatch<SetStateAction<TAccountDto | undefined>>;
  participant: TCreateParticipantDto | undefined;
  setParticipant: Dispatch<SetStateAction<TCreateParticipantDto | undefined>>;
  setParticipantId: Dispatch<SetStateAction<string | undefined>>;
};

export const AddParticipantWizard: FC<TAddParticipantWizardProps> = ({
  onFinishWizard,
  onFirstStepBack,
  senderAccount,
  setSenderAccount,
  participant,
  setParticipant,
  setParticipantId,
  initialStep,
}) => {
  const { t } = useTranslation('accounts');
  const [step, setStep] = useState<TAddParticipantWizardStep>(
    initialStep || 'selectPaymentMethod',
  );
  const [paymentMethod, setPaymentMethod] = useState<
    TPaymentMethodDto | undefined
  >(participant?.credentials.paymentMethod || undefined);
  const [credentialsFields, setCredentialsFields] = useState<
    TAddCredentialsFields | undefined
  >(participant?.credentials || undefined);

  const ctx: TAddParticipantWizardContext = useMemo(() => {
    return {
      onFinishWizard,
      onFirstStepBack,
      step,
      setStep,
      senderAccount,
      setSenderAccount,
      paymentMethod,
      setPaymentMethod,
      credentialsFields,
      setCredentialsFields,
      participant,
      setParticipant,
      setParticipantId,
    };
  }, [
    onFinishWizard,
    onFirstStepBack,
    senderAccount,
    step,
    paymentMethod,
    setPaymentMethod,
    credentialsFields,
    setCredentialsFields,
    participant,
    setParticipant,
    setParticipantId,
  ]);

  return (
    <AddParticipantWizardContext.Provider value={ctx}>
      <BankModalTitle
        title={t('accountInfo.sendMoney')}
        description={t('transferMoney.transferDetailsDescription')}
      />
      {step === 'selectPaymentMethod' && <SelectPaymentMethodStep />}
      {step === 'credentials' && <CredentialsStep />}
      {step === 'personalInfo' && <PersonalInfoStep />}
    </AddParticipantWizardContext.Provider>
  );
};

export const useAddParticipantWizardContext = () =>
  useContext(AddParticipantWizardContext);
