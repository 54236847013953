export const transfers = {
  recipientInfo: 'Recipient info',
  senderInfo: 'Sender info',
  transferInfo: 'Transfer info',
  date: 'Date',
  sender: 'Sender',
  senderAccount: 'Sender account',
  receiverAccount: 'Receiver account',
  recipient: 'Recipient',
  iban: 'IBAN',
  accountNumber: 'Account number',
  bic: 'BIC',
  ukAccountNumber: 'UK Account number',
  ukSortCode: 'UK Sort code',
  bankName: 'Bank name',
  bankCountry: 'Bank country',
  address: 'Address',
  amount: 'Amount',
  amountCredited: 'Amount Credited',
  amountDebited: 'Amount Debited',
  fee: 'Fee',
  totalAmount: 'Total amount',
  currency: 'Currency',
  transferId: 'Transfer ID',
  type: 'Type',
  system: 'System',
  description: 'Description',
  status: 'Status',
  statuses: {
    completed: 'Completed',
    processing: 'Processing',
    failed: 'Failed',
    technicalProblem: 'Technical Problem',
  },
  incoming: 'Incoming',
  outgoing: 'Outgoing',
  all: 'All',
  chargeAccount: 'Charge account',
  depositAccount: 'Deposit account',
  exchangeRate: 'Exchange rate',
  certainExchangeRate:
    '1 {{senderCurrency}} = {{exchangeRate}} {{recipientCurrency}}',
  approximateExchangeRate:
    '1 {{senderCurrency}} ≈ {{exchangeRate}} {{recipientCurrency}}',
  senderCountry: 'Sender Country',
  repeat: 'Repeat transfer',
  reason: 'Reason',
  important: 'Important',
  attachedDocuments: 'Attached documents',
  mappedSystems: {
    sepa: 'SEPA',
    swift: 'SWIFT',
    internal: 'Internal',
    self: 'Self',
    fasterUK: 'FPS',
    blockchainNetwork: 'Blockchain Network',
    ach: 'Ach',
    undefined: '',
  },
  mappedStatuses: {
    processing: 'Processing',
    completed: 'Completed',
    failed: 'Failed',
    technicalProblem: 'Technical Problem',
  },
  mappedTypes: {
    incoming: 'Incoming',
    outgoing: 'Outgoing',
    fx: 'Exchange',
  },
  transferToSelf: 'Transfer to self',
  feeAllocationDescription: {
    our: 'The sender covers all fees associated with the transfer. The amount sent is the amount that the recipient will receive in full, with no deduction for fees. This is often preferred by senders who want to ensure the recipient gets the entire amount without any deductions.',
    sha: 'Fees are shared between the sender and the recipient. The sender pays the transfer fee to their bank while the recipient may also incur fees from their bank. The total fees deducted can vary depending on the institutions involved.',
  },
  noTransactionsToShow: 'No transactions to show',
  error: 'Error',
} as const;
