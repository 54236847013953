import SumsubWebSdk from '@sumsub/websdk-react';
import i18n from 'i18next';
// const sumsubLog = createLogger('component:SumSubWidget'); // TODO: TT-74 отправлять в свой сентри

import { Box, useColorMode } from '@chakra-ui/react';
import { FC } from 'react';
import { TSamSubOnboardingApplicantDto } from '@payler/api/client-office';

export type SumSubWidgetProps = {
  applicant: TSamSubOnboardingApplicantDto;
  token: string;
  handleExpiration?: () => void;
  isTestEnv?: boolean;
};

export const SumSubWidget: FC<SumSubWidgetProps> = ({
  applicant,
  token,
  handleExpiration,
  isTestEnv,
}) => {
  const { colorMode } = useColorMode();
  const sumsubOptions = {
    addViewportTag: true,
    adaptIframeHeight: true,
  };

  const sumsubConfig = {
    lang: i18n.language,
    email: applicant.email,
    phone: applicant.phone,
    theme: colorMode,
  };

  return (
    <Box
      as={SumsubWebSdk}
      w="100%"
      testEnv={isTestEnv}
      accessToken={token}
      expirationHandler={handleExpiration}
      config={sumsubConfig}
      options={sumsubOptions}
      // onMessage={(data: string, payload: object) =>
      //   sumsubLog('event %s: %o', data, payload)
      // }
      // onError={(data: string) => sumsubLog('error: %o', data)} // TODO: TT-74 отправлять в свой сентри
    />
  );
};
