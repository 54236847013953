import { useMemo } from 'react';
import { useProfileInfo } from './use-profile-info';

export const usePermissions = () => {
  const token = useProfileInfo();
  
  const isGlobalAccounts = token.GlobalAccounts;

  return useMemo(
    () => ({
      isGlobalAccounts,
    }),
    [isGlobalAccounts],
  );
};
