import { createContext, useContext } from 'react';
import { ApiBankAdmin } from '@payler/api/back-office';

const ApiCtx = createContext({ api: new ApiBankAdmin('') });

export const ApiContext: FCC<{api: ApiBankAdmin}> = ({ children, api }) => {
  return <ApiCtx.Provider value={{ api }}>{children}</ApiCtx.Provider>;
};

export const useApi = <T = ApiBankAdmin,>() => {
  const { api } = useContext(ApiCtx);
  return api as T;
};
