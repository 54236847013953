export const menu = {
  home: 'Home',
  accountSettings: 'Account settings',
  accounts: 'Accounts',
  account: 'Account',
  support: 'Support',
  sendMoney: 'Send money',
  notifications: 'Notifications',
  recipients: 'Recipients',
  activity: 'Activity',
  logout: 'Log out',
  title: '{{companyName}} Global Accounts',
  johnDoe: 'John Doe',
  onboardingProcess: 'Onboarding process',
  demo: 'Demo',
} as const;
