import React, { ReactElement, isValidElement, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { emotionColors, TextStyles } from '@payler/ui-theme';
import { BoxProps } from '@chakra-ui/layout';

type TSegmentedPickerOption = { key: string; value: string };

const isSegmentedPickerOption = (
  option: string | ReactElement | TSegmentedPickerOption,
): option is TSegmentedPickerOption => {
  return isValidElement(option) || typeof option === 'string'
    ? false
    : 'key' in (option as TSegmentedPickerOption);
};
export interface SegmentedPickerProps {
  /** Массив с опциями */
  options: Array<string | ReactElement | TSegmentedPickerOption>;
  /** Индекс выбранного элемента */
  selection?: number | string;
  /** Callback для обработки выбора опции */
  onSelectionChange: (newSelection: number, value?: string) => void;
  size?: 'default' | 'lg';
  className?: string;
  optionPaddingX?: number;
  /**
   * Отображать на всю ширину контейнера
   */
  isFullWidth?: boolean;
  /**
   * @type {BoxProps} Props для отдельного пункта
   */
  optionProps?: BoxProps;
  divider?: boolean;
}

export interface SegmentedPickerOptionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /** Toggle с выборанным элементом */
  isSelected: boolean;
  size?: SegmentedPickerProps['size'];
  optionPaddingX?: number;
}

/**
 *
 * @param {string[]} options - Массив с опциями
 * @param {number} selection - Индекс выбранного элемента
 * @param {Function} onSelectionChange - Callback для обработки выбора опции
 * @param className
 * @param optionPaddingX - принудительное значения горизонтального отступа в опциях
 * @param size - default - 42px, lg - 48px
 * @param isFullWidth - Отображать на всю ширину контейнера
 * @param optionProps - Props для отдельного пункта
 * @constructor
 *
 * @example
 * function App() {
 *   const [selection, setSelection] = useState(0);
 *   const options = ['Apple', 'Orange', 'Pear', 'Watermelon'];
 *
 *   return (
 *      <SegmentedPicker
 *        options={options}
 *        selection={selection}
 *        onSelectionChange={(newSelection) => {
 *          setSelection(newSelection);
 *        }}
 *      />
 *   );
 * }
 */
export const SegmentedPicker = ({
  options,
  selection,
  onSelectionChange,
  className,
  optionPaddingX,
  size = 'default',
  isFullWidth,
  optionProps,
  divider,
}: SegmentedPickerProps) => {
  const wrapperDisplay = isFullWidth ? 'flex' : 'inline-flex';
  const wrapperHeight = size === 'lg' ? '48px' : '42px';
  const optionWidth = isFullWidth ? '100%' : undefined;
  const optionPadding = `0 ${optionPaddingX ?? 24}px`;
  const optionHeight = size === 'lg' ? '40px' : '24px';
  const borderBottom = divider
    ? `0.5px solid ${emotionColors.primary50}`
    : 'none';

  const selectionIndex =
    typeof selection === 'string'
      ? options.findIndex((option) =>
          isSegmentedPickerOption(option) ? option.key === selection : 0,
        )
      : selection;

  const componentOptions = useMemo(() => {
    return options.map((option, index) => {
      const optionText = isSegmentedPickerOption(option)
        ? option.value
        : option;
      return (
        <Box
          key={index}
          textStyle={TextStyles.Buttons12Medium}
          height={optionHeight}
          color="primary.350"
          bgColor="secondary.400"
          display="inline-flex"
          alignItems="center"
          text-align="center"
          flex-grow="1"
          flex-basis="0"
          padding={optionPadding}
          borderRadius={1}
          cursor="pointer"
          userSelect="none"
          width={optionWidth}
          textAlign="center"
          justifyContent="center"
          data-testid={`segmented-picker-option-${index}${
            index === selectionIndex ? '-active' : ''
          }`}
          whiteSpace="nowrap"
          onClick={() => {
            onSelectionChange(
              index,
              isSegmentedPickerOption(option) ? option.key : undefined,
            );
          }}
          boxShadow="segmentedPickerOption"
          marginRight={index !== options.length - 1 ? 1 : 0}
          {...optionProps}
          {...(index === selectionIndex && {
            color: 'primary.400',
            backgroundColor: 'segmentedPickerOptionBg',
            outline: '1px solid',
            outlineColor: 'brands.500',
          })}
        >
          {optionText}
        </Box>
      );
    });
  }, [
    options,
    optionHeight,
    optionPadding,
    optionWidth,
    onSelectionChange,
    optionProps,
    selectionIndex,
  ]);

  return (
    <Box
      display={wrapperDisplay}
      alignItems="center"
      padding={1}
      borderBottom={borderBottom}
      overflow="hidden"
      height={wrapperHeight}
      className={className}
      data-testid="segmented-picker"
    >
      {componentOptions}
    </Box>
  );
};
SegmentedPicker.displayName = 'SegmentedPicker';
