import {
  TOnboardingStepDto,
  TSamSubOnboardingApplicantDto,
  TSamSubOnboardingSumsubApplicantDto,
  TStageTypeDto,
  TStepTypeDto,
  TSumSubAccessTokenDto,
} from '@payler/api/client-office';
import { useGetAxiosError } from '@payler/bank-utils';
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { lowerFirst } from 'lodash';
import { useClientOfficeConfig } from '../../config/ConfigProvider';
import { checkStartOnboardingError } from '../../pages/OnboardingPage/OnboardingPage';
import { useApi } from '../../state/api';

/**
 * Запрос этапов прохождения онбординга. Игнорирует ошибку старта онбординга. Сразу сортирует
 * по порядку.
 */
export const useOnboardingStagesQuery = ({
  enabled = true,
  refetchInterval,
  isRefetchingAllowed = true,
}: {
  enabled?: boolean;
  /** Периодический запрос этапов онбординга. В демо кабинете используется чтобы отображать
   * актуальный статус прохождения этапов. */
  refetchInterval?: number;
  /** При прохождении онбординга не нужно перезапрашивать из-за zoho форм */
  isRefetchingAllowed?: boolean;
}) => {
  const api = useApi();
  const getError = useGetAxiosError();
  return useQuery({
    queryKey: ['onboarding', 'stages'],
    queryFn: async () => await api.getOnboardingStages(),
    enabled,
    refetchInterval,
    refetchOnMount: isRefetchingAllowed,
    refetchOnReconnect: isRefetchingAllowed,
    refetchOnWindowFocus: isRefetchingAllowed,
    retry: (failureCount, error) => {
      return (
        !checkStartOnboardingError(getError(error).errorCode) &&
        failureCount <= 3
      );
    },
    throwOnError: (error) => {
      return !checkStartOnboardingError(getError(error).errorCode);
    },
    select: ({ stages, ...rest }) => ({
      stages: stages.sort((a, b) => a.orderNumber - b.orderNumber),
      ...rest,
    }),
  });
};

export const useStartOnboarding = () => {
  const api = useApi();
  const client = useQueryClient();
  return useMutation({
    mutationKey: ['startOnboarding'],
    mutationFn: () => api.startOnboarding(),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['onboarding'] });
    },
  });
};

export const useOnboardingStageStepQuery = (
  stage?: TStageTypeDto,
  refetchInterval?: number,
) => {
  const api = useApi();
  return useQuery({
    queryKey: ['onboarding', 'step', stage],
    queryFn: async () => await api.getOnboardingStageStep(stage),
    enabled: !!stage,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval,
  });
};

export const useOnboardingStepQuery = (step?: TStepTypeDto) => {
  const api = useApi();
  return useQuery({
    queryKey: ['onboarding', 'step', step],
    queryFn: async () => await api.getOnboardingStep(step),
    enabled: [
      'briefForm',
      'preScoringForm',
      'documentsForm',
      'creatingAccount',
    ].includes(lowerFirst(step)),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useOnboardingStepMockQuery = (step?: TStepTypeDto) => {
  const api = useApi();
  const { onboardingMockUrls } = useClientOfficeConfig();
  return useQuery({
    queryKey: ['onboarding', 'step', step],
    queryFn: async () => {
      const token = await api.getToken();
      let response: TOnboardingStepDto;
      if (step?.toLowerCase() === 'briefForm'.toLowerCase()) {
        response = {
          iframeUrl: `${onboardingMockUrls?.briefForm}?token=${token}&nextStep=documentsForm`,
          type: step,
          status: 'notStarted',
        };
      } else if (step?.toLowerCase() === 'documentsForm'.toLowerCase()) {
        response = {
          iframeUrl: `${onboardingMockUrls?.documentsForm}?token=${token}&nextStep=preScoringForm`,
          type: step,
          status: 'notStarted',
        };
      } else if (step?.toLowerCase() === 'preScoringForm'.toLowerCase()) {
        response = {
          iframeUrl: `${onboardingMockUrls?.preScoringForm}?token=${token}&nextStep=creatingAccount`,
          type: step,
          status: 'notStarted',
        };
      }
      return new Promise<TOnboardingStepDto>((resolve) => {
        resolve(response);
      });
    },
    enabled: !!step,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useOnboardingPaymentQuery = () => {
  const api = useApi();
  const getError = useGetAxiosError();
  return useQuery({
    queryKey: ['onboarding', 'payment'],
    queryFn: async () => await api.getOnboardingPayment(),
    retryDelay: 5000,
    retry: (failureCount, error) =>
      getError(error).errorCode !== 'FAILED_CREATE_PAYMENT' &&
      (getError(error).errorCode === 'CLIENT_NOT_FOUND'
        ? true
        : failureCount <= 3),
    throwOnError: (error) =>
      !['FAILED_CREATE_PAYMENT', 'CLIENT_NOT_FOUND'].includes(
        getError(error).errorCode,
      ),
    refetchOnWindowFocus: false,
  });
};

/**
 * Возвращает текущий статус кандидата и фукнцию быстрой инвалидации
 * Запрос регулярно обновляется (staleTime = refetchInterval = 1000ms)
 */
export const useBankApplicantQuery = (
  enabled: boolean,
  isMockDataUsed = false,
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const queryKey = ['onboarding', 'applicant'];
  const queryFn = async () => {
    if (isMockDataUsed) {
      const response: TSamSubOnboardingApplicantDto = {
        status: 'userCreated',
        email: 'test@aol.com',
        phone: '+44 20 12345678',
      };
      return Promise.resolve(response);
    } else {
      const response = await api.getSamSubOnboardingApplicant();
      return Promise.resolve(response);
    }
  };

  const queryResult = useQuery({
    queryKey,
    queryFn,
    enabled,
    staleTime: 3000,
    refetchInterval: 3000,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    retry: (failureCount) => failureCount <= 3,
    throwOnError: true,
    structuralSharing: false,
  });

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  const result: [UseQueryResult<TSamSubOnboardingApplicantDto>, () => void] = [
    queryResult,
    invalidate,
  ];

  return result;
};

export const useSumSubApplicantQuery = (enabled: boolean) => {
  const api = useApi();

  return useQuery({
    queryKey: ['onboarding', 'sumsub'],
    queryFn: async () => await api.getSamSubOnboardingSumsubApplicant(),
    enabled,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: (failureCount) => failureCount <= 3,
    throwOnError: true,
  });
};

export const useSumSubApplicantMockQuery = (enabled: boolean) => {
  return useQuery({
    queryKey: ['onboarding', 'sumsub'],
    queryFn: async () => {
      const response: TSamSubOnboardingSumsubApplicantDto = {
        applicantId: '_mock_sms_appId_4b3f3f9a-bbae-4cf6-a7cf-6bd8994e7865',
        reviewStatus: 'none',
        reviewResult: 'none',
      };
      return Promise.resolve(response);
    },
    enabled,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useSumSubTokenQuery = (enabled: boolean) => {
  const api = useApi();
  return useQuery({
    queryKey: ['onboarding', 'sumsub-token'],
    queryFn: async () => await api.getSamSubOnboardingSumsubToken(),
    enabled,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: (failureCount) => failureCount <= 3,
    throwOnError: true,
  });
};

export const useSpushSumsubTokenMockQuery = (enabled: boolean) => {
  return useQuery({
    queryKey: ['onboarding', 'sumsub-token'],
    queryFn: async () => {
      const response: TSumSubAccessTokenDto = {
        token:
          '_act-sbx-jwt-eyJhbGciOiJub25lIn0.eyJqdGkiOiJfYWN0LXNieC05NGYzMzk4MC01ZTJkLTRiN2QtYmU2Ni01OWQwNDlkYmE0ZmItdjIiLCJ1cmwiOiJodHRwczovL2FwaS5zdW1zdWIuY29tIn0.-v2',
      };
      return Promise.resolve(response);
    },
    enabled,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};
