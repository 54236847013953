import {
  EURIcon,
  GBPIcon,
  USDIcon,
  CADIcon,
  DKKIcon,
  NOKIcon,
  PLNIcon,
  CNYIcon,
  BTCIcon,
  ETHIcon,
  USDTIcon,
  TRXIcon,
} from '@payler/ui-icons';
import { CHFIcon, SEKIcon } from './icons';

export function getCurrencyIcon(currency: string) {
  const processedCurrency = currency.toLowerCase();

  switch (processedCurrency) {
    case 'eur':
      return EURIcon;
    case 'gbp':
      return GBPIcon;
    case 'usd':
      return USDIcon;
    case 'cad':
      return CADIcon;
    case 'chf':
      return CHFIcon;
    case 'dkk':
      return DKKIcon;
    case 'nok':
      return NOKIcon;
    case 'pln':
      return PLNIcon;
    case 'sek':
      return SEKIcon;
    case 'cny':
      return CNYIcon;
    case 'btc':
      return BTCIcon;
    case 'eth':
      return ETHIcon;
    case 'usdt-erc':
      return USDTIcon;
    case 'usdt-trc':
      return USDTIcon;
    case 'trx':
      return TRXIcon;
    default:
      return undefined;
  }
}
