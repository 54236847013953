import { Box, Flex, VStack } from '@chakra-ui/react';

import { Outlet, useLocation } from 'react-router-dom';

import { ErrorBoundary, Logo } from '@payler/ui-components';
import { ErrorPage, useClientOfficeConfig } from '@payler/ui/client-office';
import { useBankBreakpointValue } from '@payler/ui/client-office';

export function PaymentLayout() {
  const location = useLocation();
  return (
    <ErrorBoundary errorPage={<ErrorPage />}>
      <Flex as="main" flexDirection="column" minH="100vh">
        <Nav />
        <Flex
          justifyContent="center"
          flexGrow="1"
          p={{ base: '16px', sm: '24px' }}
          bg="menuBG"
        >
          <ErrorBoundary key={location.pathname} errorPage={<ErrorPage />}>
            <Outlet />
          </ErrorBoundary>
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
}

const Nav = () => {
  const isDesktop = useBankBreakpointValue({ base: false, md: true });
  return isDesktop ? <DesktopHeader /> : <MobileHeader />;
};

const BaseHeader = () => {
  const { staticPath, logoFileExtension } = useClientOfficeConfig();
  return (
    <VStack
      maxH={{ base: '100%', sm: '100vh' }}
      as="nav"
      alignItems="stretch"
      spacing={0}
      px={3}
      bg="menuBG"
    >
      <Box pt={{ base: 0, sm: '32px' }} pb={{ base: 0, sm: '40px' }}>
        <Logo staticPath={staticPath} logoFileExtension={logoFileExtension} />
      </Box>
    </VStack>
  );
};

const DesktopHeader = () => (
  <Box gridArea="nav" bg="bg">
    <BaseHeader />
  </Box>
);

const MobileHeader = () => (
  <Box as="nav" bg="bg">
    <BaseHeader />
  </Box>
);

export default PaymentLayout;
