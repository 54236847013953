import React, { useMemo } from 'react';
import {
  Flex,
  HStack,
  IconButton,
  IconButtonProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@payler/ui-icons';
import { useMediaQuery } from '@chakra-ui/react';
import { breakpoints, TextStyles } from '@payler/ui-theme';
import { VerticalDivider } from '../Divider/VerticalDivider';

const DEFAULT_DESKTOP_SIBLINGS_COUNT = 1;
const DEFAULT_MOBILE_SIBLINGS_COUNT = 0;
const DEFAULT_BOUNDARY_COUNT = 1;

interface PaginationItemsProps {
  /**
   * Указывает общее количество записей, которое требуется разбить на страницы.
   * @type {number}
   */
  totalRecords: number;
  /**
   * Указывает количество отображаемых записей на каждой странице. Если этот параметр не указан
   * @type {number}
   * @default - 10
   */
  pageSize: number;
  /**
   * Функция, вызываемая с данными по текущему состоянию разбивки на страницы, только в случае изменения текущей страницы
   * @param pageData
   */
  onPageChanged: (page: number) => void;
  /**
   * Текущая страница
   * @type {number}
   * @default - 1
   */
  currentPage: number;
  /** Количество соседних элементов выбранной страницы в десктоп версии */
  desktopSiblingsCount?: number;
  /** Количество соседних элементов выбранной страницы в мобильной версии */
  mobileSiblingsCount?: number;
}

const usePages = (
  siblingCount: number,
  { currentPage, totalRecords, pageSize }: PaginationItemsProps,
  isShowEllipsis = true,
) => {
  const totalPages = useMemo(
    () => Math.ceil(totalRecords / pageSize),
    [pageSize, totalRecords],
  );

  return useMemo(() => {
    // количество отображаемых крайних страниц
    const boundaryCount = DEFAULT_BOUNDARY_COUNT;
    // секция начальных страниц
    const startPages = range(1, Math.min(boundaryCount, totalPages));
    // секция конечных страниц
    const endPages = range(
      Math.max(totalPages - boundaryCount + 1, boundaryCount + 1),
      totalPages,
    );

    // с какой страницы начинается секция соседей
    const siblingsStart = Math.max(
      Math.min(
        // старт относительно текущей страницы
        currentPage - siblingCount,
        // старт относительно последней страницы
        totalPages -
          boundaryCount -
          siblingCount * 2 -
          (isShowEllipsis ? 1 : 0),
      ),
      // старт относительно первой страницы
      boundaryCount + (isShowEllipsis ? 2 : 1),
    );

    // на какой странице заканчивается секция соседей
    const siblingsEnd = Math.min(
      Math.max(
        // конец относительно текущей страницы
        currentPage + siblingCount,
        // конец относительно первой страницы
        boundaryCount + siblingCount * 2 + (isShowEllipsis ? 2 : 1),
      ),
      // конец относительно начала секции конечных страниц
      endPages.length > 0
        ? (endPages[0] ?? 2) - (isShowEllipsis ? 2 : 1)
        : totalPages - 1,
    );

    // левые разделители
    const startEllipsisList = !isShowEllipsis
      ? []
      : siblingsStart > boundaryCount + 2
        ? (['start-ellipsis'] as const)
        : boundaryCount + 1 < totalPages - boundaryCount
          ? [boundaryCount + 1]
          : [];

    // правые разделители
    const endEllipsisList = !isShowEllipsis
      ? []
      : siblingsEnd < totalPages - boundaryCount - 1
        ? (['end-ellipsis'] as const)
        : totalPages - boundaryCount > boundaryCount
          ? [totalPages - boundaryCount]
          : [];

    // результирующий список элементов пагинации
    const itemList: (number | 'start-ellipsis' | 'end-ellipsis')[] = [
      ...startPages,
      ...startEllipsisList,
      ...range(siblingsStart, siblingsEnd),
      ...endEllipsisList,
      ...endPages,
    ];

    return { pagesList: itemList, totalPages };
  }, [currentPage, isShowEllipsis, siblingCount, totalPages]);
};

const range = (from: number, to: number): number[] =>
  Array.from({ length: to - from + 1 }, (value, index) => from + index);

export const Pagination: React.FC<PaginationItemsProps> = React.memo(
  (props) => {
    const [isMobileXs, isMobile, isDesktop] = useMediaQuery([
      `(max-width: ${breakpoints.xssm})`,
      `(min-width: ${breakpoints.xssm}) and (max-width: ${breakpoints.md})`,
      `(min-width: ${breakpoints.md})`,
    ]);
    const { totalRecords = 0 } = props;
    if (!totalRecords) return null;

    if (isMobileXs) {
      return <MobileXsComp {...props} />;
    }
    if (isMobile) {
      return <MobileComp {...props} />;
    }

    return <DesktopComp {...props} />;
  },
);
Pagination.displayName = 'Pagination';

const DesktopComp: React.FC<PaginationItemsProps> = (props) => {
  const { currentPage, onPageChanged } = props;
  const { pagesList, totalPages } = usePages(
    props.desktopSiblingsCount ?? DEFAULT_DESKTOP_SIBLINGS_COUNT,
    props,
  );

  return (
    <>
      <Flex
        boxShadow="pager"
        alignItems="center"
        boxSize="border-box"
        maxWidth={12}
        overflow="hidden"
        userSelect="none"
        mr={{ base: 1, sm: 2 }}
      ></Flex>
      <PageItemsContainer aria-label="pagination">
        <HStack
          divider={<VerticalDivider />}
          as="ul"
          className="pagination"
          data-testid="pagination-container"
          display="flex"
          alignItems="center"
          overflow="hidden"
          spacing={0}
          flexGrow={1}
          justifyContent="center"
        >
          <PaginationButton
            aria-label="Prev Page"
            onClick={() => onPageChanged(currentPage - 1)}
            isDisabled={currentPage === 1}
            icon={<ArrowLeftIcon />}
            data-testid="prev-page"
          />
          {pagesList.map((page) => {
            if (page === 'start-ellipsis' || page === 'end-ellipsis') {
              return <PageSeparator key={page} data-testid={page} />;
            } else {
              return (
                <Page
                  key={`items-${page}`}
                  isActive={currentPage === page}
                  onClick={() => onPageChanged(page)}
                  testId="page"
                >
                  {page}
                </Page>
              );
            }
          })}
          <PaginationButton
            aria-label="Next Page"
            onClick={() => onPageChanged(currentPage + 1)}
            isDisabled={currentPage === totalPages}
            icon={<ArrowRightIcon />}
            data-testid="next-page"
          />
        </HStack>
      </PageItemsContainer>
    </>
  );
};

const MobileComp: React.FC<PaginationItemsProps> = (props) => {
  const { currentPage, onPageChanged } = props;
  const { pagesList, totalPages } = usePages(
    props.mobileSiblingsCount ?? DEFAULT_MOBILE_SIBLINGS_COUNT,
    props,
    false,
  );

  return (
    <PageItemsContainer aria-label="pagination">
      <HStack
        divider={<VerticalDivider />}
        as="ul"
        className="pagination"
        data-testid="pagination-container"
        display="flex"
        alignItems="center"
        borderRadius={1}
        overflow="hidden"
        spacing={0}
      >
        <PaginationButton
          aria-label="Prev Page"
          onClick={() => onPageChanged(currentPage - 1)}
          isDisabled={currentPage === 1}
          icon={<ArrowLeftIcon />}
          data-testid="prev-page"
        />
        {pagesList.map((page) => {
          if (page === 'start-ellipsis' || page === 'end-ellipsis') {
            return null;
          }
          return (
            <Page
              key={`items-${page}`}
              isActive={currentPage === page}
              onClick={() => onPageChanged(page)}
              testId="page"
            >
              {page}
            </Page>
          );
        })}
        <PaginationButton
          aria-label="Next Page"
          onClick={() => onPageChanged(currentPage + 1)}
          isDisabled={currentPage === totalPages}
          icon={<ArrowRightIcon />}
          data-testid="next-page"
        />
      </HStack>
    </PageItemsContainer>
  );
};

const MobileXsComp: React.FC<PaginationItemsProps> = ({
  totalRecords,
  pageSize,
  onPageChanged,
  currentPage = 1,
}) => {
  const totalPages = Math.ceil(totalRecords / pageSize);

  // Generate the pages list with static first/last and exactly two middle pages
  const pagesList = useMemo(() => {
    if (totalPages <= 4) {
      return Array.from({ length: totalPages }, (_, i) => i + 1); // Show all pages if <= 4
    }

    const firstPage = 1;
    const lastPage = totalPages;

    let middlePages: number[] = [];

    // Handle cases when at boundaries
    if (currentPage <= 2) {
      middlePages = [2];
    } else if (currentPage >= totalPages - 1) {
      middlePages = [totalPages - 1];
    } else {
      // Normal case: Middle buttons shift around the currentPage
      middlePages = [currentPage];
    }

    return [firstPage, ...middlePages, lastPage];
  }, [currentPage, totalPages]);

  return (
    <HStack
      as="ul"
      className="pagination"
      data-testid="pagination-container"
      display="flex"
      alignItems="center"
      borderRadius={1}
      overflow="hidden"
      spacing={0}
      flexGrow={1}
      justifyContent="center"
    >
      <PaginationButton
        aria-label="Prev Page"
        onClick={() => onPageChanged(currentPage - 1)}
        isDisabled={currentPage === 1}
        icon={<ArrowLeftIcon />}
        data-testid="prev-page"
      />

      {pagesList.map((page) => (
        <Page
          key={`items-${page}`}
          isActive={currentPage === page}
          onClick={() => onPageChanged(page)}
          testId="page"
        >
          {page}
        </Page>
      ))}

      <PaginationButton
        aria-label="Next Page"
        onClick={() => onPageChanged(currentPage + 1)}
        isDisabled={currentPage === totalPages}
        icon={<ArrowRightIcon />}
        data-testid="next-page"
      />
    </HStack>
  );
};

const Page: FCC<{
  isActive?: boolean;
  onClick?: VoidFunction;
  testId?: string;
}> = ({ isActive, testId, ...rest }) =>
  isActive ? (
    <ActivePageItem {...rest} data-testid={`active-${testId}`} />
  ) : (
    <PageItem {...rest} data-testid={testId} />
  );

const PageSeparator: FCC<{ 'data-testid': string }> = (props) => (
  <Flex
    as="li"
    width="48px"
    height="48px"
    alignItems="center"
    justifyContent="center"
    {...props}
  >
    ...
  </Flex>
);

const PageItem: FCC<TextProps> = ({ children, ...rest }) => (
  <Text
    as="li"
    listStyleType="none"
    width="48px"
    height="48px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    textStyle={TextStyles.BodyUI16Medium}
    cursor="pointer"
    color="primary.400"
    _hover={{
      bg: 'primary.100',
      borderRadius: '12px',
    }}
    _focus={{
      border: 'none',
      outline: 'none',
      bg: 'brands.100',
      boxShadow: 'pagerItem',
    }}
    {...rest}
  >
    {children}
  </Text>
);
const ActivePageItem: FCC<TextProps> = ({ children, ...rest }) => (
  <PageItem
    borderRadius={1.5}
    bg="brands.500"
    color="white.500"
    cursor="default"
    _hover={{ bg: 'brands.500', borderRadius: '12px' }}
    {...rest}
  >
    {children}
  </PageItem>
);
const PaginationButton: React.FC<IconButtonProps> = (props) => (
  <IconButton
    borderRadius={1.5}
    outline="none"
    width="48px"
    height="48px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    border="none"
    boxShadow="none"
    padding="0"
    color="primary.400"
    variant="secondary"
    bg="transparent"
    {...props}
  />
);
const PageItemsContainer: FCC = ({ children }) => (
  <Flex
    boxSizing="border-box"
    height={6}
    alignItems="center"
    padding="16px 0"
    userSelect="none"
  >
    {children}
  </Flex>
);
