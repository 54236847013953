import { TLegalEntityDto } from '@payler/api/client-office';
import { MayBe } from '@payler/bank-utils';

export function getLegalEntityName(
  legalEntity: MayBe<TLegalEntityDto>,
): string {
  if (!legalEntity) return '';

  switch (legalEntity.type) {
    case 'individual':
      return `${legalEntity.firstName} ${legalEntity.lastName}` || '';
    case 'business':
      return legalEntity.companyName || '';
    default:
      return '';
  }
}

export function getIndividualFirstName(
  legalEntity: TLegalEntityDto | undefined,
): string {
  return legalEntity?.type === 'individual' ? legalEntity.firstName || '' : '';
}

export function getIndividualLastName(
  legalEntity: TLegalEntityDto | undefined,
): string {
  return legalEntity?.type === 'individual' ? legalEntity.lastName || '' : '';
}
