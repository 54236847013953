export const recipients = {
  addRecipient: 'Add recipient',
  type: 'Type',
  business: 'Business',
  individual: 'Individual',
  internal: 'Internal',
  blockchain: 'Blockchain',
  infoDrawer: {
    transferMoney: 'Transfer money',
    address: 'Registration Address',
    currency: 'Currency',
    bankCountry: 'Bank Country',
    bankName: 'Bank Name',
    iban: 'IBAN',
    accountNumber: 'Account Number',
    accountNumberOrIban: 'Account Number / IBAN',
    ukAccountNumber: 'UK Account number',
    ukSortCode: 'UK Sort code',
    bic: 'BIC',
    info: 'Info',
    noAccountsInfo:
      'Sending to the recipient is not possible as you do not have accounts in this currency',
    noAvailableAccountsInfo:
      'Sending to the recipient is not possible as you do not have any available accounts in this currency',
  },
  addModal: {
    recipientInfo: 'Recipient info',
    accountInfo: 'Account info',
    description: "Please enter the recipient's details below",
    accountCurrency: 'Account currency',
    bankCountry: 'Bank country',
    bankName: 'Bank name',
    accountIdentifier: 'Account Number / IBAN',
    bic: 'BIC',
    bicNumber: 'BIC Number',
    accHolderName: 'Account Holder Name',
    legalName: 'Legal name',
    firstName: 'First name',
    lastName: 'Last name',
    registrationAddress: 'Registration Address',
    allFieldsRequired: 'All fields are required',
    selectCountry: 'Select country',
    zipPostalCode: 'ZIP/Postal Code',
    cityTown: 'City/Town',
    street: 'Street',
    successDescription: 'Successfully added to Recipients',
    accountInfoMessage: 'The recipient must be a {{companyName}} account',
    type: 'Type',
    ukAccountNumber: 'UK Account Number',
    ukSortCode: 'UK Sort Code',
    network: 'Network',
    walletAddress: 'Wallet Address',
    memo: 'Memo',
    name: 'Name'
  },
  name: 'Name',
  country: 'Country',
  bic: 'BIC',
  bankInfo: 'Bank info',
  paymentType: {
    swift: 'SWIFT',
    fps: 'Faster Payments',
  },
} as const;
