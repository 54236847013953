import { Box, Button, chakra, Icon, VStack } from '@chakra-ui/react';
import {
  FC,
  HTMLAttributeAnchorTarget,
  Suspense,
  useMemo,
  useState,
} from 'react';
import { BottomMenuLink, MenuLink } from './MenuLink';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../hooks/use-permissions';
import { Logo } from '@payler/ui-components';
import { useClientOfficeConfig } from '../../config/ConfigProvider';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { BottomMenuButton } from './MenuButton';
import {
  timeSharp,
  timerOutline,
  arrowUp,
  albums,
  settingsSharp,
  helpCircle,
  logOut as logOutIcon,
} from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { useShowTransferModal } from '../../modals/TransferModal/TransferModal';
import { useAuth } from '@payler/auth';
import { useSupportLink } from '../../hooks/use-support-link';

export type TMenuLinkItem = {
  route: string;
  href?: string;
  title: string;
  icon: string;
  dataTestId: string;
  badge?: string;
  isDisabled?: boolean;
  target?: HTMLAttributeAnchorTarget;
};

export type TMenuBottomLinkItem = {
  route?: string;
  href?: string;
  title: string;
  icon: string;
  dataTestId: string;
  onClick?: () => void;
};

const BaseMenu: FCC<{ isCollapsed: boolean; toggleCollapse: () => void }> = ({
  children,
  isCollapsed,
  toggleCollapse,
}) => {
  const { staticPath, logoFileExtension } = useClientOfficeConfig();
  const CollapseIcon = isCollapsed ? ChevronRightIcon : ChevronLeftIcon;

  return (
    <Suspense fallback={null}>
      <VStack
        minH={{ base: '100%', sm: '100vh' }}
        h={{ base: '100%', sm: '100vh' }}
        maxH={{ base: '100%', sm: '100vh' }}
        maxW={isCollapsed ? '100px' : '300px'}
        transition="max-width 0.3s ease"
        overflow="hidden"
        as="nav"
        alignItems="stretch"
        spacing={0}
        px={3}
        pb={4}
        bg="bg"
      >
        <Box
          pt={{ base: 0, sm: '32px' }}
          pb={{ base: 0, sm: '0' }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {!isCollapsed && (
            <Logo
              staticPath={staticPath}
              logoFileExtension={logoFileExtension}
            />
          )}
          <Button
            variant="unstyled"
            border="none"
            my={{ base: 2 }}
            onClick={toggleCollapse}
            mx={isCollapsed ? 'auto' : ''}
          >
            <CollapseIcon display={{ base: 'none', md: 'block' }} />
          </Button>
        </Box>
        {children}
      </VStack>
    </Suspense>
  );
};

export const Menu: FCC<{
  links: TMenuLinkItem[];
}> = ({ links }) => {
  const { t } = useTranslation(['menu']);
  const { isGlobalAccounts } = usePermissions();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleCollapse = () => setIsCollapsed((prev) => !prev);
  const openTransferModal = useShowTransferModal();
  const { logout } = useAuth();
  const supportLink = useSupportLink();

  // TODO: не получилось вынести bottomLinks на уровень приложения, т.к. получение профиля падает в неаавторизованной зоне (onboarding)
  // вернуться, когда появится необходимость задавать их динамически
  const bottomLinks = useMemo(() => {
    return [
      {
        icon: settingsSharp,
        route: '/profile',
        title: t('accountSettings'),
        dataTestId: 'menu_accountSettings',
      },
      {
        icon: helpCircle,
        href: supportLink,
        title: t('support'),
        dataTestId: 'menu_accountSettings',
      },
      {
        icon: logOutIcon,
        title: t('logout'),
        dataTestId: 'button_logout',
        onClick: logout,
      },
    ];
  }, []);

  return (
    <BaseMenu isCollapsed={isCollapsed} toggleCollapse={toggleCollapse}>
      <Button
        borderRadius={4}
        variant={isCollapsed ? 'secondary' : 'primary'}
        data-testid="button_open-send-money"
        border="none"
        onClick={openTransferModal}
      >
        {isCollapsed ? (
          <Icon as={IonIcon} icon={arrowUp} w="24px" h="24px" />
        ) : (
          t('menu:sendMoney')
        )}
      </Button>
      <chakra.div
        flex="1 1 0"
        minH={0}
        userSelect="none"
        overflowY="auto"
        overflowX="hidden"
      >
        {isGlobalAccounts ? (
          <Links isCollapsed={isCollapsed} links={links} />
        ) : (
          <DemoLinks />
        )}
      </chakra.div>
      <BottomLinks isCollapsed={isCollapsed} bottomLinks={bottomLinks} />
    </BaseMenu>
  );
};

export default Menu;

const Links: FC<{ isCollapsed: boolean; links: TMenuLinkItem[] }> = ({
  isCollapsed,
  links,
}) => {
  return (
    <VStack pt="32px">
      {links.map((link) => {
        return (
          <MenuLink key={link.route} {...link} isCollapsed={isCollapsed} />
        );
      })}
    </VStack>
  );
};

// TODO:
const DemoLinks = () => {
  const { t } = useTranslation(['menu']);

  return (
    <VStack
      alignItems="stretch"
      p={0.5}
      borderRadius="md"
      bg="secondary.500"
      border="1px solid"
      borderColor="primary.100"
    >
      <MenuLink
        icon={timerOutline}
        route="/"
        title={t('menu:onboardingProcess')}
        data-testid="menu_onboarding-process"
      />
      <MenuLink
        icon={albums}
        route="/accounts"
        title={t('menu:accounts')}
        badge={t('menu:demo')}
        data-testid="menu_accounts"
      />
      <MenuLink
        icon={timeSharp}
        route="/activity"
        title={t('menu:activity')}
        badge={t('menu:demo')}
        isDisabled
        data-testid="menu_activity"
      />
    </VStack>
  );
};

export const BottomLinks: FC<{
  isCollapsed: boolean;
  bottomLinks: TMenuBottomLinkItem[];
}> = ({ isCollapsed, bottomLinks }) => {
  return (
    <VStack>
      {bottomLinks.map((link, index) => {
        const { route, title } = link;
        return route ? (
          <BottomMenuLink
            {...link}
            route={route}
            title={title}
            isCollapsed={isCollapsed}
            key={index}
          />
        ) : (
          <BottomMenuButton {...link}>{!isCollapsed && title}</BottomMenuButton>
        );
      })}
    </VStack>
  );
};
