import { useQuery } from '@tanstack/react-query';
import { useApi } from '../state/api';
import { useMemo } from 'react';

const STALE_TIME = 300_000;

/**
 * Получение валют пользователя
 */
export const useGetClientCurrenciesQuery = () => {
  const api = useApi();

  return useQuery({
    queryKey: ['currencies'],
    queryFn: () => api.getClientCurrencies(),
    staleTime: STALE_TIME,
  });
};

export const useGetClientCurrencies = () => {
  const { data = [], isLoading } = useGetClientCurrenciesQuery();

  const currencies = useMemo(
    () =>
      data.map(({ code, name, iconUri }) => ({
        currencyCode: code.toUpperCase(),
        id: code.toLowerCase(),
        name,
        iconUri,
      })),
    [data],
  );

  return { currencies, isLoading };
};

export const useGetClientCurrenciesOptions = () => {
  const { data = [] } = useGetClientCurrenciesQuery();

  return useMemo(
    () =>
      data.map(({ name, code }) => ({
        label: name,
        value: code.toLowerCase(),
      })),
    [data],
  );
};
