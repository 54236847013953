import { Box } from '@chakra-ui/react';
import Menu, { TMenuLinkItem } from '../Menu/Menu';
import MobileMenu from '../Menu/MobileMenu';
import { useBankBreakpointValue } from '../../hooks/use-bank-breakpoint-value';
import { MENU_WIDTH } from '../../layouts/BaseLayout/const';
import { FC } from 'react';

export const SideNavMenu: FC<{
  links: TMenuLinkItem[];
}> = ({ links }) => {
  const isDesktop = useBankBreakpointValue({ base: false, md: true });
  return isDesktop ? (
    <DesktopMenu links={links} />
  ) : (
    <MobileMenu links={links} />
  );
};

const DesktopMenu: FC<{
  links: TMenuLinkItem[];
}> = ({ links }) => {
  return (
    <Box
      gridArea="nav"
      position="fixed"
      top={0}
      left={0}
      height="100vh"
      width={`${MENU_WIDTH + 48}px`}
      bg="bg"
    >
      <Menu links={links} />
    </Box>
  );
};
