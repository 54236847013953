export const ParticipantField = {
  iban: 'iban',
  bic: 'bic',
  ibanOrAccountNumber: 'ibanOrAccountNumber',
  bicOrBankCode: 'bicOrBankCode',
  bankName: 'bankName',
  bankCountry: 'bankCountry',
  accountNumber: 'accountNumber',
  sortCode: 'sortCode',
  network: 'network',
  walletId: 'walletId',
  memo: 'memo',
  bankCode: 'bankCode',
  firstName: 'firstName',
  lastName: 'lastName',
  companyName: 'companyName',
  country: 'country',
  postalCode: 'postalCode',
  city: 'city',
  street: 'street',
} as const;
