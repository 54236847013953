import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';

const WL_THEME_FILENAME = 'custom-theme.json';

export function useCustomTheme(staticPath: string) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(async () => {
    const path = `${staticPath}/${WL_THEME_FILENAME}`;
    try {
      const response = await axios.get(path);

      setData(response.data);
    } catch (e) {
      throw e;
    } finally {
      setIsLoading(false);
    }
  }, [staticPath]);

  useEffect(() => {
    getData();
  }, [getData]);

  return useMemo(
    () => ({
      data,
      isLoading,
    }),
    [data, isLoading],
  );
}
