import React from 'react';
import { Icon, IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';

export const CloseButton: React.FC<{ onClick: VoidFunction }> = ({
  onClick,
}) => {
  const { t } = useTranslation(['accounts']);
  return (
    <IconButton
      icon={<Icon as={IonIcon} icon={close} w="24px" h="24px" />}
      aria-label={t('accounts:accountsDrawer.ariaLabels.closeDrawer')}
      variant="secondary"
      w={6}
      h={6}
      onClick={onClick}
      data-testid="button_close"
      border="none"
    />
  );
};
