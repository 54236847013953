import { TRANSFER_TABLE_TYPE_PARAM, TTransferTableType } from '../const';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

export const useSetURLTransferTableType = () => {
  const [params, setParams] = useSearchParams();
  return useCallback(
    (type: TTransferTableType) => {
      const newParams = new URLSearchParams(params);

      newParams.delete("Page");

      if (type === 'All types') {
        newParams.delete(TRANSFER_TABLE_TYPE_PARAM);
      } else {
        newParams.set(TRANSFER_TABLE_TYPE_PARAM, type);
      }

      setParams(newParams);
    },
    [params, setParams],
  );
};

export const useTransferTableType = (): TTransferTableType => {
  const [params] = useSearchParams();
  return useMemo(
    () =>
      (params.get(TRANSFER_TABLE_TYPE_PARAM) as TTransferTableType) ||
      'All types',
    [params],
  );
};
