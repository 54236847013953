import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Button, ButtonProps, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { TextStyles } from '@payler/ui-theme';
import {
  ApiErrorText,
  createDateFieldResolver,
  DatesFields,
  useAvailableRangeBoundaries,
} from '@payler/ui-components';
import {
  useCloseAccountStatementModal,
  useIsOpenAccountStatementModal,
} from '../../modals/AccountStatement';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import {
  BankModalBody,
  BankModalFooter,
} from '../../components/BankModal/BankModal';
import { useHandleFormError } from '@payler/bank-utils';
import { useReportsStatementsQuery } from '../../hooks/reports/queries';

const DAYS_RANGE = 365;

type TReportsStatementsForm = {
  dateFrom: string;
  dateTo: string;
};

const useResolver = () => {
  const { t } = useTranslation();
  const { uiDateFormat } = useLanguageFeatures();

  return yupResolver(
    yup.object().shape({
      dateFrom: createDateFieldResolver(t, {
        toFieldName: 'dateTo',
        dateFormat: uiDateFormat,
        canBeEmpty: false,
        availableRange: DAYS_RANGE,
      }),
      dateTo: createDateFieldResolver(t, {
        fromFieldName: 'dateFrom',
        dateFormat: uiDateFormat,
        canBeEmpty: false,
        availableRange: DAYS_RANGE,
      }),
    }),
  );
};

const errorsToFields = {
  startDate: 'dateFrom',
  endDate: 'dateTo',
} as const;

export const AccountStatementForm: React.FC = () => {
  const { t } = useTranslation(['accounts']);
  const { payload: accountId } = useIsOpenAccountStatementModal();
  const closeModal = useCloseAccountStatementModal();
  const { uiDateFormat, uiDateToServerDate } = useLanguageFeatures();
  const resolver = useResolver();
  const methods = useForm<TReportsStatementsForm>({
    resolver,
    mode: 'onChange',
  });
  const {
    watch,
    trigger,
    formState: { errors },
  } = methods;
  const dateFrom = watch('dateFrom');
  const dateTo = watch('dateTo');

  const { handleReportsStatementsQuery, loading } = useReportsStatementsQuery();
  const handleFormError = useHandleFormError<TReportsStatementsForm>();
  const [errorText, setErrorText] = useState('');

  const onSubmitHandle = methods.handleSubmit(
    async ({ dateFrom, dateTo }: TReportsStatementsForm) => {
      try {
        if (accountId) {
          await handleReportsStatementsQuery({
            accountId,
            startDate: uiDateToServerDate(dateFrom) ?? '',
            endDate: uiDateToServerDate(dateTo) ?? '',
          });
          setErrorText('');
          closeModal();
        }
      } catch (e) {
        const unknownFieldErrors = handleFormError(e, methods, errorsToFields);
        if (unknownFieldErrors.length) {
          setErrorText(unknownFieldErrors.join(' '));
        }
      }
    },
  );

  const { minDate, maxDate } = useAvailableRangeBoundaries({
    dateFrom,
    dateTo,
    daysRange: DAYS_RANGE,
  });

  useEffect(() => {
    if (!errors.dateFrom?.message && !!dateTo) trigger('dateTo');
  }, [dateFrom]);

  useEffect(() => {
    if (!errors.dateTo?.message && !!dateFrom) trigger('dateFrom');
  }, [dateTo]);

  return (
    <FormProvider {...methods}>
      <BankModalBody>
        <VStack
          id="accountStatementForm"
          as="form"
          onSubmit={onSubmitHandle}
          spacing={2}
          height="100%"
          flex="1"
          alignItems="flex-start"
        >
          <Text textStyle={TextStyles.Subtitle14Regular}>
            {t('accounts:accountStatement.description')}
          </Text>
          <VStack
            width="100%"
            spacing={2}
            height="100%"
            justifyContent="space-between"
            flex="1"
          >
            <VStack display="block" w="100%" spacing={2}>
              <DatesFields
                fieldNames={[
                  t('accounts:accountStatement.fieldNames.dateFrom'),
                  t('accounts:accountStatement.fieldNames.dateTo'),
                ]}
                stackProps={{ direction: 'row', width: '100%' }}
                labelsKeys={[
                  t('accounts:accountStatement.labels.dateFrom'),
                  t('accounts:accountStatement.labels.dateTo'),
                ]}
                dateFormat={uiDateFormat}
                minDate={minDate}
                maxDate={maxDate}
                floating
              />
            </VStack>
            {errorText && <ApiErrorText>{errorText}</ApiErrorText>}
          </VStack>
        </VStack>
      </BankModalBody>
      <BankModalFooter>
        <SubmitButton form="accountStatementForm" isLoading={loading} />
      </BankModalFooter>
    </FormProvider>
  );
};

const SubmitButton: React.FC<{ isLoading: boolean } | ButtonProps> = ({
  isLoading,
  ...props
}) => {
  const { t } = useTranslation(['common']);
  const {
    formState: { isValid },
  } = useFormContext();

  return (
    <Button
      isLoading={isLoading}
      type="submit"
      variant="primary"
      w="100%"
      isDisabled={!isValid}
      {...props}
    >
      {t('common:create')}
    </Button>
  );
};
