import {
  Button,
  ButtonProps,
  HStack,
  Icon,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { BankModalFooter } from '../../../components/BankModal/BankModal';
import { IonIcon } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import {
  TTransferDetailsForm,
  useTransferWizardContext,
} from '../TransferWizard';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectAccountsStep } from './SelectAccountsStep';
import { useCloseTransferModal } from '../TransferModal';
import { TransferDetailsStep } from './TransferDetailsStep';
import { TCreateOutgoingTransferDto } from '@payler/api/client-office';
import { useAddParticipantMutation } from '../../../hooks/participants/queries';
import { useToasts } from '@payler/bank-utils';

export const SelfTransfer = () => {
  const { t } = useTranslation(['accounts', 'common']);
  const { senderAccount, receiverAccount, setIsSelfTransfer } =
    useTransferWizardContext();

  const { step, setStep, setTransfer, setTransferDetails } =
    useTransferWizardContext();

  const toasts = useToasts();
  const { mutate: addParticipant, isPending } = useAddParticipantMutation();
  const methods = useFormContext<TTransferDetailsForm>();
  const { setValue } = methods;

  // TODO: проверить нужно ли получать closeModal из пропсов. Если нет необходимости выпилить closeModal из TransferWizard
  const closeModal = useCloseTransferModal();

  const handleBack = () => {
    if (step === 'selfSelectAccounts') {
      setIsSelfTransfer(false);
      setStep('select');
    }
    if (step === 'selfTransferDetails') {
      setValue('amount', undefined);
      setStep('selfSelectAccounts');
    }
  };

  const handleContinue = () => {
    setStep('selfTransferDetails');
  };

  const handleSubmit = methods.handleSubmit((values) => {
    if (!senderAccount) {
      toasts.error(t('accounts:transferMoney.accountNotFound'));
      return;
    }

    if (!receiverAccount) {
      toasts.error(t('accounts:transferMoney.recipientAccountNotFound'));
      return;
    }

    addParticipant(
      {
        participant: {
          credentials: {
            paymentMethod: 'internal',
            ibanOrAccountNumber: receiverAccount.number,
          },
        },
        accountId: senderAccount.id,
      },
      {
        onSuccess: (participantId: string) => {
          const transfer: TCreateOutgoingTransferDto = {
            senderAccountId: senderAccount.id,
            sendingAmount: values?.amount ? Number(values.amount) : 0,
            system: 'self',
            recipientParticipantId: participantId,
          };
          setTransfer(transfer);
          setTransferDetails(values);
          setStep('info');
        },
      },
    );
  });
  const buttonProps: ButtonProps =
    step === 'selfSelectAccounts'
      ? {
          onClick: handleContinue,
          isDisabled: !senderAccount || !receiverAccount,
        }
      : {
          form: 'selfTransferForm',
          onClick: handleSubmit,
          isLoading: isPending,
        };

  return (
    <VStack as="form" id="selfTransferForm" alignItems="start">
      {step === 'selfSelectAccounts' && <SelectAccountsStep />}
      {step === 'selfTransferDetails' && <TransferDetailsStep />}
      <BankModalFooter w="100%">
        <HStack w="100%" spacing={1}>
          <IconButton
            variant="secondary"
            aria-label="back-step"
            w="56px"
            minW="56px"
            h="56px"
            border="none"
            icon={<Icon as={IonIcon} icon={chevronBack} w="24px" h="24px" />}
            onClick={handleBack}
          />
          <Button
            variant="secondary"
            w="100%"
            data-testid="button_cancel"
            border="none"
            onClick={closeModal}
          >
            {t('common:cancel')}
          </Button>
          <Button
            variant="primary"
            w="100%"
            data-testid="button_continue"
            {...buttonProps}
          >
            {t('accounts:transferMoney.continue')}
          </Button>
        </HStack>
      </BankModalFooter>
    </VStack>
  );
};
