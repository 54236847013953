import { GeneralColors } from '../types';

export const generalColors: GeneralColors = {
  brands: {
    500: '#26695B',
    300: 'rgba(38, 105, 91, 0.32)',
    100: 'rgba(38, 105, 91, 0.1)',
    hover: '#1A6152',
  },
  red: {
    500: '#DF2935',
    300: 'rgba(223, 41, 53, 0.32)',
    100: 'rgba(223, 41, 53, 0.1)',
  },
  yellow: {
    500: '#FFCB47',
    300: 'rgba(rgba(255, 203, 71, 0.32)',
    100: 'rgba(rgba(255, 203, 71, 0.1)',
  },
  green: {
    500: '#6EB257',
    300: 'rgba(110, 178, 87, 0.32)',
    100: 'rgba(110, 178, 87, 0.1)',
  },
  violet: {
    500: '#7B61FF',
    300: 'rgba(123, 97, 255, 0.32)',
    100: 'rgba(123, 97, 255, 0.1)',
  },
  blue: {
    500: '#1B3B6F',
    300: 'rgba(27, 59, 111, 0.32)',
    100: 'rgba(27, 59, 111, 0.1)',
  },
  white: {
    500: '#FFFFFF',
    300: 'rgba(255, 255, 255, 0.32)',
    100: 'rgba(255, 255, 255, 0.1)',
  },
  black: {
    500: '#000000',
    400: 'rgba(8, 8, 8, 0.68)',
    300: 'rgba(0, 0, 0, 0.32)',
    100: 'rgba(0, 0, 0, 0.1)',
  },
  orange: {
    500: '#DD6E42',
    300: 'rgba(221, 110, 66, 0.32)',
    100: 'rgba(221, 110, 66, 0.1)',
  },
  pink: {
    500: '#FE3293',
    300: 'rgba(254, 50, 147, 0.32)',
    100: 'rgba(254, 50, 147, 0.1)',
  },
  hover: '#1A6152',
};
