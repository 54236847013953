import React, { ReactNode } from 'react';
import { Button, ButtonProps, Flex } from '@chakra-ui/react';
import {
  DangerBigIcon,
  ErrorBigIcon,
  QuestionBigIcon,
  WarningBigIcon,
} from '@payler/ui-icons';
import { ReactComponent } from '*.svg';
import { useTranslation } from 'react-i18next';
import { NotificationWithIcon } from '@payler/ui-components';
import { BankModalBody, BankModalFooter } from '../BankModal/BankModal';
import { SuccessBigIcon } from '../../icons';

export type TStatusType =
  | 'success'
  | 'warning'
  | 'error'
  | 'danger'
  | 'question'
  | 'custom';

export interface IEventStatus {
  /** Тип иконки, если не передан icon */
  statusType: TStatusType;
  title: ReactNode;
  description?: ReactNode;
  /** Если не передано, то по умолчанию будет "Close" */
  closeButtonTitle?: string;
  onCloseButtonClick: VoidFunction;
  closeButtonProps?: ButtonProps;
  /** Если не передано, то по умолчанию будет "Continue" */
  actionButtonTitle?: string;
  /** Если не передано, то кнопка отображаться не будет */
  onActionButtonClick?: VoidFunction;
  actionButtonProps?: ButtonProps;
  icon?: typeof ReactComponent;
}

export const EventStatus: React.FC<IEventStatus> = ({
  statusType,
  title,
  description,
  closeButtonTitle,
  onCloseButtonClick,
  actionButtonTitle,
  onActionButtonClick,
  icon,
  closeButtonProps,
  actionButtonProps,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <BankModalBody as={Flex} flexDirection="column" justifyContent="center">
        <NotificationWithIcon
          title={title}
          text={description}
          icon={icon || getStatusIcon(statusType)}
        />
      </BankModalBody>
      <BankModalFooter w="100%" mt="0" gap={2}>
        <Button
          variant="secondary"
          w="inherit"
          onClick={onCloseButtonClick}
          data-testid="button_close"
          {...closeButtonProps}
        >
          {closeButtonTitle ? closeButtonTitle : t('close')}
        </Button>
        {!!onActionButtonClick && (
          <Button
            variant="primary"
            w="inherit"
            onClick={onActionButtonClick}
            data-testid="button_action"
            {...actionButtonProps}
          >
            {actionButtonTitle ? actionButtonTitle : t('continue')}
          </Button>
        )}
      </BankModalFooter>
    </>
  );
};

const getStatusIcon = (
  status: TStatusType,
): typeof ReactComponent | undefined => {
  switch (status) {
    case 'error':
      return ErrorBigIcon;
    case 'warning':
      return WarningBigIcon;
    case 'success':
      return SuccessBigIcon;
    case 'danger':
      return DangerBigIcon;
    case 'question':
      return QuestionBigIcon;
    case 'custom':
      return;
  }
};
