import { forwardRef } from 'react';
import { HStack, StackProps, useToken } from '@chakra-ui/react';

type Props = { borderColor: string } & Omit<StackProps, 'borderColor'>;

export const TriggerWrapper = forwardRef<HTMLDivElement, Props>(
  ({ borderColor, ...props }, ref) => {
    const shadowColor = useToken('colors', borderColor);
    return (
      <HStack
        ref={ref}
        boxSizing="border-box"
        tabIndex={0}
        spacing={1}
        minH={9}
        px={2}
        borderRadius="md"
        borderWidth={0}
        boxShadow={`inset 0 0 0px 1px ${shadowColor}`}
        transitionDuration="fast"
        transitionProperty="background, color, box-shadow"
        position="relative"
        __css={{
          _focus: {
            outline: 'none',
          },
        }}
        {...props}
      />
    );
  },
);
