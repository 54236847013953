import { linkStyle } from './MenuLink';
import { ClassNames } from '@emotion/react';
import { Button, Icon, Text } from '@chakra-ui/react';
import { IonIcon } from '@ionic/react';

const buttonStyle = {
  ...linkStyle,
  justifyContent: 'start',
};

export const BottomMenuButton: FCC<{
  onClick?: () => void;
  href?: string;
  icon: string;
}> = ({ children, onClick, href, icon, ...rest }) => {
  return (
    <ClassNames>
      {({ css }) => {
        const normal = css(buttonStyle);

        return href ? (
          <Button
            as="a"
            variant="unstyled"
            className={normal}
            href={href}
            {...rest}
          >
            <Icon as={IonIcon} mr={2} w="24px" h="24px" icon={icon} />
            <Text>{children}</Text>
          </Button>
        ) : (
          <Button
            variant="unstyled"
            className={normal}
            onClick={onClick}
            {...rest}
          >
            <Icon as={IonIcon} mr={2} w="24px" h="24px" icon={icon} />
            <Text>{children}</Text>
          </Button>
        );
      }}
    </ClassNames>
  );
};
