import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { usePermissions } from '../../../hooks/use-permissions';
import {
  AMOUNT_FOR_MANDATORY_DESCRIPTION,
  AMOUNT_FOR_MANDATORY_DOCUMENT,
  TTransferDetailsForm,
  useTransferWizardContext,
} from '../TransferWizard';
import { useTransferFeeQuery } from '../../../hooks/transfers/queries';
import { CompositionEvent, useEffect } from 'react';
import { isEmpty } from 'lodash';
import {
  TCreateOutgoingTransferDto,
  EFeeAllocationEnum,
  TTransferFeeParams,
} from '@payler/api/client-office';
import {
  BankModalBody,
  BankModalFooter,
} from '../../../components/BankModal/BankModal';
import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { getCurrencyIcon } from '../../../currency';
import { TransferWizardAmountField } from './TransferDetailsWrapper';
import {
  DropzoneField,
  FloatingInputField,
  SegmentedPickerField,
  ThinDivider,
} from '@payler/ui-components';
import { Trans, useTranslation } from 'react-i18next';
import { useLanguageFeatures } from '../../../hooks/use-language-features';
import {
  MIN_DESCRIPTION_LENGTH,
  TCurrency,
  isValidDescriptionCharacter,
  useToasts,
} from '@payler/bank-utils';
import ExchangeDropdownField from '../../../components/ExchangeDropdown/ExchangeDropdownField';
import { ChevronCircledIcon } from '../../../icons';
import { FEE_ALLOCATION_TYPES } from '../../../const';
import { assert } from '@payler/utils';
import { IonIcon } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { useCloseTransferModal } from '../TransferModal';

export const TransferDetailsForm = ({
  handleBack,
}: {
  handleBack: VoidFunction;
}) => {
  const { t } = useTranslation(['accounts', 'transfers', 'common']);
  const { formatAmountByCurrency } = useLanguageFeatures();
  const toasts = useToasts();
  const {
    setStep,
    setTransferDetails,
    senderAccount,
    setTransfersFee,
    setTransfer,
    participant,
    participantId,
  } = useTransferWizardContext();

  const closeModal = useCloseTransferModal();

  const methods = useFormContext<TTransferDetailsForm>();
  const {
    formState: { errors },
  } = methods;

  const { isGlobalAccounts } = usePermissions();

  const amount = methods.watch('amount');
  const files = methods.watch('files');
  const description = methods.watch('description');
  const feeAllocation = methods.watch('feeAllocation');

  const transferSystem = participant?.credentials.paymentMethod;
  const currentPaymentMethod = senderAccount?.baasProvider.paymentMethods.find(
    (method) => method.paymentMethod === transferSystem,
  );
  const isFeeAllocationAvailable =
    currentPaymentMethod?.commissionStrategies &&
    currentPaymentMethod.commissionStrategies.length > 1 &&
    feeAllocation !== undefined;

  const { data: fee, isFetching } = useTransferFeeQuery(
    {
      BaasProvider: senderAccount?.baasProvider.shortName,
      TransferType: 'outgoing',
      Amount: amount,
      System: transferSystem,
      Currency: senderAccount?.currency.toLowerCase(),
      ...(isFeeAllocationAvailable && {
        FeeAllocation: EFeeAllocationEnum[feeAllocation],
      }),
    } as TTransferFeeParams,
    !!amount,
  );

  const handleSubmit = methods.handleSubmit((values) => {
    if (!senderAccount) {
      toasts.error(t('accounts:transferMoney.accountNotFound'));
      return;
    }

    const transfer = {
      senderAccountId: senderAccount.id,
      sendingAmount: values?.amount ? Number(values.amount) : 0,
      description: values?.description ?? '',
      documents: values.files,
      system: transferSystem,
      ...(isFeeAllocationAvailable && {
        feeAllocation: EFeeAllocationEnum[feeAllocation],
      }),
      recipientParticipantId: participantId,
    } as TCreateOutgoingTransferDto;
    setTransfer(transfer);
    setTransferDetails(values);
    setStep('info');
  });

  useEffect(() => {
    setTransfersFee(fee);
  }, [fee, setTransfersFee]);

  const isDocumentRequiredAndEmpty =
    amount && amount >= AMOUNT_FOR_MANDATORY_DOCUMENT && isEmpty(files);

  const isDescriptionRequiredAndEmpty =
    amount &&
    amount >= AMOUNT_FOR_MANDATORY_DESCRIPTION &&
    (!description || description.length < MIN_DESCRIPTION_LENGTH);

  const isButtonDisabled =
    isDocumentRequiredAndEmpty ||
    isDescriptionRequiredAndEmpty ||
    isFetching ||
    !isEmpty(errors);

  assert(senderAccount, 'Cannot find sender account');
  const accountOptions = [
    {
      label: formatAmountByCurrency(
        senderAccount.amount,
        senderAccount.currency,
      ),
      value: senderAccount.id,
      caption: senderAccount.name,
      icon: getCurrencyIcon(senderAccount.currency),
    },
  ];

  return (
    <>
      <BankModalBody>
        <VStack
          id="externalTransferDetails"
          spacing={2}
          height="100%"
          flex="1"
          alignItems="stretch"
          as="form"
          onSubmit={handleSubmit}
        >
          <Text textStyle={TextStyles.Subtitle14Regular}>
            {t('accounts:transferMoney.transferDetailsDescription')}
          </Text>
          <VStack
            width="100%"
            spacing={3}
            height="100%"
            justifyContent="space-between"
            flex="1"
          >
            <VStack width="100%" spacing={2} height="100%" alignItems="stretch">
              <VStack
                gap={0}
                borderRadius="md"
                boxShadow="detailsBlock"
                divider={<ThinDivider m="0 !important" />}
              >
                {senderAccount && (
                  <ExchangeDropdownField
                    fieldName="senderAccountId"
                    options={accountOptions}
                    triggerWrapperProps={{
                      bgColor: 'secondary.500',
                    }}
                    optionLabelProps={{
                      textStyle: TextStyles.Subtitle14Medium,
                    }}
                    dropdownIcon={{
                      component: ChevronCircledIcon,
                      props: { h: '26px', w: '26px', color: 'primary.500' },
                    }}
                    isDisabled
                  />
                )}
              </VStack>
              <TransferWizardAmountField />
              <HStack w="100%" justifyContent="space-between">
                {transferSystem && (
                  <Text
                    textStyle={TextStyles.Caption12Regular}
                    color="primary.400"
                  >
                    {`${t(`transfers:mappedSystems.${transferSystem}` as const)} ${t(
                      'accounts:transferMoney.transfer',
                    )}`}
                  </Text>
                )}
                <Text
                  textStyle={TextStyles.Caption12Regular}
                  color="primary.400"
                >
                  {isFetching
                    ? t('common:loading')
                    : t('accounts:transferMoney.transferFee', {
                        amount: fee?.feeAmount || 0,
                        currency: senderAccount?.currency.toUpperCase(),
                      })}
                </Text>
              </HStack>
              {isFeeAllocationAvailable && <FeeAllocationSwitcher />}
              <Controller
                name="description"
                render={({ field: { onChange, value, name } }) => (
                  <FloatingInputField
                    name={name}
                    label={t('accounts:transferMoney.description')}
                    isReadOnly={!isGlobalAccounts}
                    value={value || ''}
                    onChange={onChange}
                    onBeforeInput={(e: CompositionEvent<HTMLInputElement>) => {
                      if (!isValidDescriptionCharacter(e.data)) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />

              <Dropzone errors={errors} />
              <WarningComponent
                amount={amount}
                currency={senderAccount?.currency}
              />
            </VStack>
          </VStack>
        </VStack>
      </BankModalBody>
      <BankModalFooter>
        <HStack w="100%" spacing={1}>
          {/* TODO: вынести в отдельный компонент */}
          <IconButton
            variant="secondary"
            aria-label="back-step"
            w="56px"
            minW="56px"
            h="56px"
            border="none"
            icon={<Icon as={IonIcon} icon={chevronBack} w="24px" h="24px" />}
            onClick={handleBack}
          />
          <Button
            variant="secondary"
            w="100%"
            data-testid="button_cancel"
            border="none"
            onClick={closeModal}
          >
            {t('common:cancel')}
          </Button>
          <Button
            type="submit"
            form="externalTransferDetails"
            variant="primary"
            w="100%"
            isDisabled={isButtonDisabled}
            data-testid="button_continue"
          >
            {t('accounts:transferMoney.continue')}
          </Button>
        </HStack>
      </BankModalFooter>
    </>
  );
};

const Dropzone = ({
  errors,
}: {
  errors: FieldErrors<TTransferDetailsForm>;
}) => {
  const { t } = useTranslation(['accounts']);

  return (
    <VStack spacing={0} alignItems="stretch">
      <DropzoneField
        name="files"
        title={t('accounts:transferMoney.dropzone.title')}
        description={t('accounts:transferMoney.dropzone.description')}
      />
      {errors.files && (
        <Text
          textStyle={TextStyles.BodyUI14Regular}
          px={1.5}
          mt={1}
          color="red.500"
        >
          {errors.files.message}
        </Text>
      )}
    </VStack>
  );
};

const WarningComponent = ({
  amount,
  currency,
}: {
  amount?: number;
  currency?: TCurrency;
}) => {
  if (!amount || amount < AMOUNT_FOR_MANDATORY_DESCRIPTION || !currency)
    return null;

  return (
    <Box
      textAlign="center"
      px={2}
      py={1}
      borderRadius={1}
      border="1px solid"
      borderColor="yellow.300"
      backgroundColor="yellow.300"
    >
      {amount >= AMOUNT_FOR_MANDATORY_DOCUMENT ? (
        <UnorderedList>
          <ListItem style={{ textAlign: 'start' }}>
            <RequiredDescriptionWarning currency={currency} />
          </ListItem>
          <ListItem style={{ textAlign: 'start' }}>
            <RequiredDocumentWarning currency={currency} />
          </ListItem>
        </UnorderedList>
      ) : (
        <RequiredDescriptionWarning currency={currency} />
      )}
    </Box>
  );
};

const RequiredDocumentWarning = ({ currency }: { currency: TCurrency }) => {
  const { formatAmountByCurrency } = useLanguageFeatures();

  return (
    <Text textStyle={TextStyles.Subtitle14Regular} color="primary.500">
      <Trans
        i18nKey="accounts:transferMoney.documentWarning"
        ns={['accounts']}
        values={{
          amount: formatAmountByCurrency(
            AMOUNT_FOR_MANDATORY_DOCUMENT,
            currency,
            0,
          ),
        }}
        components={{
          bold: <Text as="span" textStyle={TextStyles.Subtitle14Medium} />,
        }}
      />
    </Text>
  );
};

const RequiredDescriptionWarning = ({ currency }: { currency: TCurrency }) => {
  const { formatAmountByCurrency } = useLanguageFeatures();
  return (
    <Text textStyle={TextStyles.Subtitle14Regular} color="primary.500">
      <Trans
        i18nKey="accounts:transferMoney.descriptionWarning"
        ns={['accounts']}
        values={{
          amount: formatAmountByCurrency(
            AMOUNT_FOR_MANDATORY_DESCRIPTION,
            currency,
            0,
          ),
        }}
        components={{
          bold: <Text as="span" textStyle={TextStyles.Subtitle14Medium} />,
        }}
      />
    </Text>
  );
};

const FeeAllocationSwitcher = () => {
  const { t } = useTranslation('transfers');
  const options = FEE_ALLOCATION_TYPES.map((option) => option.toUpperCase());
  const { watch } = useFormContext<TTransferDetailsForm>();
  const feeAllocation = watch('feeAllocation');

  const description =
    feeAllocation === EFeeAllocationEnum.our
      ? t('feeAllocationDescription.our')
      : t('feeAllocationDescription.sha');
  return (
    <VStack spacing={2}>
      <SegmentedPickerField
        name="feeAllocation"
        size="lg"
        optionPaddingX={20}
        options={options}
        isFullWidth
      />
      <Text textStyle={TextStyles.Caption12Regular} color="primary.400">
        {description}
      </Text>
    </VStack>
  );
};
