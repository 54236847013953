import { Button, HStack, Icon, IconButton, VStack } from '@chakra-ui/react';
import {
  BankModalBody,
  BankModalFooter,
} from '../../../components/BankModal/BankModal';
import { TextStyles } from '@payler/ui-theme';
import { DropdownField } from '@payler/ui-components';
import { useTranslation } from 'react-i18next';
import { useAddParticipantWizardContext } from './AddParticipantWizard';
import ExchangeDropdownField from '../../../components/ExchangeDropdown/ExchangeDropdownField';
import { TExchangeDropdownOption } from '../../../components/ExchangeDropdown/ExchangeDropdownOption';
import { useCallback, useMemo } from 'react';
import { useAccountsQuery } from '../../../hooks/accounts/queries';
import { useLanguageFeatures } from '../../../hooks/use-language-features';
import { getCurrencyIcon } from '../../../currency';
import { ChevronCircledIcon } from '../../../icons';
import { useParticipantFields } from '../../../hooks/participants/queries';
import { TPaymentMethodDto } from '@payler/api/client-office';
import { useCloseTransferModal } from '../TransferModal';
import { FormProvider, useForm } from 'react-hook-form';
import { assert } from '@payler/utils';
import { IonIcon } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';

export const SelectPaymentMethodStep = () => {
  const { t } = useTranslation(['accounts', 'common', 'fields']);
  const { formatAmountByCurrency } = useLanguageFeatures();
  const {
    senderAccount,
    setSenderAccount,
    setStep,
    paymentMethod,
    setPaymentMethod,
    onFirstStepBack,
  } = useAddParticipantWizardContext();
  const { data: accounts } = useAccountsQuery();
  const { data: paymentMethods, isLoading } = useParticipantFields(
    senderAccount?.id,
  );

  // TODO: проверить нужно ли получать closeModal из пропсов. Если нет необходимости выпилить closeModal из TransferWizard
  const closeModal = useCloseTransferModal();
  const methods = useForm<{
    senderAccountId: string;
    paymentMethod: TPaymentMethodDto | null;
  }>({
    mode: 'onChange',
    defaultValues: {
      senderAccountId: senderAccount?.id,
      paymentMethod: paymentMethod,
    },
  });

  const selectedPaymentMethod = methods.watch('paymentMethod');

  const senderAccountOptions: TExchangeDropdownOption[] = useMemo(() => {
    if (!accounts) return [];
    return accounts?.reduce((accum, account) => {
      if (account.allowOutgoingOperations) {
        accum.push({
          label: formatAmountByCurrency(account.amount, account.currency),
          value: account.id,
          caption: account.name,
          icon: getCurrencyIcon(account.currency),
        });
      }
      return accum;
    }, [] as TExchangeDropdownOption[]);
  }, [accounts, formatAmountByCurrency, senderAccount?.id]);

  const onAccountChange = useCallback(
    (accountId: string) => {
      const selectedAccount = accounts?.find(
        (account) => account.id === accountId,
      );
      setSenderAccount(selectedAccount);
      methods.resetField('paymentMethod', { defaultValue: null });
    },
    [accounts, setSenderAccount],
  );

  const onSubmit = methods.handleSubmit((values) => {
    assert(values.paymentMethod, 'Payment method is not selected');
    setPaymentMethod(values.paymentMethod);
    setStep('credentials');
  });

  // TODO: добавить иконки платежных методов
  const paymentMethodProps: TExchangeDropdownOption[] = useMemo(() => {
    if (!paymentMethods) return [];
    return Object.keys(paymentMethods.credentials).map((method) => ({
      label: method,
      value: method,
    }));
  }, [paymentMethods]);

  return (
    <FormProvider {...methods}>
      <BankModalBody>
        <VStack
          id="selectPaymentMethodForm"
          spacing={2}
          height="100%"
          flex="1"
          alignItems="stretch"
          as="form"
          onSubmit={onSubmit}
        >
          <VStack
            width="100%"
            spacing={3}
            height="100%"
            justifyContent="space-between"
            flex="1"
          >
            <VStack width="100%" spacing={2} height="100%" alignItems="stretch">
              <ExchangeDropdownField
                fieldName="senderAccountId"
                options={senderAccountOptions}
                triggerWrapperProps={{
                  boxShadow: 'none',
                  bgColor: 'primary.50',
                }}
                optionLabelProps={{
                  textStyle: TextStyles.Subtitle14Medium,
                }}
                // TODO: переделать передачу иконки
                dropdownIcon={{
                  component: ChevronCircledIcon,
                  props: { h: '26px', w: '26px', color: 'primary.500' },
                }}
                onChange={(value) => onAccountChange(value as string)}
              />
              <DropdownField
                fieldName="paymentMethod"
                label={t('fields:paymentMethod')}
                options={paymentMethodProps}
                floating
                triggerWrapperProps={{
                  bgColor: 'primary.50',
                }}
                isLoading={isLoading}
              />
            </VStack>
          </VStack>
        </VStack>
      </BankModalBody>
      <BankModalFooter>
        <HStack w="100%" spacing={1}>
          <IconButton
            variant="secondary"
            aria-label="back-step"
            w="56px"
            minW="56px"
            h="56px"
            border="none"
            icon={<Icon as={IonIcon} icon={chevronBack} w="24px" h="24px" />}
            onClick={() => onFirstStepBack?.()}
          />
          <Button
            variant="secondary"
            w="100%"
            data-testid="button_cancel"
            onClick={closeModal}
            border="none"
          >
            {t('common:cancel')}
          </Button>
          <Button
            type="submit"
            form="selectPaymentMethodForm"
            variant="primary"
            w="100%"
            data-testid="button_next-step"
            isDisabled={!selectedPaymentMethod}
          >
            {t('common:continue')}
          </Button>
        </HStack>
      </BankModalFooter>
    </FormProvider>
  );
};
