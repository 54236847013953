import { ComponentStyleConfig } from '@chakra-ui/react';
import { TextStyles } from '../textStyles';

export const FormLabelStyles: ComponentStyleConfig = {
  baseStyle: {
    textStyle: TextStyles.Caption12Medium,
    fontSize: '12px',
    fontWeight: 500,
    color: 'primary.350',
    _focus: {
      color: 'primary.500',
    },
    _disabled: {
      color: 'primary.300',
    },
    mb: 1,
    px: 0.5
  },
  variants: {
    floating: {
      textStyle: TextStyles.BodyUI16Regular,
      fontSize: '16px',
      fontWeight: 400,
      top: 1.5,
      left: 2,
      zIndex: 2,
      position: 'absolute',
      pointerEvents: 'none',
      transformOrigin: 'left top',
      transition: 'all 0.1s ease-out',
      _focus: {
        textStyle: TextStyles.Caption12Regular,
        fontWeight: 400,
        top: -1,
        fontSize: '12px',
        color: 'primary.400',
        backgroundColor: 'secondary.500',
      },
      _disabled: {
        color: 'primary.300',
      },
    },
  },
};
