export const accounts = {
  allAccountsRestricted:
    'Access to all accounts is restricted. Please contact your account manager.',
  accountWarning: {
    restrictedOutgoing:
      'Your account is restricted. You cannot perform outgoing transfers. Please contact your Account Manager.',
    restrictedIncoming:
      'Your account is restricted. You cannot perform incoming transfers. Please contact your Account Manager.',
    restrictedAll:
      'Your account is restricted. You cannot perform incoming and outgoing transfers. Please contact your Account Manager.',
    paymentRequest:
      'There are insufficient funds on your balance to cover mandatory fees {{amount}}',
    incomingTransferPending: `Incoming transfer of <bold>{{amount}} {{currency}}</bold> is pending`,
  },
  allAccounts: 'All accounts',
  allAccountsCounted: 'All accounts ({{quantity}})',
  hidden: '{{number}} hidden',
  activity: 'Activity',
  seeAll: 'See all',
  addAccount: 'Add account',
  currenciesNumber: '{{number}} currencies',
  goToActivity: 'Go to Activity',
  accountInfo: {
    sendMoney: 'Send money',
    send: 'Send',
    topUp: 'Top up',
    statement: 'Statement',
    editName: 'Edit Name',
    exchange: 'Exchange',
    confirmation: 'Confirmation',
    convertToCNY: 'Convert to CNY',
    more: 'More',
  },
  accountStatement: {
    title: 'Account Statement',
    description: 'Please select a date range for your account statement',
    labels: {
      dateFrom: 'Date from',
      dateTo: 'Date to',
    },
    fieldNames: {
      dateFrom: 'dateFrom',
      dateTo: 'dateTo',
    },
  },
  accountEditName: {
    title: 'Edit account name',
    ariaLabel: 'Edit account',
  },
  accountsDrawer: {
    accounts: '{{number}} accounts',
    ariaLabels: {
      closeDrawer: 'Close drawer',
      showOptions: 'Show options',
      enableSortingMode: 'Enable sorting mode',
      applySorting: 'Apply sorting',
    },
    organizeAccounts: 'Organize accounts',
    saveChanges: 'Save changes',
  },
  mobileMoreDrawer: {
    more: 'More',
    statement: 'Account statement',
    editName: 'Edit account name',
    confirmation: 'Confirmation',
  },
  transferMoney: {
    chooseRecipient: 'Choose a Recipient',
    selectRecipient: 'Please select a recipient',
    newTransfer: 'New transfer',
    search: 'Search',
    recipientsNotAdded: 'No recipients have been added yet',
    noRecipientsDescription: 'To continue, please click <b>“New transfer”</b>',
    nothingFound: 'Nothing found',
    transferDetails: 'Transfer details',
    accountNotFound: 'Account not found',
    recipientAccountNotFound: 'Recipient account not found',
    transferDetailsDescription:
      'Please enter the details of your transfer below',
    description: 'Description',
    continue: 'Continue',
    confirmation: 'Confirmation',
    senderAccount: 'Sender account',
    receiverAccount: 'Receiver account',
    noSourceAccount: 'No source account',
    recipient: 'Recipient',
    participant: 'Participant',
    iban: 'IBAN',
    accountNumber: 'Account number',
    ukAccountNumber: 'UK Account number',
    ukSortCode: 'UK Sort code',
    bic: 'BIC',
    bankName: 'Bank name',
    bankCountry: 'Bank country',
    address: 'Address',
    amount: 'Amount',
    fee: 'Fee',
    total: 'Total',
    transfer: 'Transfer',
    confirmationText: 'Please ensure the below information is correct',
    transferIdNotFound: 'Transfer id not found',
    transferPending: 'Transfer pending',
    dropzone: {
      title: 'Add confirmation document',
      description: 'Drag & Drop or choose file to upload',
    },
    documentWarning:
      'Amounts equal to or exceeding {{amount}} must include a supporting document',
    descriptionWarning:
      'Amounts equal to or exceeding {{amount}} must include a description',
    expirationDescription:
      'The code will expire in <timer>{{expirationCountdown}} sec</timer>',
    resendCode: 'Resend Code',
    transferFee: 'Fee: {{amount}} {{currency}}',
    transferType: 'Transfer type',
    completeOnboardingProcess: 'Complete onboarding process',
    yourAccountIsCurrentlyNotActivated:
      'Your account is currently not activated. To unlock all functionalities, please complete the steps below and we will activate your account promptly.',
    attachedDocuments: 'Attached documents',
    otpTitle: 'Enter OTP',
    otpDescription:
      'We’ve sent you an SMS with confirmation code to <phone>{{phone}}</phone>. Please enter it below.',
    resendCodeCountdown: 'Resend code in {{countdown}} sec',
    otpExpired:
      'Your OTP entry time has expired, and the payment process has been canceled',
    transferCanceled:
      'Sorry, the OTP entered is incorrect. Your payment has been canceled for security reasons. Please retry the payment process',
    exitOtpAlertTitle: 'Close window?',
    exitOtpAlertDescription:
      'Closing this window will result in the cancellation of your payment transaction. Are you sure you want to proceed?',
    downloadPdf: 'Download PDF',
    downloading: 'Downloading...',
    transferToSelfTitle: 'Transfer between your accounts',
    inSameCurrency: 'Transfers must be in the same currency',
    transferToNewRecipient: 'Transfer to new recipient',
    newPayee: 'Send money to a new payee',
    selfWarning: {
      title: 'We could not find the corresponding account for this transfer',
      description:
        'Please ensure you are transferring to an account in the same currency',
    },
    error: {
      title: 'Transfer cannot be completed',
    },
    selfTransfer: 'Self transfer',
    paymentChargesType: 'Payment charges type',
    chooseYourAccounts: 'Choose your accounts',
    enterAmount: 'Enter amount',
    specifyAmount: 'Please specify the amount you want to transfer',
    betweenYourAccounts: 'Transfer between your accounts',
    transferTo: 'Transfer to',
  },
  topUp: {
    title: 'Add Money',
    subtitle:
      'Please use the account details displayed below to send funds to your {{companyName}} account.',
    details:
      'Use these details to receive <bold>{{type}}</bold> transfers in <bold>{{currency}}</bold>',
    noData: 'No account data',
    emptyRequisites: {
      message: 'Your account details are being created',
      description: 'Please wait a moment',
    },
    requisitesType: {
      swift: 'SWIFT',
      sepa: 'SEPA',
      internal: 'Internal',
      fps: 'FPS',
      blockchain: 'Blockchain',
    },
    requisitesName: {
      accountNumber: 'Account number',
      beneficiary: 'Beneficiary',
      intermediaryBank: 'Intermediary bank',
      iban: 'IBAN',
      bic: 'BIC',
      address: 'Address',
      ukAccountNumber: 'UK Account Number',
      ukSortCode: 'UK Sort Code',
      network: 'Network',
      walletAddress: 'Wallet Address',
    },
  },
  exchange: {
    selectStep: {
      title: 'Exchange',
      description:
        'Buy and sell currencies and store the funds in your account',
      amount: 'Amount',
      youSpend: 'You spend',
      youGet: 'You get',
      exchangeRate: 'Exchange rate',
      notEnoughMoney: "You've entered more than you have in your account",
      exchangeDirection: '{{senderCurrency}} → {{receiverCurrency}}',
      exchangeRateError: 'Failed to get rate, please try again later',
      errorDescription:
        'Unfortunately, the transfer to another account cannot be completed because you have no available accounts with active incoming transfers.',
    },
    confirmationStep: {
      title: 'Confirmation',
      description:
        'Please check the details of your transfer before confirming',
      rate: 'Rate',
      youSpend: 'You spend',
      youGet: 'You get',
      noteWarning: 'Please note.',
      noteDescription:
        '<warning>{{noteWarning}}</warning> We do not currently fix exchange rates for currency exchange operations. During the exchange process, the exchange rate may change slightly. In this event, the transaction will be made according to the appropriate rate. The payler fee will be deducted from the amount of debit',
    },
    successTitle: 'Accepted for processing',
    successDescription:
      'Please note that the exchange rate may change slightly during the exchange process. In this event, the transaction will be made according to the appropriate rate.',
  },
  addAccountModal: {
    currencyStep: {
      title: 'Add account',
      description: 'Please select a currency for your account',
    },
    otpStep: {
      title: 'Enter OTP',
      description:
        'Once confirmed, your account will be created for the selected currency',
      smsDescription:
        'We’ve sent you an SMS with confirmation code to <phone>{{phone}}</phone>. Please enter it below.',
      resendCode: 'Resend Code',
      resendCodeCountdown: 'Resend code in {{countdown}} sec',
    },
    failStep: {
      expired: 'Expired',
      otpTimeExpired: 'This OTP has now expired',
      failed: 'Failed',
    },
    accountAdded: 'Account added',
  },
} as const;
