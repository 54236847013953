import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { TAccountDto } from '@payler/api/client-office';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useAccountByUrl,
  useAccountsQuery,
} from '../../hooks/accounts/queries';
import { useConvertToCNYRateQuery } from '../../hooks/transfers/queries';
import { ConvertToCNYConfirmation } from './ConvertToCNYConfirmation';
import { ConvertToCNYSelect } from './ConvertToCNYSelect';
import { ConvertToCNYSuccess } from './ConvertToCNYSuccess';
import { ConvertToCNYOtp } from './ConvertToCNYOtp';
import isNil from 'lodash/isNil';
import { removeThousandSeparatorsAndSuffix } from '../Exchange/helpers';
import { getExchangeFilteredReceiverAccountsByAccountForCNY } from './helpers';
import { TCurrency } from '@payler/bank-utils';

type TAmountDirection = 'sending' | 'receiving';
type TConvertToCNYStep = 'select' | 'confirmation' | 'otp' | 'success';
type AmountChangeCallbackFn = (data: {
  amount: string;
  amountDirection: TAmountDirection;
}) => void;

type TConvertToCNYWizardContext = {
  step: TConvertToCNYStep;
  setStep: Dispatch<SetStateAction<TConvertToCNYStep>>;
  sendingAmount: number | undefined;
  setSendingAmount: Dispatch<SetStateAction<number | undefined>>;
  exchangeRate: number | undefined;
  detailedExchangeRate: string;
  receivingAmount: number | undefined;
  setReceivingAmount: Dispatch<SetStateAction<number | undefined>>;
  senderAccount: TAccountDto | undefined;
  setSenderAccount: Dispatch<SetStateAction<TAccountDto | undefined>>;
  receiverAccount: TAccountDto | undefined;
  setReceiverAccount: Dispatch<SetStateAction<TAccountDto | undefined>>;
  isExchangeRateFetching: boolean;
  exchangeRateError: unknown;
  onAmountChange: AmountChangeCallbackFn;
  transferId?: string | undefined;
  setTransferId: Dispatch<SetStateAction<string | undefined>>;
};

const ConvertToCNYWizardContext = createContext<TConvertToCNYWizardContext>(
  {} as TConvertToCNYWizardContext,
);

export type TConvertToCNYSelectForm = {
  sendingAmount: number | undefined;
  receivingAmount: number | undefined;
  senderAccId: string | undefined;
  receiverAccId: string | undefined;
};

const useResolver = (senderAmount: number | undefined) => {
  const { t } = useTranslation(['common', 'accounts']);

  return yupResolver(
    yup.object().shape({
      sendingAmount: yup
        .number()
        .typeError(t('common:validate.required'))
        .required(t('common:validate.required'))
        .test((value, ctx) => {
          if (!value)
            return ctx.createError({ message: t('validate.required') });

          if (value > (senderAmount ?? 0)) {
            return ctx.createError({
              message: t('accounts:exchange.selectStep.notEnoughMoney'),
            });
          }
          return true;
        }),
      receivingAmount: yup.number(),
      senderAccId: yup.string(),
      receiverAccId: yup.string(),
    }),
  );
};

export const ConvertToCNYWizard: FC = () => {
  const selectedAccount = useAccountByUrl();
  const [step, setStep] = useState<TConvertToCNYStep>('select');
  const [sendingAmount, setSendingAmount] = useState<number | undefined>();
  const [exchangeRate, setExchangeRate] = useState<number>();
  const [receivingAmount, setReceivingAmount] = useState<number | undefined>();
  const [senderAccount, setSenderAccount] = useState<TAccountDto | undefined>(
    selectedAccount,
  );
  const [receiverAccount, setReceiverAccount] = useState<
    TAccountDto | undefined
  >();
  const [transferId, setTransferId] = useState<string>();
  const { t } = useTranslation('transfers');
  const detailedExchangeRate = t('certainExchangeRate', {
    senderCurrency: senderAccount?.currency.toUpperCase(),
    recipientCurrency: receiverAccount?.currency.toUpperCase(),
    exchangeRate: exchangeRate,
  });

  const { data: accounts } = useAccountsQuery();

  const {
    data: fxRateDto,
    isLoading: isExchangeRateFetching,
    error: exchangeRateError,
  } = useConvertToCNYRateQuery(
    {
      sellCurrency: senderAccount?.currency.toLowerCase() as TCurrency,
      buyCurrency: receiverAccount?.currency.toLowerCase() as TCurrency,
    },
    step === 'select' || step === 'confirmation',
  );

  const methods = useForm<TConvertToCNYSelectForm>({
    resolver: useResolver(senderAccount?.amount),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    values: {
      sendingAmount: sendingAmount,
      receivingAmount: receivingAmount,
      senderAccId: senderAccount?.id,
      receiverAccId: receiverAccount?.id,
    },
  });
  const {
    formState: { errors },
    clearErrors,
  } = methods;

  // установка дефолтного аккаунта получателя
  useEffect(() => {
    const filteredAccounts =
      getExchangeFilteredReceiverAccountsByAccountForCNY(accounts);
    if (
      receiverAccount &&
      filteredAccounts.find((item) => item.id === receiverAccount.id)
    ) {
      // если аккаунт уже установлен и он есть в выпадающем списке, то не меняем его
      return;
    }
    const selectedReceiverAccount = filteredAccounts[0];
    setReceiverAccount(selectedReceiverAccount);
  }, [senderAccount, accounts, receiverAccount, setReceiverAccount]);

  const [currentTypingAmount, setCurrentTypingAmount] = useState<number>();
  const [amountDirectionToggler, setAmountDirectionToggler] =
    useState<TAmountDirection>('sending');

  const onAmountChange = ({
    amount,
    amountDirection,
  }: {
    amount: string;
    amountDirection: TAmountDirection;
  }) => {
    if (errors.sendingAmount) {
      clearErrors('sendingAmount');
    }

    const processedAmount =
      Number(removeThousandSeparatorsAndSuffix(amount)) || undefined;
    setCurrentTypingAmount(processedAmount);
    setAmountDirectionToggler(amountDirection);
    if (amountDirection === 'sending') {
      setSendingAmount(processedAmount);
    } else {
      setReceivingAmount(processedAmount);
    }
  };

  useEffect(() => {
    setExchangeRate(fxRateDto?.rate);
  }, [fxRateDto?.rate, setExchangeRate]);

  useEffect(() => {
    if (isNil(exchangeRate) || isExchangeRateFetching) return;
    if (amountDirectionToggler === 'sending') {
      const roundedAmount = currentTypingAmount
        ? Number((currentTypingAmount * exchangeRate).toFixed(2))
        : undefined;
      setReceivingAmount(roundedAmount);
    } else {
      const roundedAmount = currentTypingAmount
        ? Number((currentTypingAmount / exchangeRate).toFixed(2))
        : undefined;
      setSendingAmount(roundedAmount);
    }
  }, [currentTypingAmount, exchangeRate, isExchangeRateFetching]);

  const ctx: TConvertToCNYWizardContext = useMemo(() => {
    return {
      step,
      setStep,
      sendingAmount,
      setSendingAmount,
      exchangeRate,
      receivingAmount,
      setReceivingAmount,
      senderAccount,
      setSenderAccount,
      receiverAccount,
      setReceiverAccount,
      detailedExchangeRate,
      isExchangeRateFetching,
      exchangeRateError,
      onAmountChange,
      transferId,
      setTransferId,
    };
  }, [
    step,
    setStep,
    sendingAmount,
    setSendingAmount,
    exchangeRate,
    receivingAmount,
    setReceivingAmount,
    senderAccount,
    setSenderAccount,
    receiverAccount,
    setReceiverAccount,
    detailedExchangeRate,
    isExchangeRateFetching,
    exchangeRateError,
    transferId,
    setTransferId,
  ]);

  return (
    <ConvertToCNYWizardContext.Provider value={ctx}>
      <FormProvider {...methods}>
        {step === 'select' && <ConvertToCNYSelect />}
        {step === 'confirmation' && <ConvertToCNYConfirmation />}
        {step === 'otp' && <ConvertToCNYOtp />}
        {step === 'success' && <ConvertToCNYSuccess />}
      </FormProvider>
    </ConvertToCNYWizardContext.Provider>
  );
};

export const useConvertToCNYWizardContext = () =>
  useContext(ConvertToCNYWizardContext);
