import { TAccountDto } from '@payler/api/client-office';
import { useTranslation } from 'react-i18next';

export const useAccountIdentifier = (account: TAccountDto | undefined) => {
  const { t } = useTranslation('accounts');
  if (!account) return null;
  let name;
  let value;
  if (account.swiftCredentials?.iban) {
    name = t('transferMoney.iban');
    value = account.swiftCredentials?.iban;
  } else if (account.swiftCredentials?.bankAccountNumber) {
    name = t('transferMoney.accountNumber');
    value = account.swiftCredentials?.bankAccountNumber;
  } else if (account.sepaCredentials?.iban) {
    name = t('transferMoney.iban');
    value = account.sepaCredentials?.iban;
  } else if (account.sepaCredentials?.bankAccountNumber) {
    name = t('transferMoney.accountNumber');
    value = account.sepaCredentials?.bankAccountNumber;
  } else if (account.fasterUKCredentials) {
    name = t('transferMoney.ukAccountNumber');
    value = account.fasterUKCredentials.ukAccountNumber;
  } else if (account.internalCredentials) {
    name = t('transferMoney.accountNumber');
    value = account.internalCredentials.bankAccountNumber;
  } else {
    return null;
  }

  return {
    name,
    value,
    fullInfo: `${name}: ${value}`,
  };
};
