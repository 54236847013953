import { TCredentialsDto } from '@payler/api/client-office';

type ExtractedFields = Record<string, string>;

export function extractPaymentFields(
  credentials?: TCredentialsDto,
  fields?: string[],
): ExtractedFields {
  const defaultResult = {
    name: '',
    country: '',
    bic: '',
  };

  if (!credentials) return {};

  let allFields: Record<string, string> = {};

  switch (credentials.paymentMethod) {
    case 'ach':
      allFields = {
        accountNumber: credentials.accountNumber || '',
        bankCode: credentials.bankCode || '',
      };
      break;
    case 'blockchainNetwork':
      allFields = {
        walletId: credentials.walletId || '',
        network: credentials.network || '',
        memo: credentials.memo || '',
      };
      break;
    case 'fasterUK':
      allFields = {
        accountNumber: credentials.accountNumber || '',
        sortCode: credentials.sortCode || '',
      };
      break;
    case 'internal':
      allFields = {
        iban: credentials.ibanOrAccountNumber || '',
      };
      break;
    case 'sepa':
      allFields = {
        iban: credentials.iban || '',
        bic: credentials.bic || '',
      };
      break;
    case 'swift':
      allFields = {
        iban: credentials.ibanOrAccountNumber || '',
        bic: credentials.bicOrBankCode || '',
        bankName: credentials.bankName || '',
        bankCountry: credentials.bankCountry || '',
      };
      break;
    default:
      return defaultResult;
  }

  return fields?.length
    ? Object.fromEntries(
        Object.entries(allFields).filter(([key]) => fields.includes(key)),
      )
    : allFields;
}
