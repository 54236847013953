import { lazy, Suspense } from 'react';
import { LoaderBox } from './LoaderBox/LoaderBox';

const withLazyLoader = (
  loader: () => Promise<{ default: React.ComponentType }>,
) => {
  const Component = lazy(loader);
  return () => (
    <Suspense fallback={<LoaderBox />}>
      <Component />
    </Suspense>
  );
};

export const AccountsPageLazy = withLazyLoader(() =>
  import('../pages/AccountsPage').then((module) => ({
    default: module.AccountsPage,
  })),
);

export const ActivityPageLazy = withLazyLoader(() =>
  import('../pages/ActivityPage/ActivityPage').then((module) => ({
    default: module.ActivityPage,
  })),
);

export const ProfilePageLazy = withLazyLoader(() =>
  import('../pages/Profile/ProfilePage').then((module) => ({
    default: module.ProfilePage,
  })),
);

export const NotFoundPageLazy = withLazyLoader(() =>
  import('../pages/NotFoundPage/NotFoundPage').then((module) => ({
    default: module.NotFoundPage,
  })),
);

export const OnboardingPageLazy = withLazyLoader(() =>
  import('../pages/OnboardingPage/OnboardingPage').then((module) => ({
    default: module.OnboardingPage,
  })),
);

export const OnboardingProcessPageLazy = withLazyLoader(() =>
  import('../pages/OnboardingProcessPage/OnboardingProcessPage').then(
    (module) => ({
      default: module.OnboardingProcessPage,
    }),
  ),
);

export const RejectionPageLazy = withLazyLoader(() =>
  import('../pages/OnboardingPage/RejectionPage').then((module) => ({
    default: module.RejectionPage,
  })),
);

export const SamSubOnboardingPageLazy = withLazyLoader(() =>
  import('../pages/SumSubOnboardingPage/SumSubOnboardingPage').then(
    (module) => ({
      default: module.SumSubOnboardingPage,
    }),
  ),
);

export const SamSubRejectionPageLazy = withLazyLoader(() =>
  import('../pages/SumSubOnboardingPage/RejectionPage').then((module) => ({
    default: module.RejectionPage,
  })),
);
