import { CardContent } from '@payler/ui-components';
import React from 'react';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import { TransfersTableWithData } from '../ActivitiesTable/ActivitiesTable';
import { useAccountIdFromUrl } from '../../hooks/accounts/queries';
import { useTransfersQuery } from '../../hooks/transfers/queries';
import { Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { TextStyles } from '@payler/ui-theme';
import { usePermissions } from '../../hooks/use-permissions';
import { useTranslation } from 'react-i18next';

const NUMBER_OF_TRANSFERS_ON_ACCOUNTS_PAGE = 6;

export const ActivityFilteredTable = () => {
  return (
    <React.Suspense fallback={<LoaderBox variant="table" />}>
      <CardContent
        p={0}
        bg="secondary.500"
        borderRadius="md"
        border="0.5px solid"
        borderColor="primary.100"
        overflow="hidden"
        width="100%"
        boxShadow="basic"
      >
        <Transfers />
      </CardContent>
    </React.Suspense>
  );
};

const Transfers = () => {
  const { t } = useTranslation(['accounts']);
  const accountId = useAccountIdFromUrl();
  const params = {
    PageSize: NUMBER_OF_TRANSFERS_ON_ACCOUNTS_PAGE,
    AccountId: accountId || undefined,
  };
  const { data, isLoading } = useTransfersQuery(params, !!accountId);
  const { isGlobalAccounts } = usePermissions();

  return (
    <React.Suspense fallback={<LoaderBox />}>
      <TransfersTableWithData
        params={{
          PageSize: NUMBER_OF_TRANSFERS_ON_ACCOUNTS_PAGE,
          AccountId: accountId || undefined,
        }}
        enabled={!!accountId}
      />
      {!isLoading && data?.data.length !== 0 && (
        <Button
          as={Link}
          variant="secondary"
          w="100%"
          borderBottomRadius="md"
          borderTopRadius={0}
          border="none"
          boxShadow="basic"
          color="primary.400"
          textStyle={TextStyles.Caption12Medium}
          isDisabled={!isGlobalAccounts}
          to={{
            pathname: '/activity',
            search: `?AccountId=${accountId}`,
          }}
          data-testid="button_go-to-activity"
        >
          {t('accounts:goToActivity')}
        </Button>
      )}
    </React.Suspense>
  );
};
