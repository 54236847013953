import { Button, HStack, Icon, IconButton, VStack } from '@chakra-ui/react';
import {
  BankModalBody,
  BankModalFooter,
} from '../../../components/BankModal/BankModal';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { useAddParticipantWizardContext } from './AddParticipantWizard';
import { IonIcon } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { useCloseTransferModal } from '../TransferModal';
import {
  useAddParticipantMutation,
  useParticipantFields,
} from '../../../hooks/participants/queries';
import {
  TAddLegalEntitiesFields,
  TCreateParticipantCommand,
  TLegalEntityFieldName,
} from '@payler/api/client-office';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { SegmentedPicker } from '@payler/ui-components';
import { useLegalEntitiesFormFields } from './helpers/use-form-fields';
import { assert } from '@payler/utils';
import { getStructuredParticipant } from './helpers/get-structured-participant';
import { useLanguageFeatures } from '../../../hooks/use-language-features';
import { getCurrencyIcon } from '../../../currency';
import ExchangeDropdown from '../../../components/ExchangeDropdown/ExchangeDropdown';

const useResolver = (fields?: TLegalEntityFieldName[]) => {
  if (!fields || fields.length === 0) {
    return undefined;
  }
  let validationFields = {} as Record<TLegalEntityFieldName, yup.AnySchema>;
  // TODO: добавить расширенную валидацию
  fields.map((field) => {
    switch (field) {
      case 'companyName':
        validationFields.companyName = yup.string().required();
        break;
      case 'firstName':
        validationFields.firstName = yup.string().required();
        break;
      case 'lastName':
        validationFields.lastName = yup.string().required();
        break;
      case 'country':
        validationFields.country = yup.string().required();
        break;
      case 'postalCode':
        validationFields.postalCode = yup.string().required();
        break;
      case 'city':
        validationFields.city = yup.string().required();
        break;
      case 'street':
        validationFields.street = yup.string().required();
        break;
    }
  });
  return yupResolver(yup.object().shape(validationFields));
};

export const PersonalInfoStep = () => {
  const { t } = useTranslation(['accounts', 'common']);
  const {
    setStep,
    senderAccount,
    credentialsFields,
    paymentMethod,
    onFinishWizard,
    participant,
    setParticipant,
    setParticipantId,
  } = useAddParticipantWizardContext();
  const { mutate: addParticipant, isPending } = useAddParticipantMutation();
  const { formatAmountByCurrency } = useLanguageFeatures();

  const [isBusinessSelected, setIsBusinessSelected] = useState<boolean>(true);

  // TODO: проверить нужно ли получать closeModal из пропсов. Если нет необходимости выпилить closeModal из TransferWizard
  const closeModal = useCloseTransferModal();
  const { data: participantFields } = useParticipantFields(senderAccount?.id);
  const legalEntitiesFieldNames = isBusinessSelected
    ? participantFields?.legalEntities.business
    : participantFields?.legalEntities.individual;
  const fields = useLegalEntitiesFormFields(legalEntitiesFieldNames);

  assert(senderAccount, 'Cannot find selected account');
  const accountOptions = [
    {
      label: formatAmountByCurrency(
        senderAccount.amount,
        senderAccount.currency,
      ),
      value: senderAccount.id,
      caption: senderAccount.name,
      icon: getCurrencyIcon(senderAccount.currency),
    },
  ];

  const methods = useForm<TAddLegalEntitiesFields>({
    defaultValues: {
      ...participant?.legalEntity,
      ...participant?.legalEntity?.address,
    },
    resolver: useResolver(legalEntitiesFieldNames),
    mode: 'onChange',
  });
  const onSubmit = methods.handleSubmit((values) => {
    assert(
      senderAccount && credentialsFields && paymentMethod,
      'Cannot find participant info',
    );
    const structuredParticipant = getStructuredParticipant({
      paymentMethod: paymentMethod,
      credentialsFields: credentialsFields,
      legalEntityFields: values,
      isBusiness: isBusinessSelected,
    });
    const addParticipantParam: TCreateParticipantCommand = {
      accountId: senderAccount.id,
      participant: structuredParticipant,
    };

    setParticipant(structuredParticipant);
    addParticipant(addParticipantParam, {
      onSuccess: (id: string) => {
        setParticipantId(id);
        onFinishWizard?.();
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <BankModalBody>
        <VStack
          id="personalInfoForm"
          spacing={3}
          height="100%"
          flex="1"
          alignItems="stretch"
          as="form"
          onSubmit={onSubmit}
        >
          <ExchangeDropdown
            options={accountOptions}
            value={senderAccount.id}
            optionLabelProps={{
              textStyle: TextStyles.Subtitle14Medium,
            }}
            isDisabled
          />
          <LegalEntityPicker
            isBusinessSelected={isBusinessSelected}
            setIsBusinessSelected={setIsBusinessSelected}
          />
          <VStack width="100%" spacing={2} height="100%" alignItems="stretch">
            {fields.map((field) => field)}
          </VStack>
        </VStack>
      </BankModalBody>
      <BankModalFooter>
        <HStack w="100%" spacing={1}>
          <IconButton
            variant="secondary"
            aria-label="back-step"
            h="56px"
            w="56px"
            minW="56px"
            border="none"
            icon={<Icon as={IonIcon} icon={chevronBack} w="24px" h="24px" />}
            onClick={() => setStep('credentials')}
          />
          <Button
            type="submit"
            form="selectPaymentMethod"
            variant="secondary"
            w="100%"
            data-testid="button_continue"
            onClick={closeModal}
          >
            {t('common:cancel')}
          </Button>
          <Button
            type="submit"
            form="personalInfoForm"
            variant="primary"
            w="100%"
            data-testid="button_continue"
            isLoading={isPending}
          >
            {t('common:continue')}
          </Button>
        </HStack>
      </BankModalFooter>
    </FormProvider>
  );
};

const LegalEntityPicker: FC<{
  isBusinessSelected: boolean;
  setIsBusinessSelected: Dispatch<SetStateAction<boolean>>;
}> = ({ isBusinessSelected, setIsBusinessSelected }) => {
  const { t } = useTranslation('participants');
  const { unregister } = useFormContext<TAddLegalEntitiesFields>();

  const selection = isBusinessSelected ? 0 : 1;

  const typeOptions = [t('business'), t('individual')];

  const handleChange = useCallback(
    (recipientTypeIndex: number) => {
      setIsBusinessSelected((val) => !val);
      switch (recipientTypeIndex) {
        case 0:
          unregister('firstName');
          unregister('lastName');
          break;
        case 1:
          unregister('companyName');
          break;
      }
    },
    [unregister],
  );

  return (
    <SegmentedPicker
      options={typeOptions}
      onSelectionChange={handleChange}
      selection={selection}
      isFullWidth
    />
  );
};
