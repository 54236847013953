/**
 * Юниты размеров из макета
 *
 * @see {@link https://chakra-ui.com/docs/styled-system/theming/theme#spacing}
 */

const units = {
  px: '1px',
  0.25: '2px',
  0.5: '4px',
  0.75: '6px',
  1: '8px',
  1.5: '12px',
  2: '16px',
  2.5: '20px',
  3: '24px',
  4: '32px',
  5: '40px',
  6: '48px',
  7: '56px',
  8: '64px',
  9: '72px',
  10: '80px',
  11: '88px',
  12: '96px',
  14: '112px',
  16: '128px',
  20: '160px',
  24: '192px',
  28: '224px',
  32: '256px',
  36: '288px',
  40: '320px',
  44: '352px',
  48: '384px',
  56: '448px',
  60: '480px',
  64: '512px',
  72: '576px',
  80: '640px',
  96: '768px',
};

export const sizeOverrides = {
  sizes: { ...units, md: '460px' },
  space: units,
  spacing: units,
  radii: { ...units, md: '16px', xl: '32px' },
};
