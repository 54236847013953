import React from 'react';
import {
  Button,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { breakpoints, TextStyles } from '@payler/ui-theme';
import { Close16Icon } from '@payler/ui-icons';
import { ThinDivider } from '@payler/ui-components';
import { useTranslation } from 'react-i18next';
import {
  FilterWrapperContextProvider,
  useFilterWrapperContext,
} from '../../context/FilterWrapperContextProvider';

const HeaderGrid = styled.div`
  display: grid;
  padding: 24px 24px 24px;
  grid-template-areas: 'close title';
  grid-template-columns: 48px 1fr;
  @media (min-width: ${breakpoints.xsm}) {
    grid-template-areas: 'title close';
    grid-template-columns: 1fr 48px;
  }
  grid-gap: 16px;
`;

export type TFiltersWrapperProps = {
  selectedFiltersCount: number;
  close: VoidFunction;
  formId: string;
};

const FiltersWrapperWithoutContext: FCC<TFiltersWrapperProps> = ({
  children,
  selectedFiltersCount,
  close,
  formId,
}) => {
  const { t } = useTranslation(['common']);
  const { ref } = useFilterWrapperContext();

  return (
    <>
      <DrawerHeader p={0}>
        <HeaderGrid>
          <HStack spacing={2}>
            <VStack flex={1} alignItems="flex-start" spacing={0}>
              <Text as="h4" textStyle={TextStyles.h4}>
                {t('common:filters')}
              </Text>
              <Text textStyle={TextStyles.Subtitle14Regular}>
                {selectedFiltersCount}
                &nbsp;{t('common:selected')}
              </Text>
            </VStack>
          </HStack>
          <IconButton
            gridArea="close"
            variant="secondary"
            w={6}
            h={6}
            onClick={close}
            aria-label="close"
            icon={<Icon w={3} h={3} as={Close16Icon} />}
          />
        </HeaderGrid>
      </DrawerHeader>
      <ThinDivider />
      <DrawerBody p={0} display="flex" flexDirection="column">
        <Flex flexDirection="column" flex="1" p={3} minH="100%">
          {children}
        </Flex>
      </DrawerBody>
      <DrawerFooter p={3}>
        <HStack w="100%" justifyContent="space-between">
          {selectedFiltersCount > 0 && (
            <Button
              variant="secondary"
              onClick={() => ref.current?.reset()}
              data-testid="button_clear-filters"
              w="100%"
            >
              {t('common:clearFilters')}
            </Button>
          )}
          <Button
            variant="primary"
            type="submit"
            w="100%"
            data-testid="button_apply-filters"
            form={formId}
          >
            {t('common:apply')}
          </Button>
        </HStack>
      </DrawerFooter>
    </>
  );
};

export const FiltersWrapper: FCC<TFiltersWrapperProps> = (props) => (
  <FilterWrapperContextProvider>
    <FiltersWrapperWithoutContext {...props} />
  </FilterWrapperContextProvider>
);
