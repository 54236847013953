export const demo = {
  completeYourOnboardingProcess: 'Complete Your Onboarding Process',
  yourAccountIsCurrentlyNotActivated:
    'Your account is currently not activated. To unlock all functionalities, please complete the steps below and we will activate your account promptly.',
  companyApplicationReceived: 'Company Application Received',
  companyApplicationReceivedDescription:
    "We've received your company application. Please sit tight as we process your request to get started with us.",
  kycVerificationSent:
    'KYC Verification sent to Shareholders / UBOs / Directors',
  kycVerificationSentDescription:
    "We're taking the necessary steps to verify key individuals in your company. We've sent Know Your Customer (KYC) verification requests to all indicated shareholders, ultimate beneficial owners (UBO), and directors. To help us set you up quickly, please ensure all parties respond to these requests promptly.",
  onboardingFeePaid: 'Onboarding Fee Paid',
  onboardingFeePaidDescription:
    'To activate your account and benefit from our services, please pay the required onboarding fee to complete the onboarding process.',
  yourAccountIsActivated: 'Your Account is Activated',
  yourAccountIsActivatedDescription:
    'Congratulations! Your account is now activated. You can now access all of our services and reap the benefits of truly seamless transactions.',
  pay: 'Pay',
} as const;
