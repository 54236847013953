import {
  TAddCredentialsFields,
  TAddLegalEntitiesFields,
  TCreateParticipantDto,
  TCredentialsDto,
  TPaymentMethodDto,
} from '@payler/api/client-office';

export const getStructuredParticipant = ({
  paymentMethod,
  credentialsFields,
  legalEntityFields,
  isBusiness,
}: {
  paymentMethod: TPaymentMethodDto;
  credentialsFields: TAddCredentialsFields;
  legalEntityFields: TAddLegalEntitiesFields;
  isBusiness: boolean;
}): TCreateParticipantDto => {
  let credentialsParams = {} as TCredentialsDto;
  const { companyName, firstName, lastName, ...address } = legalEntityFields;
  switch (paymentMethod) {
    case 'ach':
      credentialsParams = {
        paymentMethod,
        accountNumber: credentialsFields.accountNumber!,
        bankCode: credentialsFields.bankCode!,
      };
      break;
    case 'blockchainNetwork':
      credentialsParams = {
        paymentMethod,
        walletId: credentialsFields.walletId!,
        network: credentialsFields.network!,
        memo: credentialsFields.memo,
      };
      break;
    case 'fasterUK':
      credentialsParams = {
        paymentMethod,
        accountNumber: credentialsFields.accountNumber!,
        sortCode: credentialsFields.sortCode!,
      };
      break;
    case 'internal':
      credentialsParams = {
        paymentMethod,
        ibanOrAccountNumber: credentialsFields.ibanOrAccountNumber!,
      };
      break;
    case 'sepa':
      credentialsParams = {
        paymentMethod,
        iban: credentialsFields.iban!,
        bic: credentialsFields.bic,
      };
      break;
    case 'swift':
      credentialsParams = {
        paymentMethod,
        ibanOrAccountNumber: credentialsFields.ibanOrAccountNumber!,
        bicOrBankCode: credentialsFields.bicOrBankCode,
        bankName: credentialsFields.bankName,
        bankCountry: credentialsFields.bankCountry,
      };
      break;
    default:
      break;
  }
  return {
    credentials: credentialsParams,
    legalEntity: {
      ...(isBusiness
        ? {
            type: 'business',
            companyName: companyName!,
          }
        : {
            type: 'individual',
            firstName: firstName!,
            lastName: lastName!,
          }),
      address,
    },
  };
};
