import {
  Button,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import {
  BankModalBody,
  BankModalFooter,
  BankModalTitle,
  ModalHeaderActions,
} from '../../components/BankModal/BankModal';
import { useCreateOutgoingTransferMutation } from '../../hooks/transfers/queries';
import {
  TTransferDetailsForm,
  useTransferWizardContext,
} from './TransferWizard';
import {
  REQUISITES_NOT_FOUND_ERROR,
  useGetAxiosError,
  useHandleFormError,
} from '@payler/bank-utils';
import { usePermissions } from '../../hooks/use-permissions';
import { useFormContext } from 'react-hook-form';
import { FormOption } from '@payler/ui-components';
import { beautifyIban } from '../../helpers/recipient';
import { useParticipantBankInfo } from '../../hooks/use-participant-bank-info';
import { useTransferMoneyInfo } from '../../hooks/use-transfer-money-info';
import { DocumentsWrapper } from '../../components/DocumentLink/DocumentsWrapper';
import { useParticipantAddress } from '../../helpers/use-participant-address';
import {
  TAchCredentials,
  TCredentialsDto,
  TCryptoCredentials,
  TFasterUkCredentials,
  TInternalCredentials,
  TSepaCredentials,
  TSwiftCredentials,
} from '@payler/api/client-office';
import { IonIcon } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { useCloseTransferModal } from './TransferModal';

const fieldsMap: Record<string, keyof TTransferDetailsForm> = {
  Amount: 'amount',
  Description: 'description',
};

export const TransferConfirmation = () => {
  const { setStep, transfer, setTransferId, isSelfTransfer, setError } =
    useTransferWizardContext();
  const { t } = useTranslation(['accounts', 'common']);
  const getError = useGetAxiosError();
  const [errorText, setErrorText] = useState('');
  const { isGlobalAccounts } = usePermissions();

  const { mutate: createOutgoingTransfer, isPending: isCreateTransferLoading } =
    useCreateOutgoingTransferMutation();

  const closeModal = useCloseTransferModal();

  const methods = useFormContext<TTransferDetailsForm>();
  const handleFormError = useHandleFormError<TTransferDetailsForm>();

  const handleBack = () => {
    if (isSelfTransfer) {
      setStep('selfTransferDetails');
    } else {
      setStep('form');
    }
  };
  const handleSubmit = useCallback(() => {
    if (transfer) {
      createOutgoingTransfer(transfer, {
        onSuccess: (transferId: string) => {
          setTransferId(transferId);
          setStep('otp');
        },
        onError: (e) => {
          const { fieldErrors } = getError(e);
          if (fieldErrors?.[0]?.fieldErrorCode === REQUISITES_NOT_FOUND_ERROR) {
            setError(e);
            setStep('error');
            return;
          }
          const unknownFieldErrors = handleFormError(e, methods, fieldsMap);
          if (unknownFieldErrors.length)
            setErrorText(unknownFieldErrors.join(' '));
        },
      });
    }
  }, [
    createOutgoingTransfer,
    handleFormError,
    methods,
    setStep,
    setTransferId,
    transfer,
  ]);

  return (
    <>
      <BankModalTitle title={t('accounts:transferMoney.confirmation')} />
      <BankModalBody>
        <VStack spacing={3} height="100%" justifyContent="space-between">
          <VStack spacing={2} width="100%" alignItems="baseline">
            <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
              {t('accounts:transferMoney.confirmationText')}
            </Text>
            <VStack pb={0.25} spacing={2} width="100%" alignItems="baseline">
              {isSelfTransfer ? <SelfTransferInfo /> : <CommonTransferInfo />}
              {errorText && <ApiErrorText>{errorText}</ApiErrorText>}
            </VStack>
          </VStack>
        </VStack>
      </BankModalBody>
      <BankModalFooter>
        <HStack w="100%" spacing={1}>
          <IconButton
            variant="secondary"
            aria-label="back-step"
            w="56px"
            minW="56px"
            h="56px"
            border="none"
            icon={<Icon as={IonIcon} icon={chevronBack} w="24px" h="24px" />}
            isDisabled={isCreateTransferLoading}
            onClick={handleBack}
          />
          <Button
            variant="secondary"
            w="100%"
            data-testid="button_cancel"
            border="none"
            onClick={closeModal}
          >
            {t('common:cancel')}
          </Button>
          <Button
            variant="primary"
            w="100%"
            onClick={() => {
              if (!isGlobalAccounts) {
                setStep('demo');
              } else {
                handleSubmit();
              }
            }}
            isLoading={isCreateTransferLoading}
            data-testid="button_continue"
          >
            {t('accounts:transferMoney.continue')}
          </Button>
        </HStack>
      </BankModalFooter>
    </>
  );
};

const Row: React.FC<{
  title: string;
  description: ReactNode | string | undefined;
  errorMessage?: string;
}> = ({ title, description, errorMessage }) => {
  if (!description) return null;
  return (
    <FormOption isInvalid={!!errorMessage} errorMessage={errorMessage}>
      <VStack spacing={0.5} width="100%" alignItems="baseline">
        <Text textStyle={TextStyles.Caption12Regular} color="primary.350">
          {title}
        </Text>
        {typeof description === 'string' ? (
          <Text textStyle={TextStyles.BodyUI16Regular} color="primary.500">
            {description}
          </Text>
        ) : (
          description
        )}
      </VStack>
    </FormOption>
  );
};

const SelfTransferInfo = () => {
  const { t } = useTranslation(['accounts']);
  const { senderAccount, receiverAccount, transfer, transfersFee } =
    useTransferWizardContext();
  const {
    formState: { errors },
  } = useFormContext<TTransferDetailsForm>();

  const currency = receiverAccount?.currency.toLowerCase() || '';

  const { amount, fee, totalAmount } = useTransferMoneyInfo({
    currency,
    feeAmount: transfersFee?.feeAmount,
    sendingAmount: transfer?.sendingAmount,
  });

  return (
    <>
      <Row
        title={t('accounts:transferMoney.senderAccount')}
        description={senderAccount?.number}
      />
      <Row
        title={t('accounts:transferMoney.receiverAccount')}
        description={receiverAccount?.number}
      />
      <Row
        title={t('accounts:transferMoney.amount')}
        description={amount}
        errorMessage={errors.amount?.message}
      />
      <Row title={t('accounts:transferMoney.fee')} description={fee} />
      <Row
        title={t('accounts:transferMoney.total')}
        description={totalAmount}
      />
    </>
  );
};

const CommonTransferInfo = () => {
  const { t } = useTranslation(['accounts', 'transfers']);
  const { transfer, senderAccount, transfersFee, participant } =
    useTransferWizardContext();
  const {
    formState: { errors },
  } = useFormContext<TTransferDetailsForm>();

  const currency = senderAccount?.currency.toLowerCase() || '';
  // TODO: переработать и использовать getRecipientTitle
  const participantTitle =
    participant?.legalEntity?.type === 'business'
      ? participant?.legalEntity?.companyName
      : `${participant?.legalEntity?.firstName} ${participant?.legalEntity?.lastName}`;
  const transferSystem = participant?.credentials.paymentMethod;

  const { amount, fee, totalAmount } = useTransferMoneyInfo({
    currency,
    feeAmount: transfersFee?.feeAmount,
    sendingAmount: transfer?.sendingAmount,
  });

  const participantAddress = useParticipantAddress(
    participant?.legalEntity?.address,
  );

  return (
    <>
      <Row
        title={t('accounts:transferMoney.senderAccount')}
        description={senderAccount?.number}
      />
      <Row
        title={t('accounts:transferMoney.recipient')}
        description={participantTitle}
      />
      {participant && (
        <CredentialsInfo credentials={participant?.credentials} />
      )}
      <Row
        title={t('accounts:transferMoney.amount')}
        description={amount}
        errorMessage={errors.amount?.message}
      />
      <Row title={t('accounts:transferMoney.fee')} description={fee} />
      <Row
        title={t('accounts:transferMoney.total')}
        description={totalAmount}
      />
      <Row
        title={t('accounts:transferMoney.transferType')}
        description={
          transferSystem ? t(`transfers:mappedSystems.${transferSystem}`) : ''
        }
      />
      {transfer?.feeAllocation && (
        <Row
          title={t('accounts:transferMoney.paymentChargesType')}
          description={transfer?.feeAllocation.toUpperCase()}
        />
      )}
      <Row
        title={t('accounts:transferMoney.address')}
        description={participantAddress}
      />
      <Row
        title={t('accounts:transferMoney.description')}
        description={transfer?.description || '-'}
        errorMessage={errors.description?.message}
      />
      {transfer?.documents && transfer?.documents.length > 0 && (
        <Row
          title={t('accounts:transferMoney.attachedDocuments')}
          description={
            <DocumentsWrapper
              documents={transfer.documents}
              isClickable={false}
            />
          }
        />
      )}
    </>
  );
};

export const CredentialsInfo = ({
  credentials,
}: {
  credentials: TCredentialsDto;
}) => {
  switch (credentials.paymentMethod) {
    case 'swift':
      return <SwiftCredentials credentials={credentials} />;
    case 'sepa':
      return <SepaCredentials credentials={credentials} />;
    case 'fasterUK':
      return <FasterUkCredentials credentials={credentials} />;
    case 'ach':
      return <AchCredentials credentials={credentials} />;
    case 'blockchainNetwork':
      return <CryptoCredentials credentials={credentials} />;
    case 'internal':
      return <InternalCredentials credentials={credentials} />;
    default:
      return null;
  }
};

const SwiftCredentials = ({
  credentials,
}: {
  credentials: TSwiftCredentials;
}) => {
  const { t } = useTranslation('fields');
  const { label: bankInfoTitle, value: bankInfoDescription } =
    useParticipantBankInfo({
      name: credentials.bankName,
      country: credentials.bankCountry,
      bic: credentials.bicOrBankCode,
    });
  return (
    <>
      <Row
        title={t('ibanOrAccountNumber')}
        description={credentials.ibanOrAccountNumber}
      />
      <Row title={bankInfoTitle} description={bankInfoDescription} />
    </>
  );
};
const SepaCredentials = ({
  credentials,
}: {
  credentials: TSepaCredentials;
}) => {
  const { t } = useTranslation('fields');
  return (
    <>
      <Row title={t('iban')} description={beautifyIban(credentials.iban)} />
      <Row title={t('bic')} description={credentials.bic} />
    </>
  );
};
const FasterUkCredentials = ({
  credentials,
}: {
  credentials: TFasterUkCredentials;
}) => {
  const { t } = useTranslation('fields');
  return (
    <>
      <Row
        title={t('ukAccountNumber')}
        description={credentials.accountNumber}
      />
      <Row title={t('sortCode')} description={credentials.sortCode} />
    </>
  );
};
const AchCredentials = ({ credentials }: { credentials: TAchCredentials }) => {
  const { t } = useTranslation('fields');
  return (
    <>
      <Row title={t('accountNumber')} description={credentials.accountNumber} />
      <Row title={t('bankCode')} description={credentials.bankCode} />
    </>
  );
};
const InternalCredentials = ({
  credentials,
}: {
  credentials: TInternalCredentials;
}) => {
  const { t } = useTranslation('fields');
  return (
    <>
      <Row
        title={t('ibanOrAccountNumber')}
        description={credentials.ibanOrAccountNumber}
      />
    </>
  );
};
const CryptoCredentials = ({
  credentials,
}: {
  credentials: TCryptoCredentials;
}) => {
  const { t } = useTranslation('fields');
  return (
    <>
      <Row title={t('walletId')} description={credentials.walletId} />
      <Row title={t('network')} description={credentials.network} />
      <Row title={t('memo')} description={credentials.memo} />
    </>
  );
};
