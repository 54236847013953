import { TAccountDto } from '@payler/api/client-office';
import { TExchangeDropdownOption } from '../../components/ExchangeDropdown/ExchangeDropdownOption';
import { getCurrencyIcon } from '../../currency';

export const getExchangeFilteredReceiverAccountsByAccount = (
  account: TAccountDto | undefined,
  accounts: TAccountDto[] | null,
) => {
  if (!account || !accounts) {
    return [];
  }
  return accounts.filter(
    (item) =>
      item.currency.toLowerCase() !== account.currency.toLowerCase() &&
      item.baasProvider.id === account.baasProvider.id &&
      item.allowIncomingOperations,
  );
};

export const removeThousandSeparatorsAndSuffix = (value: string) =>
  value.replace(/\s/g, '').replace(/[^\d.-]/g, '');

export const getExchangeAccountOptionByAccount = (
  account: TAccountDto,
  formatNumber: (value: number | bigint) => string,
) => {
  return {
    value: account.id,
    label: account.currency.toUpperCase(),
    icon: getCurrencyIcon(account.currency),
    caption: formatNumber(account.amount),
  };
};

export const getExchangeAccountsOptions = (
  accounts: TAccountDto[] | undefined,
  formatNumber: (value: number | bigint) => string,
): TExchangeDropdownOption[] | undefined => {
  return accounts?.map((account) => ({
    value: account.id,
    label: account.currency.toUpperCase(),
    icon: getCurrencyIcon(account.currency),
    caption: formatNumber(account.amount),
  }));
};

export const calculateRateWithCommission = (
  rate: number,
  percentFee: number,
): number => {
  const commission = rate * percentFee;
  const rateWithCommission = Number((rate - commission).toFixed(4));
  return rateWithCommission;
};

export const isAccountCanFx = (account: TAccountDto | undefined) =>
  !!account?.baasProvider.products.find((product) => product === 'fx');
