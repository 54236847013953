import React from 'react';
import { Flex } from '@chakra-ui/react';
import { SpinnerProps } from '@chakra-ui/spinner';
import { BankLoader } from '../BankLoader/BankLoader';
import { CardContent } from '@payler/ui-components';

export const LoaderBox: React.FC<
  SpinnerProps & { variant?: 'table' | 'default' }
> = ({ variant, ...rest }) => {
  const Component = (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      minHeight="100%"
      flex="1"
      flexDirection="column"
      p={4}
      {...rest}
    >
      <BankLoader />
    </Flex>
  );
  switch (variant) {
    case 'table':
      return (
        <CardContent
          p={0}
          bg="secondary.500"
          borderRadius="md"
          border="0.5px solid"
          borderColor="primary.100"
          overflow="hidden"
          width="100%"
          height="100%"
          {...rest}
        >
          {Component}
        </CardContent>
      );
    default:
      return Component;
  }
};
