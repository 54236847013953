import { ComponentType } from 'react';

export const WHITELABELED_COMPONENTS_DEFAULT_MAPPING = {
  // addRecipientWizard: AddRecipientWizard,
} as const;

type TWhiteLabeledComponent =
  keyof typeof WHITELABELED_COMPONENTS_DEFAULT_MAPPING;

export type TWhiteLabeledComponents = {
  [K in TWhiteLabeledComponent]: (typeof WHITELABELED_COMPONENTS_DEFAULT_MAPPING)[K] extends ComponentType<
    infer P
  >
    ? ComponentType<P>
    : never;
};
