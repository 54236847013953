import {
  TFxTransferDto,
  TIncomingTransferDto,
  TOutgoingTransferDto,
  TTransferDto,
} from '../types';

export const mockOutgoingTransfers: TOutgoingTransferDto[] = [
  {
    id: '4',
    created: '2022-09-22T04:30:22.062293+00:00',
    modified: '2022-09-22T04:30:22.062293+00:00',
    clientId: '1',
    operationType: 'outgoing',
    reason: null,
    senderSide: {
      amount: 15550,
      feeAmount: 20,
      totalAmount: 15570,
      feeAllocation: null,
      currency: 'USD',
    },
    sender: {
      accountId: '714970e5-8987-4d83-b069-03710d2a6bbe',
      clientId: '0bd50c70-2d5b-4a32-ae73-30733f29218b',
      credentials: {
        paymentMethod: 'internal',
        ibanOrAccountNumber: '2010000000013EUR',
      },
      legalEntity: {
        type: 'individual',
        firstName: 'Amy',
        lastName: 'Winehouse',
        address: {
          country: 'GB',
          postalCode: 'NW59 6OU',
          city: 'London',
          street: '9683 North Street',
        },
      },
    },
    state: 'technicalProblem',
    system: 'sepa',
    description: 'Outgoing transfer',
    documents: [
      {
        name: 'account_statements (2).pdf',
      },
    ],
    recipient: {
      accountId: '714970e5-8987-4d83-b069-03710d2a6bbe',
      clientId: '0bd50c70-2d5b-4a32-ae73-30733f29218b',
      credentials: {
        paymentMethod: 'internal',
        ibanOrAccountNumber: '2010000000013EUR',
      },
      legalEntity: {
        type: 'individual',
        firstName: 'Amy',
        lastName: 'Winehouse',
        address: {
          country: 'GB',
          postalCode: 'NW59 6OU',
          city: 'London',
          street: '9683 North Street',
        },
      },
    },
  },
  
];

export const mockIncomingTransfers: TIncomingTransferDto[] = [
  {
    id: '4',
    created: '2022-09-22T04:30:22.062293+00:00',
    modified: '2022-09-22T04:30:22.062293+00:00',
    clientId: '1',
    operationType: 'outgoing',
    reason: null,
    recipientSide: {
      amount: 15550,
      feeAmount: 20,
      totalAmount: 15570,
      feeAllocation: null,
      currency: 'USD',
    },
    sender: {
      accountId: '714970e5-8987-4d83-b069-03710d2a6bbe',
      clientId: '0bd50c70-2d5b-4a32-ae73-30733f29218b',
      credentials: {
        paymentMethod: 'internal',
        ibanOrAccountNumber: '2010000000013EUR',
      },
      legalEntity: {
        type: 'individual',
        firstName: 'Amy',
        lastName: 'Winehouse',
        address: {
          country: 'GB',
          postalCode: 'NW59 6OU',
          city: 'London',
          street: '9683 North Street',
        },
      },
    },
    state: 'technicalProblem',
    system: 'sepa',
    description: 'Outgoing transfer',
    recipient: {
      accountId: '714970e5-8987-4d83-b069-03710d2a6bbe',
      clientId: '0bd50c70-2d5b-4a32-ae73-30733f29218b',
      credentials: {
        paymentMethod: 'internal',
        ibanOrAccountNumber: '2010000000013EUR',
      },
      legalEntity: {
        type: 'individual',
        firstName: 'Amy',
        lastName: 'Winehouse',
        address: {
          country: 'GB',
          postalCode: 'NW59 6OU',
          city: 'London',
          street: '9683 North Street',
        },
      },
    },
  },
];

export const mockFxTransfers: TFxTransferDto[] = [
  {
    id: '4',
    created: '2022-09-22T04:30:22.062293+00:00',
    modified: '2022-09-22T04:30:22.062293+00:00',
    clientId: '1',
    operationType: 'outgoing',
    reason: null,
    senderSide: {
      amount: 15550,
      feeAmount: 20,
      totalAmount: 15570,
      feeAllocation: null,
      currency: 'USD',
    },
    sender: {
      accountId: '714970e5-8987-4d83-b069-03710d2a6bbe',
      clientId: '0bd50c70-2d5b-4a32-ae73-30733f29218b',
      credentials: {
        paymentMethod: 'internal',
        ibanOrAccountNumber: '2010000000013EUR',
      },
      legalEntity: {
        type: 'individual',
        firstName: 'Amy',
        lastName: 'Winehouse',
        address: {
          country: 'GB',
          postalCode: 'NW59 6OU',
          city: 'London',
          street: '9683 North Street',
        },
      },
    },
    state: 'technicalProblem',
    recipient: {
      accountId: '714970e5-8987-4d83-b069-03710d2a6bbe',
      clientId: '0bd50c70-2d5b-4a32-ae73-30733f29218b',
      credentials: {
        paymentMethod: 'internal',
        ibanOrAccountNumber: '2010000000013EUR',
      },
      legalEntity: {
        type: 'individual',
        firstName: 'Amy',
        lastName: 'Winehouse',
        address: {
          country: 'GB',
          postalCode: 'NW59 6OU',
          city: 'London',
          street: '9683 North Street',
        },
      },
    },
    recipientSide: {
      amount: 15550,
      feeAmount: 20,
      totalAmount: 15570,
      feeAllocation: null,
      currency: 'USD',
    },
    exchangeRate: 0.6,
  },
];

export const mockTransfers: TTransferDto[] = [
  {
    outgoing: null,
    incoming: null,
    fx: mockFxTransfers[0],
    id: '1',
    created: '2022-09-22T05:30:21.062293+00:00',
    modified: '2022-09-22T05:30:21.062293+00:00',
  },
  {
    outgoing: null,
    incoming: null,
    fx: mockFxTransfers[1],
    id: '2',
    created: '2022-09-22T05:30:21.062293+00:00',
    modified: '2022-09-22T05:30:21.062293+00:00',
  },
  {
    outgoing: null,
    incoming: mockIncomingTransfers[0],
    fx: null,
    id: '3',
    created: '2022-09-22T05:20:21.062293+00:00',
    modified: '2022-09-22T05:20:21.062293+00:00',
  },
  {
    outgoing: mockOutgoingTransfers[0],
    incoming: null,
    fx: null,
    id: '4',
    created: '2022-09-22T04:30:22.062293+00:00',
    modified: '2022-09-22T04:30:22.062293+00:00',
  },
  {
    outgoing: mockOutgoingTransfers[1],
    incoming: null,
    fx: null,
    id: '5',
    created: '2022-09-22T03:35:00.062293+00:00',
    modified: '2022-09-22T03:35:00.062293+00:00',
  },
  {
    outgoing: null,
    incoming: null,
    fx: mockFxTransfers[2],
    id: '6',
    created: '2022-09-22T03:30:00.062293+00:00',
    modified: '2022-09-22T03:30:00.062293+00:00',
  },
  {
    outgoing: null,
    incoming: null,
    fx: mockFxTransfers[3],
    id: '7',
    created: '2022-09-22T03:30:00.062293+00:00',
    modified: '2022-09-22T03:30:00.062293+00:00',
  },
  {
    outgoing: mockOutgoingTransfers[2],
    incoming: null,
    fx: null,
    id: '8',
    created: '2022-09-22T03:35:00.062293+00:00',
    modified: '2022-09-22T03:35:00.062293+00:00',
  },
];
