import React, { PropsWithChildren, FC, useMemo } from 'react';
import {
  DrawerBody,
  DrawerHeader,
  HStack,
  VStack,
  Text,
  Box,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { NoData, ThinDivider } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import { BankDrawer } from '../../components/BankDrawer/BankDrawer';
import { BankDrawerContent } from '../../components/BankDrawer/BankDrawerContent';
import { LoaderBox } from '@payler/ui/client-office';
import {
  useSetIncomingTransferIdToUrl,
  useIncomingTransferIdFromUrl,
  useIncomingTransferByUrl,
} from '../../hooks/transfers/queries';
import { useLanguageFeatures } from '@payler/ui/client-office';
import { useTranslation } from 'react-i18next';
import { CrossIcon } from '@payler/ui-icons';
import { InfoItem } from '../helpers/InfoItem';
import { TransferStatementButton } from '../../components/TransferStatementButton/TransferStatementButton';
import { SelfTransferAccountInfo } from '../helpers/SelfTransferAccountInfo';
import { isNil } from 'lodash';
import { useParticipantBankInfo } from '../../hooks/use-participant-bank-info';
import { ListIcon } from '../../components/ListIcon/ListIcon';
import { extractPaymentFields } from '../../helpers/extract-payment-fields';
import { getLegalEntityName } from '../../helpers/getLegalEntityName';

export const IncomingTransferInfoDrawer = () => {
  const incomingTransferId = useIncomingTransferIdFromUrl();
  const isOpen = !!incomingTransferId;
  const setIncomingTransferId = useSetIncomingTransferIdToUrl();

  return (
    <BankDrawer isOpen={isOpen} onClose={setIncomingTransferId}>
      <BankDrawerContent drawerId="incoming-transfer-info-drawer">
        <React.Suspense fallback={<LoaderBox />}>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <ThinDivider />
          <Body />
        </React.Suspense>
      </BankDrawerContent>
    </BankDrawer>
  );
};

const HeaderWrapper: FC<PropsWithChildren> = ({ children }) => {
  const incomingTransferId = useIncomingTransferIdFromUrl();
  const setIncomingTransferId = useSetIncomingTransferIdToUrl();

  return (
    <DrawerHeader p={3}>
      <HStack spacing={2} justifyContent="space-between">
        {children}
        <HStack spacing={2}>
          {incomingTransferId && (
            <TransferStatementButton transferId={incomingTransferId} />
          )}
          <IconButton
            icon={<CrossIcon />}
            aria-label="close"
            variant="secondary"
            w={6}
            h={6}
            onClick={() => {
              setIncomingTransferId();
            }}
            data-testid="button_drawer-close"
          />
        </HStack>
      </HStack>
    </DrawerHeader>
  );
};

const Header = () => {
  const { t } = useTranslation('transfers');
  const { serverDatetimeToDisplay } = useLanguageFeatures();
  const transfer = useIncomingTransferByUrl();

  const isSelfTransfer = transfer?.operationType === 'self';

  if (!transfer) {
    return <Box />;
  }

  return (
    <HStack spacing={2}>
      <Flex alignItems="center" position="relative">
        <ListIcon
          status={transfer.state}
          type="incoming"
          isSelfTransfer={isSelfTransfer}
        />
      </Flex>
      <VStack spacing={0} alignItems="baseline">
        <Text
          textStyle={TextStyles.h4}
          color="primary.500"
          maxW="200px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {isSelfTransfer
            ? t('transferToSelf')
            : getLegalEntityName(transfer.sender.legalEntity)}
        </Text>
        <Text textStyle={TextStyles.Subtitle14Regular} color="primary.350">
          {serverDatetimeToDisplay(transfer?.created, 'datetime')}
        </Text>
      </VStack>
    </HStack>
  );
};

const Body = () => {
  const { t } = useTranslation('transfers');
  const transfer = useIncomingTransferByUrl();
  const { formatAmountByCurrency, serverDatetimeToDisplay } =
    useLanguageFeatures();

  const isSelfTransfer = transfer?.operationType === 'self';

  const { label: bankInfoLabel, value: bankInfoValue } = useParticipantBankInfo(
    extractPaymentFields(transfer?.recipient?.credentials),
  );

  if (!transfer) {
    return <NoData />;
  }

  const isIban =
    extractPaymentFields(transfer.sender.credentials, ['iban']).iban ||
    extractPaymentFields(transfer.sender.credentials, ['iban'])
      .ibanOrAccountNumber ||
    undefined;

  return (
    <DrawerBody p={0}>
      <VStack spacing={0} alignItems="stretch" divider={<ThinDivider />}>
        <VStack spacing={2} alignItems="stretch" p={3}>
          {isSelfTransfer ? (
            <SelfTransferAccountInfo
              senderAccountNumber={isIban}
              receiverAccountNumber={isIban}
            />
          ) : (
            <>
              <Text as="h4" textStyle={TextStyles.h4}>
                {t('senderInfo')}
              </Text>
              <InfoItem
                label={t('sender')}
                value={getLegalEntityName(transfer.sender?.legalEntity)}
              />
              <InfoItem
                label={t('senderCountry')}
                value={transfer.sender?.legalEntity?.address.country}
              />
              <InfoItem label={t('iban')} value={isIban} />
              <InfoItem label={t('accountNumber')} value={isIban} />
              {transfer.sender.credentials.paymentMethod === 'fasterUK' && (
                <>
                  <InfoItem
                    label={t('ukAccountNumber')}
                    value={transfer.sender.credentials.accountNumber}
                  />
                  <InfoItem
                    label={t('ukSortCode')}
                    value={transfer.sender.credentials.sortCode}
                  />
                </>
              )}
              <InfoItem label={bankInfoLabel} value={bankInfoValue} />
            </>
          )}
        </VStack>
        <VStack spacing={2} alignItems="stretch" p={3}>
          <Text as="h4" textStyle={TextStyles.h4}>
            {t('transferInfo')}
          </Text>
          <InfoItem
            label={t('date')}
            value={serverDatetimeToDisplay(transfer?.created, 'datetime')}
          />
          <InfoItem label={t('transferId')} value={transfer.id} />
          <InfoItem
            label={t('amount')}
            value={formatAmountByCurrency(
              transfer.recipientSide.amount || 0,
              transfer.recipientSide.currency,
            )}
          />
          {!isSelfTransfer && (
            <>
              {!isNil(transfer.recipientSide.feeAmount) && (
                <InfoItem
                  label={t('fee')}
                  value={formatAmountByCurrency(
                    transfer.recipientSide.feeAmount,
                    transfer.recipientSide.currency,
                  )}
                />
              )}
              {!isNil(transfer.recipientSide.totalAmount) && (
                <InfoItem
                  label={t('totalAmount')}
                  value={formatAmountByCurrency(
                    transfer.recipientSide.totalAmount,
                    transfer.recipientSide.currency,
                  )}
                />
              )}
              <InfoItem
                label={t('system')}
                value={t(`mappedSystems.${transfer.system}`)}
              />
              <InfoItem label={t('description')} value={transfer.description} />
            </>
          )}
          <InfoItem
            label={t('status')}
            value={t(`statuses.${transfer.state}`)}
          />
        </VStack>
      </VStack>
    </DrawerBody>
  );
};
