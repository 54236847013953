import {
  Box,
  VStack,
  Text,
  Button,
  HStack,
  Stack,
  Icon,
  Badge,
  IconButton,
} from '@chakra-ui/react';
import { Close16Icon, FilterIcon } from '@payler/ui-icons';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { FxTransferInfoDrawer } from '../../drawers/FxTransferInfoDrawer/FxTransferInfoDrawer';
import { IncomingTransferInfoDrawer } from '../../drawers/IncomingTransferInfoDrawer/IncomingTransferInfoDrawer';
import { OutgoingTransferInfoDrawer } from '../../drawers/OutgoingTransferInfoDrawer/OutgoingTransferInfoDrawer';
import { useTransferFiltersDrawer } from '../../drawers/TransferFiltersDrawer/transfer-filters-state';
import { TransferFiltersDrawer } from '../../drawers/TransferFiltersDrawer/TransferFiltersDrawer';
import {
  useSelectedTransfersFiltersCount,
  useTransfersQueryParamsFromUrl,
} from '../../hooks/transfers/url';
import { useBankBreakpointValue } from '../../hooks/use-bank-breakpoint-value';
import { ContentLayout } from '../../layouts/ContentLayout/ContentLayout';
import { ActivitiesTable } from '../../tables/ActivitiesTable/ActivitiesTable';
import { useCallback } from 'react';
import { deleteTransferIdsFromUrl } from '../../hooks/transfers/helpers';
import { useSearchParams } from 'react-router-dom';
import { TTransferFiltersUrlParams } from '@payler/api/client-office';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import { useAccountsQuery } from '../../hooks/accounts/queries';
import { isArray } from 'lodash';
import { TransferModal } from '../../modals/TransferModal/TransferModal';

type TTransferFiltersKey = keyof TTransferFiltersUrlParams;

export const ActivityPage = () => {
  const isMobile = useBankBreakpointValue({ base: true, md: false });
  return (
    <ContentLayout>
      <HStack spacing={isMobile ? 2 : 3} justifyContent="space-between" mb={3}>
        <Header />
        <Filters />
      </HStack>
      <VStack spacing={isMobile ? 2 : 3} alignItems="start">
        <Badges />
        <ActivitiesTable />
      </VStack>
      <TransferFiltersDrawer />
      <IncomingTransferInfoDrawer />
      <OutgoingTransferInfoDrawer />
      <FxTransferInfoDrawer />
      <TransferModal />
    </ContentLayout>
  );
};

const Badges = () => {
  const filters = useTransfersQueryParamsFromUrl();
  const filtersList = Object.entries(filters).filter(
    ([key, value]) => value !== undefined && key !== "Type",
  );
  if (!filtersList.length) return null;

  return (
    <HStack w="100%" flexWrap="wrap" gap={1}>
      {filtersList.map(([key, value]) => {
        if (isArray(value)) {
          return value.map((valueItem) => (
            <FilterBadge
              key={`${key}.${valueItem}`}
              filterKey={key as TTransferFiltersKey}
              filterValue={valueItem}
            />
          ));
        } else {
          return (
            <FilterBadge
              key={key}
              filterKey={key as TTransferFiltersKey}
              filterValue={value}
            />
          );
        }
      })}
    </HStack>
  );
};

const FilterBadge = ({
  filterKey,
  filterValue,
}: {
  filterKey: TTransferFiltersKey;
  filterValue: string;
}) => {
  const { t } = useTranslation(['fields']);
  const [params, setParams] = useSearchParams();
  const { serverDateToUiDate } = useLanguageFeatures();
  const { data: accounts } = useAccountsQuery();

  const defaultHandleClose = () => {
    params.delete(filterKey);
    setParams(params);
  };

  let resultingValue;
  let handleClose;
  switch (filterKey) {
    case 'CreatedFrom':
    case 'CreatedTo':
      resultingValue = serverDateToUiDate(filterValue);
      handleClose = defaultHandleClose;
      break;
    case 'AccountId':
      const selectedAccount = accounts?.find((acc) => acc.id === filterValue);
      resultingValue = selectedAccount?.name;
      handleClose = defaultHandleClose;
      break;
    case 'States':
      resultingValue = filterValue;
      handleClose = () => {
        params.delete(filterKey, filterValue);
        setParams(params);
      };
      break;
    default:
      handleClose = defaultHandleClose;
      resultingValue = filterValue;
  }

  const badgeText = `${t(`fields:paramsMapping.${filterKey}`)}: ${resultingValue}`;

  return (
    <Badge variant="primary" size="md">
      <HStack>
        <Text textStyle={TextStyles.Caption12Medium} color="primary.500">
          {badgeText}
        </Text>
        <IconButton
          variant="primary"
          icon={<Icon as={Close16Icon} />}
          aria-label={`${filterKey}.close`}
          w="14px"
          h="14px"
          onClick={handleClose}
        />
      </HStack>
    </Badge>
  );
};
const Filters = () => {
  const [params, setParams] = useSearchParams();
  const { t } = useTranslation(['menu', 'common']);
  const isMobile = useBankBreakpointValue({ base: true, md: false });
  const { open: openFilters } = useTransferFiltersDrawer();
  const filtersCount = useSelectedTransfersFiltersCount();
  const buttonAlign = isMobile ? 'stretch' : 'center';
  const filtersBgColor =
    filtersCount === 0 ? undefined : isMobile ? 'brands.300' : 'primary.100';
  const iconColor = isMobile
    ? 'secondary.500'
    : filtersCount > 0
      ? 'brands.500'
      : 'primary.350';

  const handleOpenFilters = useCallback(() => {
    deleteTransferIdsFromUrl(params, setParams);
    openFilters();
  }, [openFilters, params, setParams]);

  return (
    <HStack spacing={2} alignItems={buttonAlign}>
      {/* TODO: поиск вернём, как только сделают бэк {!isMobile && (
        <Input
          leftElementProps={{
            children: <Search2Icon />,
            pointerEvents: 'none',
            paddingLeft: '12px',
            h: '100%',
            justifyContent: 'start',
          }}
          size="xs"
          placeholder={t('common:search')}
          data-testid="input_search"
          bgColor="primary.50"
        />
      )} */}
      <Button
        variant={isMobile ? 'primary' : 'secondary'}
        onClick={handleOpenFilters}
        border={isMobile ? 'none' : undefined}
        data-testid="button_open-filters"
        px={1.5}
        py={1}
        h={4}
        {...(isMobile && { p: '14px', borderRadius: 'md', h: '100%' })}
      >
        <HStack spacing={1}>
          <Icon
            as={FilterIcon}
            color={iconColor}
            boxSize={isMobile ? '20px' : 'inherit'}
          />
          {!isMobile && (
            <Text textStyle={TextStyles.Buttons12Medium}>
              {t('common:filters')}
            </Text>
          )}
        </HStack>
      </Button>
      {/* Скачивание отчета временно удалено в рамках BANK-1910 */}
    </HStack>
  );
};

const Header = () => {
  const { t } = useTranslation(['menu']);
  return (
    <Box>
      <Text as="h2" textStyle={TextStyles.h2} mb={2} color="primary.500">
        {t('menu:activity')}
      </Text>
    </Box>
  );
};
