import { useEffect } from 'react';
import {
  BankModalBody,
  BankModalTitle,
} from '../../../components/BankModal/BankModal';
import { useTranslation } from 'react-i18next';
import { HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import {
  TTransferDetailsForm,
  useTransferWizardContext,
} from '../TransferWizard';
import { DetailsCard } from '../TransferDetails/TransferDetailsWrapper';
import { getCurrencyIcon } from '../../../currency';
import { assert } from '@payler/utils';
import { useLanguageFeatures } from '../../../hooks/use-language-features';
import { TextStyles } from '@payler/ui-theme';
import { useTransferFeeQuery } from '../../../hooks/transfers/queries';
import { TTransferFeeParams } from '@payler/api/client-office';
import { FloatingInputField, ThinDivider } from '@payler/ui-components';
import { usePermissions } from '../../../hooks/use-permissions';

export const TransferDetailsStep = () => {
  const { t } = useTranslation(['accounts', 'common']);
  const { formatAmountByCurrency } = useLanguageFeatures();
  const { senderAccount, receiverAccount, setTransfersFee } =
    useTransferWizardContext();
  const { isGlobalAccounts } = usePermissions();

  assert(senderAccount && receiverAccount, 'Accounts are not selected');

  const methods = useFormContext<TTransferDetailsForm>();
  const amount = methods.watch('amount');

  const { data: fee, isFetching } = useTransferFeeQuery(
    {
      BaasProvider: senderAccount?.baasProvider.shortName,
      TransferType: 'outgoing',
      Amount: amount,
      System: 'self',
      Currency: senderAccount?.currency.toLowerCase(),
    } as TTransferFeeParams,
    !!amount,
  );

  useEffect(() => {
    setTransfersFee(fee);
  }, [fee, setTransfersFee]);

  return (
    <>
      <BankModalTitle
        title={t('accounts:transferMoney.enterAmount')}
        description={t('accounts:transferMoney.specifyAmount')}
      />
      <BankModalBody w="100%">
        <VStack spacing={2}>
          <VStack spacing={0} w="100%">
            <DetailsCard
              icon={
                <Icon
                  w={5}
                  h={5}
                  as={getCurrencyIcon(senderAccount.currency)}
                />
              }
              title={formatAmountByCurrency(
                senderAccount.amount,
                senderAccount.currency,
              )}
              subtitle={senderAccount.name}
              data-testid="card_sender-account"
              borderBottomRadius={0}
              bgColor="primary.50"
            />
            <ThinDivider />
            <DetailsCard
              icon={
                <Icon
                  w={5}
                  h={5}
                  as={getCurrencyIcon(receiverAccount.currency)}
                />
              }
              title={formatAmountByCurrency(
                receiverAccount.amount,
                receiverAccount.currency,
              )}
              subtitle={receiverAccount.name}
              data-testid="card_receiver-account"
              borderTopRadius={0}
              bgColor="primary.50"
            />
          </VStack>
          <HStack justifyContent="space-between" w="100%">
            <Text textStyle={TextStyles.BodyUI14Semibold} color="primary.500">
              {t('accounts:transferMoney.betweenYourAccounts')}
            </Text>
            <Text textStyle={TextStyles.BodyUI14Semibold} color="primary.500">
              {isFetching
                ? t('common:loading')
                : t('accounts:transferMoney.transferFee', {
                    amount: fee?.feeAmount || 0,
                    currency: senderAccount?.currency.toUpperCase(),
                  })}
            </Text>
          </HStack>
          <FloatingInputField
            name="amount"
            label={t('accounts:transferMoney.amount')}
            variant="currency"
            autoComplete="off"
            currencySuffix={
              amount ? senderAccount?.currency.toUpperCase() : undefined
            }
            allowNegative={false}
            isReadOnly={!isGlobalAccounts}
          />
        </VStack>
      </BankModalBody>
    </>
  );
};
