import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  Tooltip,
  useClipboard,
  VStack,
} from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import {
  BankModal,
  BankModalBody,
  BankModalFooter,
  BankModalTitle,
  ModalHeaderActions,
} from '../../components/BankModal/BankModal';
import { CopyIcon, SettingsFillIcon } from '@payler/ui-icons';
import {
  useAccountByUrl,
  useAccountsQuery,
} from '../../hooks/accounts/queries';
import { Trans, useTranslation } from 'react-i18next';
import {
  useCountdownTimer,
  useRecoilBoolean,
  useRecoilBooleanValue,
} from '@payler/bank-utils';
import { SegmentedPicker, ThinDivider } from '@payler/ui-components';
import { TAccountDto } from '@payler/api/client-office';
import { useCompanyInfo } from '../../config/ConfigProvider';
import { useIsCryptoCurrency } from '../../hooks/use-is-crypto-currency';

const KEY = 'modals:AddMoneyModal';

export const useShowAddMoneyModal = () => useRecoilBoolean(KEY).on;
export const useCloseAddMoneyModal = () => useRecoilBoolean(KEY).off;
export const useIsOpenAddMoneyModal = () => useRecoilBooleanValue(KEY);

type TAddMoneyTypes = 'sepa' | 'swift' | 'fps' | 'internal' | 'blockchain';

const useAddMoneyTypes = (account: TAccountDto) => {
  const isCrypto = useIsCryptoCurrency(account.currency);

  return useMemo(() => {
    const types: TAddMoneyTypes[] = [];
    if (account.sepaCredentials) {
      types.push('sepa');
    }
    if (account.swiftCredentials) {
      types.push('swift');
    }
    if (account.fasterUKCredentials) {
      types.push('fps');
    }
    if (account.internalCredentials || account.internalBaasCredentials) {
      types.push('internal');
    }
    if (isCrypto) {
      types.push('blockchain');
    }

    return types;
  }, [
    account.internalCredentials,
    account.sepaCredentials,
    account.swiftCredentials,
    account.internalBaasCredentials,
    account.fasterUKCredentials,
  ]);
};

const useClipboardRequisites = (
  type: TAddMoneyTypes | undefined,
  account: TAccountDto,
) => {
  const { t } = useTranslation(['accounts']);

  const getGroupString = useCallback(
    (title: string, value: string | undefined | null) => {
      if (!value) return null;
      return title + '\n' + value + '\n';
    },
    [],
  );

  return useMemo(() => {
    const groups: (string | null)[] = [];

    if (type === 'sepa') {
      groups.push(
        getGroupString(
          t('accounts:topUp.requisitesName.accountNumber'),
          account.sepaCredentials?.bankAccountNumber,
        ),
      );
      groups.push(
        getGroupString(
          t('accounts:topUp.requisitesName.beneficiary'),
          account.sepaCredentials?.beneficiary,
        ),
      );
      groups.push(
        getGroupString(
          t('accounts:topUp.requisitesName.intermediaryBank'),
          account.sepaCredentials?.bankName,
        ),
      );
      groups.push(
        getGroupString(
          t('accounts:topUp.requisitesName.iban'),
          account.sepaCredentials?.iban,
        ),
      );
      groups.push(
        getGroupString(
          t('accounts:topUp.requisitesName.bic'),
          account.sepaCredentials?.bic,
        ),
      );
    } else if (type === 'fps') {
      groups.push(
        getGroupString(
          t('accounts:topUp.requisitesName.beneficiary'),
          account.fasterUKCredentials?.beneficiary,
        ),
      );
      groups.push(
        getGroupString(
          t('accounts:topUp.requisitesName.intermediaryBank'),
          account.fasterUKCredentials?.bankName,
        ),
      );
      groups.push(
        getGroupString(
          t('accounts:topUp.requisitesName.ukAccountNumber'),
          account.fasterUKCredentials?.ukAccountNumber,
        ),
      );
      groups.push(
        getGroupString(
          t('accounts:topUp.requisitesName.ukSortCode'),
          account.fasterUKCredentials?.ukSortCode,
        ),
      );
    } else if (type === 'swift') {
      groups.push(
        getGroupString(
          t('accounts:topUp.requisitesName.accountNumber'),
          account.number,
        ),
      );
      groups.push(
        getGroupString(
          t('accounts:topUp.requisitesName.beneficiary'),
          account.swiftCredentials?.beneficiary,
        ),
      );
      groups.push(
        getGroupString(
          t('accounts:topUp.requisitesName.intermediaryBank'),
          account.swiftCredentials?.bankName,
        ),
      );
      groups.push(
        getGroupString(
          t('accounts:topUp.requisitesName.iban'),
          account.swiftCredentials?.iban,
        ),
      );
      groups.push(
        getGroupString(
          t('accounts:topUp.requisitesName.bic'),
          account.swiftCredentials?.bic,
        ),
      );
      groups.push(
        getGroupString(
          t('accounts:topUp.requisitesName.address'),
          account.swiftCredentials?.bankAddress,
        ),
      );
    } else if (type === 'internal') {
      if (account.internalCredentials) {
        groups.push(
          getGroupString(
            t('accounts:topUp.requisitesName.accountNumber'),
            account.internalCredentials?.bankAccountNumber,
          ),
        );
      } else if (account.internalBaasCredentials) {
        groups.push(
          getGroupString(
            t('accounts:topUp.requisitesName.iban'),
            account.internalBaasCredentials?.iban,
          ),
        );
        groups.push(
          getGroupString(
            t('accounts:topUp.requisitesName.accountNumber'),
            account.internalBaasCredentials?.bankAccountNumber,
          ),
        );
      }
    }

    return groups.filter((item) => !!item).join('\n');
  }, [
    account.internalCredentials,
    account.sepaCredentials,
    account.swiftCredentials,
    getGroupString,
    t,
    type,
  ]);
};

export const AddMoneyModal: React.FC = () => {
  const { t } = useTranslation(['accounts']);
  const open = useIsOpenAddMoneyModal();
  const account = useAccountByUrl();
  const closeModal = useCloseAddMoneyModal();

  if (!account) {
    return (
      <BankModal close={closeModal} open={open}>
        <BankModalTitle title={t('accounts:topUp.title')} />
        <BankModalBody>
          <Box width="100%">
            <Text textStyle={TextStyles.BodyText16Medium} color="primary.500">
              {t('accounts:topUp.noData')}
            </Text>
          </Box>
        </BankModalBody>
      </BankModal>
    );
  }

  return (
    <BankModal close={closeModal} open={open}>
      <AddMoneyModalContent account={account} />
    </BankModal>
  );
};

const AddMoneyModalContent = ({ account }: { account: TAccountDto }) => {
  const { t } = useTranslation(['accounts', 'common']);
  const { hasCopied, onCopy, setValue } = useClipboard('');
  const typeList = useAddMoneyTypes(account);
  const [type, setType] = useState<TAddMoneyTypes | undefined>(typeList[0]);
  const clipboardRequisites = useClipboardRequisites(type, account);
  const { companyName } = useCompanyInfo();

  useEffect(() => {
    setValue(clipboardRequisites);
  }, [clipboardRequisites, setValue]);

  const handleCopyAll = () => {
    onCopy();
  };

  return (
    <>
      <ModalHeaderActions isShowBack={false} />
      <BankModalTitle
        title={t('accounts:topUp.title')}
        description={t('accounts:topUp.subtitle', { companyName })}
      />
      <BankModalBody>
        <VStack spacing={2} alignItems="start">
          {typeList.length > 1 && type && (
            <ChooseType type={type} setType={setType} typeList={typeList} />
          )}
          {!!type && (
            <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
              <Trans
                i18nKey="accounts:topUp.details"
                ns={['accounts']}
                values={{
                  type: type.toUpperCase(),
                  currency: account.currency.toUpperCase(),
                }}
                components={{
                  bold: (
                    <Text
                      as="span"
                      textStyle={TextStyles.Subtitle14Medium}
                      color="primary.500"
                    />
                  ),
                }}
              />
            </Text>
          )}
          {type === 'internal' && <InternalRequisites account={account} />}
          {type === 'sepa' && <SEPARequisites account={account} />}
          {type === 'swift' && <SWIFTRequisites account={account} />}
          {type === 'fps' && <FPSRequisites account={account} />}
          {type === 'blockchain' && (
            <BlockchainNetworkRequisites account={account} />
          )}
          {!type && <EmptyRequisitesPlaceholder />}
        </VStack>
      </BankModalBody>
      {!!type && (
        <BankModalFooter>
          <Button
            leftIcon={<CopyIcon />}
            variant="primary"
            w="100%"
            onClick={handleCopyAll}
            data-testid="button_copy-all"
          >
            {t(!hasCopied ? 'common:copyAll' : 'common:copied')}
          </Button>
        </BankModalFooter>
      )}
    </>
  );
};

const InternalRequisites = ({ account }: { account: TAccountDto }) => {
  const { t } = useTranslation(['accounts']);

  const accountNumber =
    account.internalCredentials?.bankAccountNumber ||
    account.internalBaasCredentials?.bankAccountNumber;

  return (
    <VStack spacing={2} w="full" divider={<ThinDivider />}>
      {accountNumber && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.accountNumber')}
          value={accountNumber}
        />
      )}
      {account.internalBaasCredentials?.iban && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.iban')}
          value={account.internalBaasCredentials.iban}
        />
      )}
    </VStack>
  );
};

const SEPARequisites = ({ account }: { account: TAccountDto }) => {
  const { t } = useTranslation(['accounts']);

  return (
    <VStack spacing={2} w="full" divider={<ThinDivider />}>
      {account.sepaCredentials?.bankAccountNumber && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.accountNumber')}
          value={account.sepaCredentials.bankAccountNumber}
        />
      )}
      {account.sepaCredentials?.beneficiary && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.beneficiary')}
          value={account.sepaCredentials.beneficiary}
        />
      )}
      {account.sepaCredentials?.bankName && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.intermediaryBank')}
          value={account.sepaCredentials.bankName}
        />
      )}
      {account.sepaCredentials?.iban && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.iban')}
          value={account.sepaCredentials.iban}
        />
      )}
      {account.sepaCredentials?.bic && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.bic')}
          value={account.sepaCredentials.bic}
        />
      )}
    </VStack>
  );
};

const SWIFTRequisites = ({ account }: { account: TAccountDto }) => {
  const { t } = useTranslation(['accounts']);

  return (
    <VStack spacing={2} w="full" divider={<ThinDivider />}>
      {account.swiftCredentials?.bankAccountNumber && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.accountNumber')}
          value={account.swiftCredentials.bankAccountNumber}
        />
      )}
      {account.swiftCredentials?.beneficiary && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.beneficiary')}
          value={account.swiftCredentials.beneficiary}
        />
      )}
      {account.swiftCredentials?.bankName && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.intermediaryBank')}
          value={account.swiftCredentials.bankName}
        />
      )}
      {account.swiftCredentials?.iban && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.iban')}
          value={account.swiftCredentials.iban}
        />
      )}
      {account.swiftCredentials?.bic && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.bic')}
          value={account.swiftCredentials.bic}
        />
      )}
      {account.swiftCredentials?.bankAddress && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.address')}
          value={account.swiftCredentials.bankAddress}
        />
      )}
    </VStack>
  );
};
const FPSRequisites = ({ account }: { account: TAccountDto }) => {
  const { t } = useTranslation(['accounts']);

  return (
    <VStack spacing={2} w="full" divider={<ThinDivider />}>
      {account.fasterUKCredentials?.beneficiary && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.beneficiary')}
          value={account.fasterUKCredentials.beneficiary}
        />
      )}
      {account.fasterUKCredentials?.bankName && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.intermediaryBank')}
          value={account.fasterUKCredentials.bankName}
        />
      )}
      {account.fasterUKCredentials?.ukAccountNumber && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.ukAccountNumber')}
          value={account.fasterUKCredentials.ukAccountNumber}
        />
      )}
      {account.fasterUKCredentials?.ukSortCode && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.ukSortCode')}
          value={account.fasterUKCredentials.ukSortCode}
        />
      )}
    </VStack>
  );
};

const BlockchainNetworkRequisites = ({ account }: { account: TAccountDto }) => {
  const { t } = useTranslation(['accounts']);
  const network = account.blockchainNetworkCredentials?.network;
  const walletAddress = account.blockchainNetworkCredentials?.walletAddress;
  return (
    <VStack spacing={2} w="full" divider={<ThinDivider />}>
      {network && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.network')}
          value={network}
        />
      )}
      {walletAddress && (
        <RequisiteRow
          title={t('accounts:topUp.requisitesName.walletAddress')}
          value={walletAddress}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          maxWidth="300px"
        />
      )}
    </VStack>
  );
};

export const EmptyRequisitesPlaceholder = () => {
  const { t } = useTranslation(['accounts']);
  const { remainingTime, resetTimer } = useCountdownTimer(10_000);
  const { refetch } = useAccountsQuery();

  // refetch реквизитов через 10 секунд
  useEffect(() => {
    if (remainingTime === 0) {
      refetch().then(() => {
        resetTimer();
      });
    }
  }, [remainingTime, refetch, resetTimer]);

  return (
    <VStack
      px={4}
      py={5}
      spacing={3}
      bg="primary.25"
      borderRadius="md"
      w="full"
    >
      <Icon
        as={SettingsFillIcon}
        w={8}
        h={8}
        alignSelf="center"
        color="brands.500"
      />
      <VStack p={0} spacing={0}>
        <Text textStyle={TextStyles.BodyText16Semibold} color="primary.350">
          {t('accounts:topUp.emptyRequisites.message')}
        </Text>
        <Text textStyle={TextStyles.Subtitle14Regular} color="primary.300">
          {t('accounts:topUp.emptyRequisites.description')}
        </Text>
      </VStack>
    </VStack>
  );
};

const RequisiteRow: React.FC<{
  title: string;
  value: string;
  overflow?: CSSProperties['overflow'];
  textOverflow?: CSSProperties['textOverflow'];
  whiteSpace?: CSSProperties['whiteSpace'];
  maxWidth?: CSSProperties['maxWidth'];
}> = ({ title, value, ...rest }) => {
  const { t } = useTranslation(['common']);
  const { hasCopied, onCopy } = useClipboard(value);

  return (
    <Flex justifyContent="space-between" width="100%">
      <Box>
        <Text textStyle={TextStyles.Caption12Regular} color="primary.350">
          {title}
        </Text>
        <Text textStyle={TextStyles.BodyUI16Medium} color="primary.500"  {...rest}>
          {value}
        </Text>
      </Box>
      <Flex alignItems="center">
        <Tooltip
          isOpen={hasCopied || undefined}
          label={hasCopied ? t('common:copied') : t('common:clickToCopy')}
          hasArrow
          placement="top"
        >
          <Icon
            as={CopyIcon}
            w="20px"
            h="20px"
            color="primary.300"
            _hover={{ color: 'primary.350', cursor: 'pointer' }}
            onClick={onCopy}
            data-testid="button_copy-requisite"
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};

const ChooseType: FC<{
  type: TAddMoneyTypes;
  setType: React.Dispatch<React.SetStateAction<TAddMoneyTypes | undefined>>;
  typeList: TAddMoneyTypes[];
}> = ({ type, setType, typeList }) => {
  const { t } = useTranslation(['accounts']);
  const localizedOptions = useMemo(
    () => typeList.map((item) => t(`accounts:topUp.requisitesType.${item}`)),
    [typeList, t],
  );

  return (
    <Box w="full">
      <SegmentedPicker
        size="lg"
        options={localizedOptions}
        onSelectionChange={(idx: number) => {
          setType(typeList[idx]);
        }}
        selection={typeList.indexOf(type)}
        isFullWidth
      />
    </Box>
  );
};
