/**
 * Цвета для использования в @emotion
 */
export const emotionColors = {
  primary25: 'var(--chakra-colors-primary-25)',
  primary50: 'var(--chakra-colors-primary-50)',
  primary100: 'var(--chakra-colors-primary-100)',
  primary200: 'var(--chakra-colors-primary-200)',
  primary300: 'var(--chakra-colors-primary-300)',
  primary350: 'var(--chakra-colors-primary-350)',
  primary400: 'var(--chakra-colors-primary-400)',
  primary500: 'var(--chakra-colors-primary-500)',

  secondary25: 'var(--chakra-colors-secondary-25)',
  secondary50: 'var(--chakra-colors-secondary-50)',
  secondary100: 'var(--chakra-colors-secondary-100)',
  secondary200: 'var(--chakra-colors-secondary-200)',
  secondary300: 'var(--chakra-colors-secondary-300)',
  secondary350: 'var(--chakra-colors-secondary-350)',
  secondary400: 'var(--chakra-colors-secondary-400)',
  secondary500: 'var(--chakra-colors-secondary-500)',

  bg: 'var(--chakra-colors-bg)',
  menuBg: 'var(--chakra-colors-menuBG)',

  brands100: 'var(--chakra-colors-brands-100)',
  brands300: 'var(--chakra-colors-brands-300)',
  brands500: 'var(--chakra-colors-brands-500)',

  red100: 'rgba(223, 41, 53, 0.1)',
  red300: 'rgba(223, 41, 53, 0.32)',
  red500: '#DF2935',

  yellow100: 'rgba(255, 203, 71, 0.1)',
  yellow300: 'rgba(255, 203, 71, 0.32)',
  yellow500: '#FFCB47',

  green100: 'rgba(110, 178, 87, 0.1)',
  green300: 'rgba(110, 178, 87, 0.32)',
  green500: '#6EB257',

  violet100: 'rgba(123, 97, 255, 0.1)',
  violet300: 'rgba(123, 97, 255, 0.32)',
  violet500: '#7B61FF',

  white100: 'rgba(255, 255, 255, 0.1)',
  white300: 'rgba(255, 255, 255, 0.32)',
  white500: '#FFFFFF',

  black100: 'rgba(0, 0, 0, 0.1)',
  black300: 'rgba(0, 0, 0, 0.32)',
  black500: '#000000',

  hover: 'var(--chakra-colors-hover)',
};
