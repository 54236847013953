import { getAppRoutesDefaultConfig } from '@payler/ui/client-office';
import { RouteObject } from 'react-router-dom';
import PaymentLayout from '../layouts/PaymentLayout';
import { SpushPaymentPageLazy } from '../components/lazy-components';

export const getSpushAppRoutesConfig = getAppRoutesDefaultConfig;

export const purchaseRoutesConfig: RouteObject[] = [
  {
    path: '/purchase',
    element: <PaymentLayout />,
    children: [{ path: ':id', element: <SpushPaymentPageLazy /> }],
  },
];
