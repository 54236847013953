export const stockFonts =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

export const fonts = {
  DemiBold: `RobotoTextWebDemiBold, ${stockFonts}`,
  UIRegular: `RobotoUIWebRegular, ${stockFonts}`,
  UIMedium: `RobotoUIWebMedium, ${stockFonts}`,
  Medium: `RobotoTextWebMedium, ${stockFonts}`,
  Regular: `RobotoTextWebRegular, ${stockFonts}`,
  UIBold: `RobotoUIWebBold, ${stockFonts}`,
  Code: `Roboto Mono, ${stockFonts}`,
  body: `RobotoTextWebRegular, ${stockFonts}`,
  code: `RobotoMono, ${stockFonts}`,
};
