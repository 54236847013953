import { ComponentStyleConfig } from '@chakra-ui/react';
import { TextStyles } from '../textStyles';

export const ButtonStyles: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'xl',
    border: '1px solid',
    fontWeight: 500,
  },
  sizes: {
    default: {
      textStyle: TextStyles.Buttons16Medium,
      px: 3,
      py: 2,
      height: 7,
    },
    small: {
      textStyle: TextStyles.Buttons16Medium,
      px: 2,
      py: 1.5,
      height: 6,
    },
    xs: {
      textStyle: TextStyles.Buttons12Small,
      borderRadius: 0.5,
      px: 1.5,
      py: 0.5,
      height: '30px',
    },
    sm: {
      textStyle: TextStyles.Subtitle14Medium,
      px: 1.5,
      py: 0.75,
      height: 5,
    },
  },
  variants: {
    link: {
      border: 'none',
      p: 0,
      color: 'brands.500',
      cursor: 'pointer',
    },
    primary: {
      borderColor: 'primary.50',
      bg: 'brands.500',
      color: 'buttonText',
      _hover: {
        bg: 'brands.hover',
        _disabled: {
          bg: 'brands.300',
          color: 'buttonText',
        },
      },
      _disabled: {
        bg: 'brands.300',
        color: 'buttonText',
      },
    },
    outlined: {
      borderColor: 'brands.100',
      bg: 'brands.100',
      color: 'brands.500',
      _hover: {
        bg: 'brands.hover',
        color: 'white.500',
        _disabled: {
          bg: 'brands.300',
          color: 'white.500',
        },
      },
      _disabled: {
        bg: 'brands.300',
        color: 'white.500',
        borderColor: 'primary.50',
      },
    },
    red: {
      borderColor: 'primary.50',
      bg: 'red.500',
      color: 'white.500',
      _hover: {
        bg: 'red.400',
        _disabled: {
          bg: 'red.300',
          color: 'white.500',
        },
      },
      _disabled: {
        bg: 'red.300',
        color: 'white.500',
      },
    },
    green: {
      borderColor: 'green.100',
      bg: 'green.100',
      color: 'green.500',
      _hover: {
        color: 'white.500',
        bg: 'green.400',
        _disabled: {
          bg: 'green.300',
          color: 'white.500',
        },
      },
      _disabled: {
        bg: 'green.300',
        color: 'white.500',
      },
    },
    secondary: {
      borderColor: 'primary.50',
      bg: 'primary.50',
      color: 'primary.400',
      _hover: {
        bg: 'primary.100',
      },
      _disabled: {
        color: 'primary.300',
      },
    },
    borderless: {
      bg: 'secondary.500',
      color: 'primary.350',
      border: 'none',
      textStyle: TextStyles.Subtitle14Medium,
      _hover: {
        bg: 'brands.100',
        color: 'brands.500',
        textStyle: TextStyles.Subtitle14Semibold,
      },
    },
    outlinedViolet: {
      borderColor: 'violet.100',
      _disabled: {
        bg: 'brands.300',
        color: 'white.500',
        borderColor: 'primary.50',
      },
      color: 'violet.500',
      bg: 'violet.100',
      _hover: { bg: 'violet.300' },
      _active: { bg: 'violet.300' },
    },
  },
  defaultProps: {
    size: 'default',
    variant: 'primary',
  },
};
