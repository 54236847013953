import Menu, { TMenuLinkItem } from './Menu';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import { createRecoilKey } from '../../state/createKey';
import { useLocation } from 'react-router-dom';
import { FC, useEffect } from 'react';
import { Hamburger, Logo } from '@payler/ui-components';
import { css } from '@emotion/react';
import { DEFAULT_MOBILE_HEADER_HEIGHT } from '../../layouts/BaseLayout/const';
import { Box, HStack } from '@chakra-ui/react';
import { useClientOfficeConfig } from '../../config/ConfigProvider';
import { emotionColors } from '@payler/ui-theme';

export const mobileMenuOpenedAtom = atom({
  key: createRecoilKey('mobileMenuOpenedAtom'),
  default: false,
  effects: [
    ({ onSet }) => {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      onSet((v) => {
        document.body.style.overflow = v ? 'hidden' : originalStyle;
      });
    },
  ],
});

const openStyle = css({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  maxWidth: '300px',
  zIndex: 1000,
});

const overlayStyle = css({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999,
});

export const BaseMobileMenu: FCC = ({ children }) => {
  const location = useLocation();
  const {
    mobileHeaderHeight = DEFAULT_MOBILE_HEADER_HEIGHT,
    staticPath,
    logoFileExtension,
  } = useClientOfficeConfig();
  const hideMenu = useRecoilCallback(
    ({ set }) =>
      () => {
        set(mobileMenuOpenedAtom, false);
      },
    [],
  );
  const toggle = useRecoilCallback(
    ({ set }) =>
      () =>
        set(mobileMenuOpenedAtom, (v) => !v),
    [],
  );
  const menuVisible = useRecoilValue(mobileMenuOpenedAtom);

  useEffect(() => {
    hideMenu();
  }, [hideMenu, location]);

  return (
    <Box
      as="nav"
      bg="bg"
      px="16px"
      borderBottom={`0.5px solid ${emotionColors.primary100}`}
    >
      <HStack
        maxWidth="600px"
        m="0 auto"
        py="10px"
        justifyContent="space-between"
      >
        <Logo m={0} staticPath={staticPath} logoFileExtension={logoFileExtension} />
        <Box
          borderRadius="md"
          px="13px"
          py="14px"
          bg="primary.25"
          borderColor="primary.50"
          borderWidth="1px"
          w={6}
          h={6}
          zIndex={1100}
        >
          <Hamburger
            sx={{ svg: { w: '20px', h: '20px' } }}
            onClick={toggle}
            isOpen={menuVisible}
          />
        </Box>
      </HStack>
      {menuVisible && (
        <>
          <Box css={overlayStyle} onClick={hideMenu} />
          <Box css={openStyle}>{children}</Box>
        </>
      )}
    </Box>
  );
};

export const MobileMenu: FC<{
  links: TMenuLinkItem[];
}> = ({ links }) => (
  <BaseMobileMenu>
    <Menu links={links} />
  </BaseMobileMenu>
);
export default MobileMenu;
