import { VStack, Text } from '@chakra-ui/react';
import { ThinDivider } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import {
  BankModalBody,
  BankModalTitle,
  ModalHeaderActions,
} from '../../components/BankModal/BankModal';
import { useTransferWizardContext } from './TransferWizard';
import React, { useCallback } from 'react';
import { usePermissions } from '../../hooks/use-permissions';
import { TransactionsIcon, UsersIcon } from '@payler/bank-utils';
import { RegularArrowButton } from '../../components/ArrowButton/RegularArrowButton';
import { useAccountsQuery } from '../../hooks/accounts/queries';

export const TransferSelectRecipient = ({
  onAddRecipientClick,
}: {
  onAddRecipientClick: VoidFunction;
}) => {
  const { t } = useTranslation(['accounts']);
  const { setStep, setIsSelfTransfer, senderAccount, setIsNewRecipientState } =
    useTransferWizardContext();
  const { isGlobalAccounts } = usePermissions();
  const { data: accounts } = useAccountsQuery({ enabled: !senderAccount });

  const handleNewTransferClick = useCallback(() => {
    if (!isGlobalAccounts) {
      // TODO: добавить мок для партисипанта и setParticipant
      // setRecipient(mockRecipients[0]);
      setStep('form');
    } else {
      onAddRecipientClick();
    }
  }, [isGlobalAccounts, onAddRecipientClick, setStep]);

  const isReceiverExists = accounts?.some(
    (account) =>
      account.currency.toLowerCase() ===
        senderAccount?.currency.toLowerCase() &&
      account.id !== senderAccount.id,
  );

  const isCnyAccount = senderAccount?.currency.toLowerCase() === 'cny';
  const isSelfTransferButtonVisible = !isCnyAccount;

  const handleTransferToSelfClick = useCallback(() => {
    if (isReceiverExists) {
      setStep('selfSelectAccounts');
      setIsSelfTransfer(true);
    } else {
      setStep('selfWarning');
    }
  }, [isReceiverExists, setIsSelfTransfer, setStep]);

  return (
    <>
      <ModalHeaderActions />
      <BankModalTitle title={t('accounts:transferMoney.chooseRecipient')} />
      <BankModalBody>
        <VStack spacing={2} width="100%" alignItems="baseline">
          <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
            {t('accounts:transferMoney.selectRecipient')}
          </Text>
          <VStack
            w="full"
            bgColor="primary.25"
            borderRadius="md"
            divider={<ThinDivider m="0 !important" />}
          >
            {isSelfTransferButtonVisible && (
              <RegularArrowButton
                title={t('accounts:transferMoney.transferToSelfTitle')}
                subtitle={t('accounts:transferMoney.inSameCurrency')}
                regularIcon={TransactionsIcon}
                onClick={handleTransferToSelfClick}
                disabled={!isGlobalAccounts}
                data-testid="button_transfer-to-self"
                borderTopRadius="md"
                _hover={{ bg: 'primary.50' }}
                cursor={!isGlobalAccounts ? 'not-allowed' : 'pointer'}
              />
            )}
            <RegularArrowButton
              title={t('accounts:transferMoney.transferToNewRecipient')}
              subtitle={t('accounts:transferMoney.newPayee')}
              regularIcon={UsersIcon}
              onClick={handleNewTransferClick}
              data-testid="button_transfer-to-new-recipient"
              borderBottomRadius="md"
              borderTopRadius={isSelfTransferButtonVisible ? 'none' : 'md'}
              _hover={{ bg: 'primary.50' }}
            />
          </VStack>
          {/* Список доступных получателей удален в рамках BP-100 */}
        </VStack>
      </BankModalBody>
    </>
  );
};
