import { IS_IOS_PLATFORM } from '@payler/bank-utils';
import { AxiosInstance } from 'axios';
import { Dictionary } from 'lodash';

export async function prepareBlob(
  axios: AxiosInstance,
  url: string,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  params?: Dictionary<any>,
) {
  let blob: Blob;
  let fileName = '';
  let mimeType = 'application/octet-stream'; // default MIME type

  if (process.env['STORYBOOK_IN_STORYBOOK'] === 'true') {
    fileName = 'test_name_for_storybook.csv';
    blob = new Blob([`some, storybook, csv`, `\n1,2,3`]);
  } else {
    const resp = await axios.get(url, {
      params,
      responseType: 'arraybuffer',
    });

    fileName =
      resp.headers?.['content-disposition']
        ?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)?.[1]
        ?.replace(/"/g, '') ?? '';

    mimeType = resp.headers['content-type'] || mimeType;
    blob = new Blob([resp.data], { type: mimeType });
  }
  return { blob, fileName };
}

export function downloadFile(blob: Blob, fileName: string) {
  if (IS_IOS_PLATFORM) {
    downloadFileIOS(blob, fileName)
  } else {
    const blobURL = URL.createObjectURL(blob);

    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;

    tempLink.setAttribute('download', fileName);
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
      URL.revokeObjectURL(blobURL);
    }, 100);
  }
}

function downloadFileIOS(blob: Blob, fileName: string) {
  const reader = new FileReader();

  reader.onload = function () {
    const base64Data = reader.result?.toString().split(',')[1];
    (window as any).webkit.messageHandlers.downloadBlob.postMessage({
      data: base64Data,
      filename: fileName,
    });
  };

  reader.readAsDataURL(blob);
}
