import { Search2Icon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react';
import { TCurrency, useHandleToastError } from '@payler/bank-utils';
import { ErrorBoundary, ThinDivider } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowButton } from '../../components/ArrowButton/ArrowButton';
import {
  BankModalBody,
  BankModalTitle,
  ModalHeaderActions,
} from '../../components/BankModal/BankModal';
import { useAddAccountMutation } from '../../hooks/accounts/mutations';
import { useAddAccountWizardContext } from './AddAccountWizard';
import { useBaasProviderCurrenciesQuery } from '../../hooks/currencies/queries';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import { useGetClientCurrencies } from '../../hooks/use-get-client-currencies';

export type TSelectCurrencyForm = {
  currency: TCurrency;
};

const MIN_CURRENCIES_FOR_SEARCH = 3;

export const SelectCurrencyStep = () => {
  const { t } = useTranslation(['accounts']);

  return (
    <>
      <ModalHeaderActions />
      <BankModalTitle
        title={t('accounts:addAccountModal.currencyStep.title')}
        description={t('accounts:addAccountModal.currencyStep.description')}
      />
      <BankModalBody>
        <ErrorBoundary>
          <Suspense fallback={<LoaderBox />}>
            <CurrencyPicker />
          </Suspense>
        </ErrorBoundary>
      </BankModalBody>
    </>
  );
};

const CurrencyPicker = () => {
  const [search, setSearch] = useState('');
  const { data } = useBaasProviderCurrenciesQuery();
  const { currencies, isLoading } = useGetClientCurrencies();

  const mappedCurrencies = useMemo(() => {
    if (!data) {
      return [];
    }
    return data?.currencies.map((serverCurrency) => {
      const currencyObject = currencies.find(
        (currency) => currency.id === serverCurrency.toLowerCase(),
      );
      if (!currencyObject) {
        return {
          id: serverCurrency,
          name: '',
          currencyCode: serverCurrency.toUpperCase(),
          iconUri: '',
        };
      }
      return currencyObject;
    });
  }, [data, currencies]);

  const filteredList = useMemo(
    () =>
      mappedCurrencies.filter((item) => {
        return (
          item.currencyCode.toUpperCase().indexOf(search.toUpperCase()) >= 0 ||
          item.name.toUpperCase().indexOf(search.toUpperCase()) >= 0
        );
      }),
    [search, mappedCurrencies],
  );

  return (
    <VStack spacing={2} alignItems="stretch">
      <Search onChange={setSearch} />
      <List list={filteredList} isLoading={isLoading} />
    </VStack>
  );
};

const Search = ({ onChange }: { onChange: (val: string) => void }) => {
  const { t } = useTranslation();
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <Search2Icon color="primary.400" w="20px" h="20px" />
      </InputLeftElement>
      <Input
        placeholder={t('search')}
        onChange={(e) => {
          onChange(e.target.value ?? '');
        }}
      />
    </InputGroup>
  );
};

const List = ({
  isLoading,
  list,
}: {
  list: {
    id: string;
    name: string;
    currencyCode: string;
    iconUri?: string;
  }[];
  isLoading: boolean;
}) => {
  const { setCurrency, currency, setStep, setPhoneNumber, setCorrelationId } =
    useAddAccountWizardContext();
  const handleToastError = useHandleToastError();
  const { isPending, mutate } = useAddAccountMutation();

  const handleSelect = useCallback(
    (currency: string) => {
      setCurrency(currency);
      mutate(
        {
          currencyCode: currency,
        },
        {
          onSuccess: (resp) => {
            setPhoneNumber(resp.phoneNumber);
            setCorrelationId(resp.correlationId);
            setStep('otp');
          },
          onError: handleToastError,
        },
      );
    },
    [
      handleToastError,
      mutate,
      setCorrelationId,
      setCurrency,
      setPhoneNumber,
      setStep,
    ],
  );

  const handleClick = useCallback(
    (id: string) => {
      setCurrency(id);
      handleSelect(id);
    },
    [setCurrency, handleSelect],
  );

  return isLoading ? (
    <LoaderBox />
  ) : (
    <RadioGroup name="currency" value={currency}>
      <VStack spacing={0} alignItems="stretch" divider={<ThinDivider />}>
        {list.map((item) => (
          <CurrencyItem
            key={item.id}
            id={item.id}
            currencyCode={item.currencyCode}
            name={item.name}
            iconUri={item.iconUri}
            onClick={handleClick}
            isLoading={isPending}
          />
        ))}
      </VStack>
    </RadioGroup>
  );
};

const CurrencyItem = ({
  id,
  currencyCode,
  name,
  isLoading,
  iconUri,
  onClick,
}: {
  id: string;
  currencyCode?: string;
  name?: string;
  iconUri?: string;
  isLoading: boolean;
  onClick: (id: string) => void;
}) => {
  const { currency } = useAddAccountWizardContext();

  return (
    <ArrowButton
      onClick={() => {
        onClick(id);
      }}
      py={2}
      px={0}
      isHover
      disabled={isLoading}
      isLoading={isLoading && currency?.toLowerCase() === id.toLowerCase()}
    >
      <HStack alignItems="stretch">
        {iconUri ? (
          <Flex alignItems="center">
            <Box borderRadius="50%" h="40px" w="40px" overflow="hidden">
              <Image
                h="100%"
                w="100%"
                transform="scale(1.5)"
                objectFit="contain"
                src={iconUri}
              />
            </Box>
          </Flex>
        ) : (
          <Flex alignItems="center">
            <Icon boxSize={5} />
          </Flex>
        )}
        <VStack spacing={0} w="full" alignItems="start">
          <Text textStyle={TextStyles.Subtitle14Medium}>{currencyCode}</Text>
          <Text textStyle={TextStyles.Caption12Regular} color="primary.350">
            {name}
          </Text>
        </VStack>
      </HStack>
    </ArrowButton>
  );
};
