import { RouteObject } from 'react-router-dom';
import {
  SamSubOnboardingPageLazy,
  SamSubRejectionPageLazy,
} from '../components/lazy-components';

export const getSamSubOnboardingRoutes = (): RouteObject[] => {
  return [
    {
      path: '/',
      element: <SamSubOnboardingPageLazy />,
    },
    {
      path: '/rejection',
      element: <SamSubRejectionPageLazy />,
    },
  ];
};
