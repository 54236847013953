import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useHandleToastError } from '@payler/bank-utils';
import { ThinDivider } from '@payler/ui-components';
import { PencilIcon, CNYIcon } from '@payler/ui-icons';
import { TextStyles } from '@payler/ui-theme';
import { t } from 'i18next';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAccountByUrl,
  useAccountIdFromUrl,
  useCNYAccount,
} from '../../hooks/accounts/queries';
import { useAccountConfirmationQuery } from '../../hooks/reports/queries';
import { ExchangeMobileIcon, FileTextIcon } from '../../icons';
import { useShowAccountStatementModal } from '../../modals/AccountStatement';
import { useShowEditAccountNameModal } from '../../modals/EditAccountName';
import { useShowExchangeModal } from '../../modals/Exchange/ExchangeModal';
import { ArrowButton } from '../ArrowButton/ArrowButton';
import { useCheckAppFlagEnabled } from '../../config/ConfigProvider';
import { useShowConvertToCNYModal } from '../../modals/ConvertToCNY/ConvertToCNYModal';

export const MobileMoreDrawer: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslation(['accounts']);
  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent borderRadius="16px 16px 0px 0px">
        <DrawerHeader p={2} textStyle={TextStyles.h4} color="primary.500">
          {t('accounts:mobileMoreDrawer.more')}
        </DrawerHeader>
        <DrawerBody px={2} pt={0}>
          <MoreButtons />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const MoreButtons = () => {
  const handleToastError = useHandleToastError();
  const accountId = useAccountIdFromUrl();
  const account = useAccountByUrl();
  const cnyAccount = useCNYAccount();
  const hasCNYAccount = !!cnyAccount;
  const isEURAccount = account?.currency.toLowerCase() === 'eur';
  const onOpenEditAccountNameModal = useShowEditAccountNameModal();
  const showAccountStatementModal = useShowAccountStatementModal();
  const onOpenExchangeModal = useShowExchangeModal();
  const onOpenConvertToCNYModal = useShowConvertToCNYModal();
  const isFeautureExchangeEnabled = useCheckAppFlagEnabled('accounts.exchange');
  const isFeatureCNYEnabled = useCheckAppFlagEnabled('accounts.cny');
  const {
    handleAccountConfirmationQuery,
    loading: accountConfirmationLoading,
  } = useAccountConfirmationQuery();

  const handleDownloadAccountConfirmation = useCallback(async () => {
    if (!accountId) {
      return;
    }
    try {
      await handleAccountConfirmationQuery(accountId);
    } catch (e) {
      handleToastError(e);
    }
  }, [accountId, handleAccountConfirmationQuery, handleToastError]);

  return (
    <VStack spacing={0} divider={<ThinDivider />} width="100%">
      {/* Account statement удален в рамках BANK-1756 */}
      {isFeautureExchangeEnabled && (
        <ArrowButton
          onClick={onOpenExchangeModal}
          disabled={!account?.allowOutgoingOperations}
        >
          <HStack spacing={2}>
            <Flex alignItems="center">
              <IconButton
                aria-label={t('accounts:accountInfo.exchange')}
                icon={<Icon w="14px" h="14px" as={ExchangeMobileIcon} />}
                variant="outlined"
                size="xs"
                w="36px"
                h="36px"
                minH="36px"
                minW="36px"
                borderRadius="50px"
              />
            </Flex>
            <Text textStyle={TextStyles.BodyUI16Medium} color="primary.500">
              {t('accounts:accountInfo.exchange') as string}
            </Text>
          </HStack>
        </ArrowButton>
      )}
      {isFeatureCNYEnabled && hasCNYAccount && isEURAccount && (
        <ArrowButton
          onClick={onOpenConvertToCNYModal}
          disabled={!account?.allowOutgoingOperations}
        >
          <HStack spacing={2}>
            <Flex alignItems="center">
              <IconButton
                aria-label={t('accounts:accountInfo.convertToCNY')}
                icon={<Icon w="14px" h="14px" as={CNYIcon} />}
                variant="outlined"
                size="xs"
                w="36px"
                h="36px"
                minH="36px"
                minW="36px"
                borderRadius="50px"
              />
            </Flex>
            <Text textStyle={TextStyles.BodyUI16Medium} color="primary.500">
              {t('accounts:accountInfo.convertToCNY')}
            </Text>
          </HStack>
        </ArrowButton>
      )}
      <ArrowButton
        onClick={() => {
          if (accountId) {
            onOpenEditAccountNameModal(accountId);
          }
        }}
        data-testid="button_open_edit-name-modal"
      >
        <HStack spacing={2}>
          <Flex alignItems="center">
            <IconButton
              aria-label={t('accounts:mobileMoreDrawer.editName')}
              icon={<Icon w="14px" h="14px" as={PencilIcon} />}
              variant="outlined"
              size="xs"
              w="36px"
              h="36px"
              minH="36px"
              minW="36px"
              borderRadius="50px"
            />
          </Flex>
          <Text textStyle={TextStyles.BodyUI16Medium} color="primary.500">
            {t('accounts:mobileMoreDrawer.editName') as string}
          </Text>
        </HStack>
      </ArrowButton>
      <ArrowButton
        onClick={() => {
          if (accountId) {
            showAccountStatementModal(accountId);
          }
        }}
        data-testid="button_open_statement-modal"
      >
        <HStack spacing={2}>
          <Flex alignItems="center">
            <IconButton
              aria-label={t('accounts:mobileMoreDrawer.statement')}
              icon={<Icon w="14px" h="14px" as={PencilIcon} />}
              variant="outlined"
              size="xs"
              w="36px"
              h="36px"
              minH="36px"
              minW="36px"
              borderRadius="50px"
            />
          </Flex>
          <Text textStyle={TextStyles.BodyUI16Medium} color="primary.500">
            {t('accounts:mobileMoreDrawer.statement') as string}
          </Text>
        </HStack>
      </ArrowButton>
      <ArrowButton
        onClick={handleDownloadAccountConfirmation}
        disabled={accountConfirmationLoading}
        data-testid="button_download_confirmation"
      >
        <HStack spacing={2}>
          <Flex alignItems="center">
            <IconButton
              aria-label={t('accounts:mobileMoreDrawer.confirmation')}
              icon={<Icon w="14px" h="14px" as={FileTextIcon} />}
              variant="outlined"
              size="xs"
              w="36px"
              h="36px"
              minH="36px"
              minW="36px"
              borderRadius="50px"
            />
          </Flex>
          <Text textStyle={TextStyles.BodyUI16Medium} color="primary.500">
            {t('accounts:mobileMoreDrawer.confirmation') as string}
          </Text>
        </HStack>
      </ArrowButton>
    </VStack>
  );
};
