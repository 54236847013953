import { css, Global } from '@emotion/react';
import React from 'react';

const RobotoTextBlackWoff = 'Roboto-Text/Roboto-Text_Black.woff';
const RobotoTextBlackWoff2 = 'Roboto-Text/Roboto-Text_Black.woff2';
const RobotoTextBoldWoff = 'Roboto-Text/Roboto-Text_Bold.woff';
const RobotoTextBoldWoff2 = 'Roboto-Text/Roboto-Text_Bold.woff2';
const RobotoTextSemiBoldWoff = 'Roboto-Text/Roboto-Text_SemiBold.woff';
const RobotoTextSemiBoldWoff2 = 'Roboto-Text/Roboto-Text_SemiBold.woff2';
const RobotoTextMediumWoff = 'Roboto-Text/Roboto-Text_Medium.woff';
const RobotoTextMediumWoff2 = 'Roboto-Text/Roboto-Text_Medium.woff2';
const RobotoTextRegularWoff = 'Roboto-Text/Roboto-Text_Regular.woff';
const RobotoTextRegularWoff2 = 'Roboto-Text/Roboto-Text_Regular.woff2';
const RobotoTextVFWoff = 'Roboto-Text/Roboto-Text_VF.woff';
const RobotoTextVFWoff2 = 'Roboto-Text/Roboto-Text_VF.woff2';
const RobotoUIBoldWoff = 'Roboto-UI/Roboto-UI_Bold.woff';
const RobotoUIBoldWoff2 = 'Roboto-UI/Roboto-UI_Bold.woff2';
const RobotoUIMediumWoff = 'Roboto-UI/Roboto-UI_Medium.woff';
const RobotoUIMediumWoff2 = 'Roboto-UI/Roboto-UI_Medium.woff2';
const RobotoUIRegularWoff = 'Roboto-UI/Roboto-UI_Regular.woff';
const RobotoUIRegularWoff2 = 'Roboto-UI/Roboto-UI_Regular.woff2';
const RobotoUIVFWoff = 'Roboto-UI/Roboto-UI_VF.woff';
const RobotoUIVFWoff2 = 'Roboto-UI/Roboto-UI_VF.woff2';

/**
 * hack against chrome autofill styles
 */
const chromeAutofillStylesHack = css`
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
`;

/**
 * Путь до ассетов шрифтов, по умолчанию 'assets/fonts/'
 * @param prefix
 * @constructor
 */
export const GlobalFonts = ({
  prefix = 'assets/fonts/',
}: {
  prefix?: string;
}) => (
  <Global
    styles={css`
      @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
      @font-face {
        font-family: 'RobotoTextWebBlack';
        src: url(${prefix}${RobotoTextBlackWoff2}) format('woff2'),
          url(${prefix}${RobotoTextBlackWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'RobotoTextWebBold';
        src: url(${prefix}${RobotoTextBoldWoff2}) format('woff2'),
          url(${prefix}${RobotoTextBoldWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'RobotoTextWebSemiBold';
        src: url(${prefix}${RobotoTextSemiBoldWoff2}) format('woff2'),
          url(${prefix}${RobotoTextSemiBoldWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'RobotoTextWebMedium';
        src: url(${prefix}${RobotoTextMediumWoff2}) format('woff2'),
          url(${prefix}${RobotoTextMediumWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'RobotoTextWebRegular';
        src: url(${prefix}${RobotoTextRegularWoff2}) format('woff2'),
          url(${prefix}${RobotoTextRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'RobotoTextWebVF';
        src: url(${prefix}${RobotoTextVFWoff2}) format('woff2'),
          url(${prefix}${RobotoTextVFWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: 'RobotoUIWebBold';
        src: url(${prefix}${RobotoUIBoldWoff2}) format('woff2'),
          url(${prefix}${RobotoUIBoldWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'RobotoUIWebMedium';
        src: url(${prefix}${RobotoUIMediumWoff2}) format('woff2'),
          url(${prefix}${RobotoUIMediumWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'RobotoUIWebRegular';
        src: url(${prefix}${RobotoUIRegularWoff2}) format('woff2'),
          url(${prefix}${RobotoUIRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'RobotoUIWebVF';
        src: url(${prefix}${RobotoUIVFWoff2}) format('woff2'),
          url(${prefix}${RobotoUIVFWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      ${chromeAutofillStylesHack};
    `}
  />
);
