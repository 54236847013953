import { DemoApiClientOffice, TToken } from '@payler/api/client-office';

import { ISpushApiClientOffice } from './types/api';
import { TAuth } from '@payler/auth';
import {
  TCustomerPaymentSessionDto,
  TConfirmPaymentParams,
} from './types/payment';
import {
  mockedCustomerAfterPaymentSession,
  mockedCustomerPaymentSession,
} from './mocks';
import { AxiosError } from 'axios';

export class SpushDemoApiClientOffice
  extends DemoApiClientOffice
  implements ISpushApiClientOffice
{
  public constructor(baseURL: string, auth?: TAuth<TToken>) {
    super(baseURL, auth);
  }

  /**
   * Запрос данных платежной сессии по ID
   */
  async getCustomerPaymentSession(
    sessionId: string,
  ): Promise<TCustomerPaymentSessionDto> {
    return Promise.resolve(mockedCustomerPaymentSession);
  }

  /**
   * Осуществление платежа
   */
  async confirmPayment(
    params: TConfirmPaymentParams,
  ): Promise<TCustomerPaymentSessionDto> {
    return Promise.resolve(mockedCustomerAfterPaymentSession);
  }

  /**
   * Отмена платежа
   */
  async declinePayment(sessionId: string): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Подтверждение Подтверждение ОТП
   */
  override async confirmOutgoingTransfer(
    transferId: string,
    confirmationCode: string,
  ) {
    const err: any = new AxiosError('test', 'EXCEEDED_OTP_ATTEMPTS_ERROR');
    err.response = {};
    err.response.data = {
      errors: {
        Code: {
          code: 'EXCEEDED_OTP_ATTEMPTS_ERROR',
          message: 'exceed otp text',
        },
      },
      code: 'EXCEEDED_OTP_ATTEMPTS_ERROR',
    };
    return Promise.reject(err);
    // return Promise.reject({} as never);
  }
}
