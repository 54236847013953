import { TClientDto, isCompanyClient } from '@payler/api/client-office';

export const getClientName = (client: TClientDto) =>
  isCompanyClient(client)
    ? client.legalName
    : `${client.firstName} ${client.lastName}`;

export const getSupportLink = (
  supportEmail: string,
  legalName: string = '',
  supportEmailSubject?: string,
) =>
  `mailto:${supportEmail}?subject=${encodeURIComponent(
    supportEmailSubject ?? `${legalName}: business account problem`,
  )}`;
