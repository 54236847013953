import React from 'react';
import { Pagination } from './Pagination';
import { RowsPerPage } from './RowsPerPage';
import useResizeObserver from 'use-resize-observer';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

export interface PaginationProps {
  goToPage: (pageNumber: number) => void;
  totalRecords: number;
  rowsPerPage?: number[];
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  currentPage: number;
  totalPages?: number;
  /** Количество соседних элементов выбранной страницы в десктоп версии */
  desktopSiblingsCount?: number;
  /** Количество соседних элементов выбранной страницы в мобильной версии */
  mobileSiblingsCount?: number;
}

const round = (w: number) => {
  if (w > 309) return 310;
  if (w > 199) return 200;
  if (w > 109) return 110;
  return 10;
};

export const PaginationContainer: React.FC<PaginationProps> = (props) => {
  const { t } = useTranslation();
  const { ref, width } = useResizeObserver<HTMLDivElement>({
    round,
  });
  const showRowsPerPage = !!width && width >= 110;
  const showShowedLabel = !!width && width >= 200;
  const showRowsPerPageLabel = !!width && width >= 310;
  const startItem = (props.currentPage - 1) * props.pageSize + 1;
  const endItem = Math.min(startItem - 1 + props.pageSize, props.totalRecords);
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
      data-testid="pagination"
      gap="8px"
    >
      <Flex flexShrink={0} alignItems="center" flexGrow={1}>
        <Pagination {...props} onPageChanged={props.goToPage} />
      </Flex>
      <Flex
        maxW="100%"
        justifyContent={width === 110 ? 'flex-end' : 'space-between'}
        alignItems="center"
        ref={ref}
      >
        {showShowedLabel && (
          <Box
            ml={2}
            textStyle={TextStyles.Caption12Regular}
            color="primary.350"
            flexShrink={0}
            data-testid="showed-pages"
          >
            {t('pager.showed', 'Showed')}
            <Box textStyle={TextStyles.Subtitle14Medium} color="primary.500">
              {startItem} - {endItem} {t('pager.of', 'of')} {props.totalRecords}
            </Box>
          </Box>
        )}
        <RowsPerPage
          showRowsPerPageLabel={showRowsPerPageLabel}
          showRowsPerPage
          currentRowPerPage={props.pageSize}
          rowsPerPage={props?.rowsPerPage}
          setPageSize={props.setPageSize}
        />
      </Flex>
    </Flex>
  );
};
PaginationContainer.displayName = 'PaginationContainer';
