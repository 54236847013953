import { TDropdownOption } from '@payler/ui-components';
import country from './country';

/**
 * Все доступные языки
 */
export const ALL_LANGS = ['en', 'es'];

export const DEFAULT_PAGER_PROPS = {
  currentPage: 1,
  pageSize: 10,
  totalRecords: 0,
  totalPages: 0,
};

/**
 * Причины для межбанковских переводов
 */
export const TRANSFER_REASON = [
  'Expenses',
  'Loan',
  'Merchant settlement',
  'Mortgage repayment',
  'Payment for goods and services',
  'Refund',
  'Salary/Payroll',
  'Repayment of loan',
  'Tax settlement',
  'Other',
] as const;

export const TRANSFER_REASON_OPTIONS: TDropdownOption[] = TRANSFER_REASON.map(
  (r) => ({ label: r, value: r }),
);

/**
 * Список доступных стран
 */
export const COUNTRY = country;

/**
 * Список стран для Select
 */
export const COUNTRY_OPTIONS: TDropdownOption[] = COUNTRY.map((r) => ({
  label: r.name,
  value: r.code.toLowerCase(),
}));

export const PASSWORD_REGEXP = new RegExp(
  /^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{8,}$/,
);

export const DEFAULT_OTP_LENGTH = 6;
export const FEE_ALLOCATION_TYPES = ['our', 'sha'];

export const UK_COUNTRY_CODE = 'GB';
export const UK_CURRENCY_CODE = 'GBP';

/**
 * Возможные типы трансферов
 */
export type TTransferTableType =
  | 'All types'
  | 'Incoming'
  | 'Outgoing'
  | 'Fx';
export const TRANSFER_TABLE_TYPE_PARAM = 'Type';
export const TRANSFER_TABLE_TYPES = [
  'All types',
  'Incoming',
  'Outgoing',
  'Fx',
];
