import { forwardRef } from 'react';
import {
  Box,
  HStack,
  Icon,
  IconProps,
  StackProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { ChevronDownIcon } from '@payler/ui-icons';
import { TExchangeDropdownOption } from './ExchangeDropdownOption';
import { TriggerWrapper } from './TriggerWrapper';
import { VerticalDivider } from '@payler/ui-components';
import { ReactComponent } from '*.svg';
import { IonIcon } from '@ionic/react';

export type TExchangeDropdownTriggerProps = {
  isActive?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  isOpen: boolean;
  selectedOption?: TExchangeDropdownOption;
  triggerWrapperProps?: Omit<StackProps, 'borderColor'>;
  isFocused?: boolean;
  optionLabelProps?: TextProps;
  dropdownIcon?: {
    component?: typeof ReactComponent | typeof IonIcon;
    props?: IconProps;
  };
};

export const ExchangeDropdownTrigger = forwardRef<
  HTMLDivElement,
  TExchangeDropdownTriggerProps
>(
  (
    {
      isActive,
      isError,
      isDisabled,
      isOpen,
      triggerWrapperProps,
      optionLabelProps,
      selectedOption,
      dropdownIcon,
    },
    ref,
  ) => {
    const borderColor = getBorderColor(!!isActive, !!isError);
    const bg = getBgColor(isError, isActive);
    const cursor = isDisabled ? 'not-allowed' : 'pointer';
    const chevronColor = isActive ? 'primary.500' : 'primary.300';
    const chevronTransform = isOpen ? 'rotate(180deg)' : 'rotate(0deg)';

    return (
      <TriggerWrapper
        ref={ref}
        borderColor={borderColor}
        cursor={cursor}
        bg={bg}
        {...triggerWrapperProps}
      >
        <Box minW={0} flex={1}>
          {selectedOption?.label && (
            <HStack spacing={2} transitionDuration="fast">
              {selectedOption.icon && (
                <Icon
                  as={selectedOption.icon}
                  w="36px"
                  h="36px"
                  flexShrink={0}
                  flexBasis="36px"
                />
              )}

              <HStack minW={0} w="100%">
                <Box minW={0} flex={1}>
                  <Text
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    color={isDisabled ? 'primary.350' : 'primary.500'}
                    transitionDuration="fast"
                    textStyle={TextStyles.BodyUI16Medium}
                    {...optionLabelProps}
                  >
                    {selectedOption.label}
                  </Text>
                  {selectedOption.caption && (
                    <Text
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      color={isDisabled ? 'primary.300' : 'primary.350'}
                      transitionDuration="fast"
                      textStyle={TextStyles.Caption12Regular}
                      mt="0"
                    >
                      {selectedOption.caption}
                    </Text>
                  )}
                </Box>

                {selectedOption.secondLabel && (
                  <HStack minW={0} flexShrink={1}>
                    <Box minW={0} flexShrink={1} textAlign="right">
                      <Text
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        color={isDisabled ? 'primary.350' : 'primary.500'}
                        transitionDuration="fast"
                        textStyle={TextStyles.BodyUI16Medium}
                      >
                        {selectedOption.secondLabel}
                      </Text>
                      {selectedOption.secondCaption && (
                        <Text
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          color={isDisabled ? 'primary.300' : 'primary.350'}
                          transitionDuration="fast"
                          textStyle={TextStyles.Caption12Regular}
                          mt="0"
                        >
                          {selectedOption.secondCaption}
                        </Text>
                      )}
                    </Box>
                    <VerticalDivider h={4} style={{ margin: '0 16px' }} />
                  </HStack>
                )}
              </HStack>
            </HStack>
          )}
        </Box>

        <Icon
          as={dropdownIcon?.component || ChevronDownIcon}
          transitionDuration="fast"
          color={chevronColor}
          transform={chevronTransform}
          w="20px"
          h="20px"
          {...dropdownIcon?.props}
        />
      </TriggerWrapper>
    );
  },
);

function getBgColor(isError?: boolean, isActive?: boolean) {
  if (isError) {
    return 'red.100';
  }
  return isActive ? 'secondary.500' : 'primary.25';
}

function getBorderColor(isActive: boolean, isError: boolean) {
  if (isError) return 'red.500';
  return isActive ? 'brands.500' : 'primary.100';
}
