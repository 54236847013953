import { ImageProps, Image, useColorMode, ColorMode } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React from 'react';

export const getLogoFilename = (
  colorMode: ColorMode,
  logoFileExtension: 'svg' | 'png' = 'png',
) => {
  return colorMode === 'light'
    ? `logo.${logoFileExtension}`
    : `logo-dark.${logoFileExtension}`;
};

//TODO: посмотреть в сторону base64
export const Logo = ({
  forLogin,
  staticPath = '',
  logoFileExtension,
  ...rest
}: Omit<ImageProps, 'css'> & {
  forLogin?: boolean;
  staticPath?: string;
  logoFileExtension?: 'svg' | 'png';
}) => {
  const { colorMode } = useColorMode();
  const fileName = getLogoFilename(colorMode, logoFileExtension);
  const logoSrc = `${staticPath}/${fileName}`;

  const logo = <Image m={{ base: 2 }} src={logoSrc} {...rest} />;
  if (forLogin) return logo;
  return <Link to="/">{logo}</Link>;
};
