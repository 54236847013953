import {
  createFormData,
  useGetAxiosError,
  useHandleToastError,
} from '@payler/bank-utils';
import { useApi } from '../../state/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import {
  deleteBaasProviderIdFromUrl,
  deleteCurrencyCodeFromUrl,
} from './helpers';
import createLogger from 'debug';
import {
  TBaasProvidersCurrenciesDto,
  TReferencesCurrencyDto,
  TEditCurrencyPayload,
} from '@payler/api/back-office';
import { providersAtom } from '../../drawers/BaasProviderInfoDrawer/state';
import { useSetRecoilState } from 'recoil';

const log = createLogger('currencies');

export const CURRENCY_CODE = 'currency_code';
export const BAAS_PROVIDER_ID = 'baas_provider_id';
const STALE_TIME = 300_000;

/**
 * Запросы для Currency
 */
export const useCurrenciesQuery = () => {
  const api = useApi();
  const getError = useGetAxiosError();

  return useQuery({
    queryKey: ['currencies', 'references'],
    queryFn: () => api.getCurrencies(),
    staleTime: STALE_TIME,
    throwOnError: (error) => !getError(error).fieldErrors,
    retry: (failureCount, error) =>
      !getError(error).fieldErrors && failureCount <= 3,
  });
};

export const useCurrencyByCodeQuery = (currencyCode?: string) => {
  const api = useApi();
  const getError = useGetAxiosError();

  return useQuery({
    queryKey: ['currencies', 'references', currencyCode],
    queryFn: () => api.getCurrencyByCode(currencyCode),
    enabled: !!currencyCode,
    staleTime: STALE_TIME,
    retry: (failureCount, error) =>
      !getError(error).fieldErrors && failureCount <= 3,
  });
};

export const useSetCurrencyCodeToUrl = () => {
  const [params, setParams] = useSearchParams();

  return useCallback(
    (currencyCode?: string) => {
      deleteCurrencyCodeFromUrl(params);
      currencyCode && params.append(CURRENCY_CODE, currencyCode);

      setParams(params);
    },
    [params, setParams],
  );
};

export const useCurrencyCodeFromUrl = () => {
  const [params] = useSearchParams();
  return params.get(CURRENCY_CODE);
};

export const useClearCurrencyCodeInUrl = () => {
  const [params, setParams] = useSearchParams();

  return useCallback(() => {
    deleteCurrencyCodeFromUrl(params, setParams);
  }, [params]);
};

/**
 * Создание валюты
 */
export const useCreateCurrency = () => {
  const api = useApi();
  const client = useQueryClient();
  const handleToastError = useHandleToastError();

  return useMutation({
    mutationKey: ['currencies', 'references', 'create'],
    mutationFn: (data: TReferencesCurrencyDto) => {
      const formData = new FormData();
      createFormData(formData, data);

      return api.createNewCurrency(formData);
    },
    onError: handleToastError,
    onSettled: () =>
      client.invalidateQueries({
        queryKey: ['currencies', 'references'],
      }),
  });
};

/**
 * Редактирование валюты
 */
export const useEditCurrencyMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const handleToastError = useHandleToastError();

  return useMutation({
    mutationFn: (data: { code: string } & TEditCurrencyPayload) => {
      const formData = new FormData();
      createFormData(formData, data);

      return api.editCurrency(data.code, formData);
    },
    onError: handleToastError,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ['currencies', 'references'] })
        .then(() => log('Invalidated currencies list'));
    },
  });
};

/**
 * Запросы для Baas Provider
 */
export const useBaasProvidersCurrenciesQuery = () => {
  const api = useApi();
  const getError = useGetAxiosError();

  return useQuery({
    queryKey: ['baasProviders', 'references'],
    queryFn: () => api.getBaasProvidersCurrencies(),
    staleTime: STALE_TIME,
    throwOnError: (error) => !getError(error).fieldErrors,
    retry: (failureCount, error) =>
      !getError(error).fieldErrors && failureCount <= 3,
  });
};

/**
 * Создание привязки провайдера
 */
export const useCreateBaasProvidersCurrency = () => {
  const api = useApi();
  const client = useQueryClient();
  const handleToastError = useHandleToastError();

  return useMutation({
    mutationKey: ['baasProviders', 'references'],
    mutationFn: (data: TBaasProvidersCurrenciesDto) =>
      api.createBaasProvidersCurrency(data),
    onError: handleToastError,
    onSettled: () =>
      client.invalidateQueries({
        queryKey: ['baasProviders', 'references'],
      }),
  });
};

/**
 * Удаление привязки провайдера
 */
export const useDeleteBaasProvidersCurrency = () => {
  const api = useApi();
  const client = useQueryClient();
  const handleToastError = useHandleToastError();
  const setProviders = useSetRecoilState(providersAtom);

  return useMutation({
    mutationKey: ['deleteProvider'],
    mutationFn: (data: TBaasProvidersCurrenciesDto) =>
      api.deleteBaasProvidersCurrency(data),
    onSuccess: (_, data) => {
      setProviders((prev) => {
        const newData = new Map(prev);

        newData.delete(
          `${data.baasProvider}${data.paymentMethod}${data.currencyCode}`,
        );

        return newData;
      });
      client.invalidateQueries({
        queryKey: ['baasProviders', 'references'],
      });
    },
    onError: handleToastError,
  });
};

export const useSetBaasProviderIdToUrl = () => {
  const [params, setParams] = useSearchParams();

  return useCallback(
    (baasProviderId?: string) => {
      deleteBaasProviderIdFromUrl(params);
      baasProviderId && params.append(BAAS_PROVIDER_ID, baasProviderId);

      setParams(params);
    },
    [params, setParams],
  );
};

export const useBaasProviderIdFromUrl = () => {
  const [params] = useSearchParams();
  return params.get(BAAS_PROVIDER_ID);
};

export const useClearBaasProviderIdFromUrl = () => {
  const [params, setParams] = useSearchParams();

  return useCallback(() => {
    deleteBaasProviderIdFromUrl(params, setParams);
  }, [params]);
};
