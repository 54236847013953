import { FC, useMemo, useState } from 'react';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import {
  TAccountDto,
  TConvertToCNYTransferDto,
  TCreateConvertToCNYTransferCommand,
  TTransferFeeParams,
} from '@payler/api/client-office';
import { TextStyles } from '@payler/ui-theme';
import { Trans, useTranslation } from 'react-i18next';
import {
  BankModalBody,
  BankModalFooter,
  BankModalTitle,
  ModalHeaderActions,
} from '../../components/BankModal/BankModal';
import {
  TConvertToCNYSelectForm,
  useConvertToCNYWizardContext,
} from './ConvertToCNYWizard';
import {
  useCreateConvertToCNYMutation,
  useTransferFeeQuery,
} from '../../hooks/transfers/queries';
import { useHandleFormError } from '@payler/bank-utils';
import { useFormContext } from 'react-hook-form';
import { ApiErrorText } from '@payler/ui-components';

const fieldsMap: Record<string, keyof TConvertToCNYSelectForm> = {
  Amount: 'sendingAmount',
};

export const ConvertToCNYConfirmation: FC = () => {
  const { t } = useTranslation(['common', 'accounts', 'transfers']);
  const { mutate: createConvYrtToCNYTransfer, isPending } =
    useCreateConvertToCNYMutation();
  const {
    setStep,
    detailedExchangeRate,
    sendingAmount,
    receivingAmount,
    senderAccount,
    receiverAccount,
    setTransferId,
  } = useConvertToCNYWizardContext();
  const { data: transferFee } = useTransferFeeQuery({
    BaasProvider: senderAccount?.baasProvider.shortName,
    TransferType: 'outgoing',
    Amount: sendingAmount,
    Currency: senderAccount?.currency.toLowerCase(),
    System: 'internal',
  } as TTransferFeeParams);
  const handleFormError = useHandleFormError<TConvertToCNYSelectForm>();
  const methods = useFormContext<TConvertToCNYSelectForm>();
  const {
    formState: { errors },
  } = methods;
  const [errorText, setErrorText] = useState<string>();
  const { handleSubmit: rhfHandleSubmit } = methods;

  const onSubmit = useMemo(
    () =>
      rhfHandleSubmit(() => {
        const createFxTransferData: TCreateConvertToCNYTransferCommand = {
          sellAmount: sendingAmount as number,
          sellAccountId: (senderAccount as TAccountDto).id,
          buyAccountId: (receiverAccount as TAccountDto).id,
        };
        createConvYrtToCNYTransfer(createFxTransferData, {
          onSuccess: (transferDto: TConvertToCNYTransferDto) => {
            setTransferId(transferDto.externalTransferId);
            setStep('otp');
          },
          onError: (e) => {
            const unknownFieldErrors = handleFormError(e, methods, fieldsMap);
            if (unknownFieldErrors.length) {
              setErrorText(unknownFieldErrors.join(' '));
            }
          },
        });
      }),
    [
      sendingAmount,
      createConvYrtToCNYTransfer,
      handleFormError,
      methods,
      receiverAccount,
      rhfHandleSubmit,
      senderAccount,
      setStep,
    ],
  );

  return (
    <>
      <ModalHeaderActions
        isShowBack
        onBack={() => {
          setStep('select');
        }}
      />
      <BankModalTitle
        title={t('accounts:exchange.confirmationStep.title')}
        description={t('accounts:exchange.confirmationStep.description')}
      />
      <BankModalBody>
        <VStack alignItems="start">
          <Row
            description={t('accounts:exchange.confirmationStep.rate')}
            value={detailedExchangeRate}
          />
          <Row
            description={t('accounts:exchange.confirmationStep.youSpend')}
            value={`${sendingAmount} ${senderAccount?.currency.toUpperCase()}`}
          />
          {errors.sendingAmount && (
            <ApiErrorText>{errors.sendingAmount.message}</ApiErrorText>
          )}
          <Row
            description={t('transfers:fee')}
            value={`${transferFee ? transferFee.feeAmount : 0} ${senderAccount?.currency.toUpperCase()}`}
          />
          <Row
            description={t('accounts:exchange.confirmationStep.youGet')}
            value={`${receivingAmount} ${receiverAccount?.currency.toUpperCase()}`}
          />
          {errorText && <ApiErrorText>{errorText}</ApiErrorText>}
        </VStack>
        <Box pt={3}>
          <Text
            textStyle={TextStyles.Caption12Regular}
            color="primary.350"
            textAlign="justify"
          >
            <Trans
              i18nKey="accounts:exchange.confirmationStep.noteDescription"
              ns={['accounts']}
              values={{
                noteWarning: t(
                  'accounts:exchange.confirmationStep.noteWarning',
                ),
              }}
              components={{
                warning: (
                  <Text
                    as="span"
                    textStyle={TextStyles.Caption12Medium}
                    color="primary.500"
                  />
                ),
              }}
            />
          </Text>
        </Box>
      </BankModalBody>
      <BankModalFooter>
        <Button
          w="full"
          onClick={onSubmit}
          isLoading={isPending}
          isDisabled={!(senderAccount && receiverAccount)}
        >
          {t('common:continue')}
        </Button>
      </BankModalFooter>
    </>
  );
};

export const Row: FC<{ description: string; value: string }> = ({
  description,
  value,
}) => (
  <HStack justifyContent="space-between" width="full">
    <Text textStyle={TextStyles.tables} whiteSpace="nowrap">
      {description}
    </Text>
    <Box
      style={{
        borderBottomStyle: 'dotted',
        borderBottomWidth: 2.5,
        borderBottomColor: 'primary.300',
      }}
      h={1.5}
      w="full"
    />
    <Text textStyle={TextStyles.tables} whiteSpace="nowrap">
      {value}
    </Text>
  </HStack>
);
