import { useMutation, useQuery } from '@tanstack/react-query';
import { useApi } from '../../state/api';
import { TCreateParticipantCommand } from '@payler/api/client-office';
import { useHandleToastError } from '@payler/bank-utils';

const STALE_TIME = 60_000;

export const useAddParticipantMutation = () => {
  const api = useApi();
  const handleToastError = useHandleToastError();

  return useMutation({
    mutationKey: ['addParticipant'],
    mutationFn: (data: TCreateParticipantCommand) => api.addParticipant(data),
    onError: handleToastError,
  });
};

export const useParticipantFields = (accountId?: string) => {
  const api = useApi();

  return useQuery({
    queryKey: ['participant', 'fields', accountId],
    queryFn: () => (!!accountId ? api.getParticipantFields(accountId) : null),
    staleTime: STALE_TIME,
    enabled: !!accountId,
  });
};
