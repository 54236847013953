import { TCurrency } from '../types';

/**
 * TODO: нужно перейти на получение с бека
 * Добавлено только из-за того, что логика крипты завязана на поля isCrypto,network
 * @deprecated
 */
export const ALL_CURRENCIES: {
  id: TCurrency;
  name: string;
  currencyCode: Uppercase<TCurrency>;
  isCrypto?: boolean;
  network?: string;
}[] = [
  {
    id: 'eur',
    name: 'Euro',
    currencyCode: 'EUR',
  },
  {
    id: 'gbp',
    name: 'Pound Sterling',
    currencyCode: 'GBP',
  },
  {
    id: 'usd',
    name: 'Dollar',
    currencyCode: 'USD',
  },
  {
    id: 'cad',
    name: 'Canadian Dollar',
    currencyCode: 'CAD',
  },
  {
    id: 'chf',
    name: 'Swiss Franc',
    currencyCode: 'CHF',
  },
  {
    id: 'dkk',
    name: 'Danish Krone',
    currencyCode: 'DKK',
  },
  {
    id: 'nok',
    name: 'Norwegian Krone',
    currencyCode: 'NOK',
  },
  {
    id: 'pln',
    name: 'Polish Zloty',
    currencyCode: 'PLN',
  },
  {
    id: 'sek',
    name: 'Swedish Krona',
    currencyCode: 'SEK',
  },
  {
    id: 'cny',
    name: 'Chinese yuan renminbi',
    currencyCode: 'CNY',
  },
  // Cryptocurrencies
  {
    id: 'btc',
    name: 'Bitcoin',
    currencyCode: 'BTC',
    isCrypto: true,
    network: 'Bitcoin',
  },
  {
    id: 'eth',
    name: 'Ethereum',
    currencyCode: 'ETH',
    isCrypto: true,
    network: 'Ethereum',
  },
  {
    id: 'usdt-erc',
    name: 'Tether (ERC-20)',
    currencyCode: 'USDT-ERC',
    isCrypto: true,
    network: 'Ethereum',
  },
  {
    id: 'usdt-trc',
    name: 'Tether (TRC-20)',
    currencyCode: 'USDT-TRC',
    isCrypto: true,
    network: 'Tron',
  },
  {
    id: 'trx',
    name: 'Tron - TRX',
    currencyCode: 'TRX',
    isCrypto: true,
    network: 'Tron',
  },
];

/**
 * TODO: нужно перейти на получение с бека
 * Добавлено только из-за того, что логика крипты завязана на поля isCrypto,network
 * @deprecated
 */
export const namedCurrencyOptions: Array<{ label: string; value: TCurrency }> =
  [
    {
      label: 'Pound Sterling - GBP',
      value: 'gbp',
    },
    { label: 'Euro - EUR', value: 'eur' },
    { label: 'Dollar - USD', value: 'usd' },
    { label: 'Canadian Dollar - CAD', value: 'cad' },
    { label: 'Swiss Franc - CHF', value: 'chf' },
    { label: 'Danish Krone - DKK', value: 'dkk' },
    { label: 'Norwegian Krone - NOK', value: 'nok' },
    { label: 'Polish Zloty - PLN', value: 'pln' },
    { label: 'Swedish Krona - SEK', value: 'sek' },
    { label: 'Chinese Yuan - CNY', value: 'cny' },
    { label: 'Bitcoin - BTC', value: 'btc' },
    { label: 'Ethereum - ETH', value: 'eth' },
    { label: 'Tether (ERC-20) - USDT_ERC', value: 'usdt-erc' },
    { label: 'Tether (TRC-20) - USDT_TRC', value: 'usdt-trc' },
    { label: 'Tron - TRX', value: 'trx' },
  ];

/**
 * TODO: нужно перейти на получение с бека
 * Добавлено только из-за того, что логика крипты завязана на поля isCrypto,network
 * @deprecated
 */
export const NAMED_CRYPTO_CURRENCY_OPTIONS: Array<{
  label: string;
  value: TCurrency;
}> = [
  { label: 'Bitcoin - BTC', value: 'btc' },
  { label: 'Ethereum - ETH', value: 'eth' },
  { label: 'Tether (ERC-20) - USDT_ERC', value: 'usdt-erc' },
  { label: 'Tether (TRC-20) - USDT_TRC', value: 'usdt-trc' },
  { label: 'Tron - TRX', value: 'trx' },
];
