import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { AccountInfo } from '../../components/AccountInfo/AccountInfo';
import {
  AllAccountsDrawer,
  useShowAllAccountsDrawer,
} from '../../components/AllAccountsDrawer/AllAccountsDrawer';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import { useLayoutContext } from '../../context/LayoutContextProvider';
import { IncomingTransferInfoDrawer } from '../../drawers/IncomingTransferInfoDrawer/IncomingTransferInfoDrawer';
import { OutgoingTransferInfoDrawer } from '../../drawers/OutgoingTransferInfoDrawer/OutgoingTransferInfoDrawer';
import {
  useAccountByUrl,
  useAccountsQuery,
} from '../../hooks/accounts/queries';
import { ContentLayout } from '../../layouts/ContentLayout/ContentLayout';
import { AccountStatementModal } from '../../modals/AccountStatement';
import { AddAccountModal } from '../../modals/AddAccount';
import { EditAccountNameModal } from '../../modals/EditAccountName';
import { TransferModal } from '../../modals/TransferModal/TransferModal';
import { useTranslation } from 'react-i18next';
import { TextStyles } from '@payler/ui-theme';
import { AddMoneyModal } from '../../modals/AddMoney/AddMoney';
import { ExchangeModal } from '../../modals/Exchange/ExchangeModal';
import { FxTransferInfoDrawer } from '../../drawers/FxTransferInfoDrawer/FxTransferInfoDrawer';
import { OnboardingProgressBlock } from '../../components/OnboardingProgressBlock/OnboardingProgressBlock';
import { usePermissions } from '../../hooks/use-permissions';
import { ActivityFilteredTable } from '../../tables/ActivityFilteredTable/ActivityFilteredTable';
import { ConvertToCNYModal } from '../../modals/ConvertToCNY/ConvertToCNYModal';
import { useClientStatus } from '../../hooks/clients/helpers';
import { useShowAddAccountModal } from '../../modals/AddAccount/AddAccount';
import { IonIcon } from '@ionic/react';
import { addCircle, grid } from 'ionicons/icons';
import { useBaasProviderCurrenciesQuery } from '../../hooks/currencies/queries';

export const AccountsPage = () => {
  const { isMobile, isTablet } = useLayoutContext();

  return (
    <React.Suspense fallback={<LoaderBox variant="table" />}>
      <ContentLayout>
        <Flex justifyContent="center">
          <VStack
            w={isMobile ? '100%' : isTablet ? '448px' : '600px'}
            maxW="600px"
            spacing={2}
          >
            <AccountsBody />
          </VStack>
          <EditAccountNameModal />
          <AccountStatementModal />
          <AddAccountModal />
          <AddMoneyModal />
          <ExchangeModal />
          <ConvertToCNYModal />
        </Flex>
      </ContentLayout>
    </React.Suspense>
  );
};

const AccountsBody: React.FC = () => {
  const currentAccount = useAccountByUrl();
  const { isGlobalAccounts } = usePermissions();

  return (
    <>
      <Header />
      {!isGlobalAccounts && <OnboardingProgressBlock />}
      <ClientWarning />
      <AccountWarning />
      <AccountInfo />
      <Activity />
      <AllAccountsDrawer />
      <IncomingTransferInfoDrawer />
      <OutgoingTransferInfoDrawer />
      <FxTransferInfoDrawer />
      <TransferModal initialSenderAccount={currentAccount} />
    </>
  );
};

const Activity: React.FC = () => {
  const { t } = useTranslation(['accounts']);
  return (
    <VStack spacing={1} w="inherit">
      <Flex w="full" justifyContent="space-between">
        <Text textStyle={TextStyles.BodyText16Semibold} color="primary.500">
          {t('accounts:activity')}
        </Text>
      </Flex>
      <ActivityFilteredTable />
    </VStack>
  );
};

const ClientWarning = () => {
  const { t } = useTranslation(['accounts']);
  const { isActive: isActiveClient, isPreActive: isPreActiveClient } =
    useClientStatus();
  const { isGlobalAccounts } = usePermissions();

  if (isActiveClient || isPreActiveClient || !isGlobalAccounts) {
    return null;
  }

  return (
    <Alert
      variant="left-accent"
      status="error"
      borderColor="red.500"
      bgColor="red.100"
      borderRadius={1}
      px={2}
      py={1.5}
    >
      <AlertIcon color="red.500" w="24px" h="24px" mr={1.5} />
      <Text textStyle={TextStyles.BodyUI14Regular} color="primary.500">
        {t('accounts:allAccountsRestricted')}
      </Text>
    </Alert>
  );
};

const AccountWarning = () => {
  const account = useAccountByUrl();
  const accountWarnings = account?.alerts || [];

  if (!accountWarnings.length) return null;

  return (
    <VStack w="inherit" spacing={1}>
      {accountWarnings.map((warning, index) => (
        <Alert
          key={index}
          variant="left-accent"
          status="error"
          borderColor="red.500"
          bgColor="red.100"
          borderRadius={1}
          px={2}
          py={1.5}
        >
          <AlertIcon color="red.500" w="24px" h="24px" mr="12px" />
          <Text textStyle={TextStyles.BodyUI14Regular} color="primary.500">
            {warning}
          </Text>
        </Alert>
      ))}
    </VStack>
  );
};

const Header = () => {
  const { t } = useTranslation(['menu', 'accounts']);
  const openAddAccountModal = useShowAddAccountModal();
  const openAllAccountsDrawer = useShowAllAccountsDrawer();
  const { data: accounts } = useAccountsQuery();
  const { data: baasProviderData } = useBaasProviderCurrenciesQuery();
  const isAddAccountButtonVisible =
    baasProviderData && baasProviderData.currencies.length > 0;
  const { isMobile } = useLayoutContext();

  const ResponsiveStack = isMobile ? VStack : HStack;
  return (
    <ResponsiveStack
      w="100%"
      {...(!isMobile && { justifyContent: 'space-between' })}
    >
      <Text as="h2" textStyle={TextStyles.h2} color="primary.500">
        {t('menu:accounts')}
      </Text>
      <HStack
        spacing={1}
        {...(isMobile && {
          mt: 2,
          w: '100%',
          flexWrap: 'wrap',
        })}
      >
        {accounts && accounts.length > 1 && (
          <Button
            variant="secondary"
            size="sm"
            onClick={openAllAccountsDrawer}
            data-testid="button_open-all-accounts-drawer"
            leftIcon={<Icon as={IonIcon} icon={grid} w="16px" h="16px" />}
            border="none"
            {...(isMobile && {
              flex: 1,
            })}
          >
            {t('accounts:allAccountsCounted', { quantity: accounts.length })}
          </Button>
        )}
        {isAddAccountButtonVisible && (
          <Button
            variant="primary"
            size="sm"
            onClick={openAddAccountModal}
            data-testid="button_open-add-account"
            leftIcon={<Icon as={IonIcon} icon={addCircle} w="16px" h="16px" />}
            {...(isMobile && {
              flex: 1,
            })}
          >
            {t('accounts:addAccount')}
          </Button>
        )}
      </HStack>
    </ResponsiveStack>
  );
};
