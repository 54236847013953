export const fields = {
  dateFrom: 'Date from',
  dateTo: 'Date to',
  accountName: 'Account name',
  currency: 'Currency',
  account: 'Account',
  type: 'Type',
  system: 'System',
  status: 'Status',
  amountFrom: 'Amount from',
  amountTo: 'Amount to',
  minAmount: 'Min amount',
  maxAmount: 'Max amount',
  startDate: 'Start date',
  endDate: 'End date',
  paramsMapping: {
    CreatedFrom: 'Date from',
    CreatedTo: 'Date to',
    AccountId: 'Account',
    Currency: 'Currency',
    AmountFrom: 'Amount from',
    AmountTo: 'Amount to',
    Type: 'Type',
    System: 'System',
    States: 'Status',
  },
  accountNumber: 'Account Number',
  bankCode: 'Bank Code',
  iban: 'IBAN',
  bic: 'BIC',
  ibanOrAccountNumber: 'Iban or Account Number',
  bicOrBankCode: 'BIC or Bank Code',
  bankName: 'Bank Name',
  bankCountry: 'Bank Country',
  sortCode: 'Sort Code',
  network: 'Network',
  walletId: 'Wallet Id',
  memo: 'Memo',
  companyName: 'Company Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  country: 'Country',
  postalCode: 'Postal Code',
  city: 'City',
  street: 'Street',
  ukAccountNumber: 'UK Account number',
  paymentMethod: 'Payment method',
} as const;
