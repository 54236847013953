import { useClientQuery } from './clients/queries';
import { useCompanyInfo } from '../config/ConfigProvider';
import { getClientName } from '../helpers/clients';

export const useSupportLink = () => {
  const { data } = useClientQuery();
  const { supportEmail, supportEmailSubject } = useCompanyInfo();

  return `mailto:${supportEmail}?subject=${encodeURIComponent(
    supportEmailSubject ?? `${getClientName(data)}: business account problem`,
  )}`;
};
