import {
  ParticipantField,
  TCredentialFieldName,
  TLegalEntityFieldName,
} from '@payler/api/client-office';

import { DropdownField, FloatingInputField } from '@payler/ui-components';
import { useTranslation } from 'react-i18next';
import { COUNTRY_OPTIONS } from '../../../../const';

export const useCredentialFormFields = (
  credentialFieldNames?: TCredentialFieldName[],
): JSX.Element[] => {
  const { t } = useTranslation('fields');

  if (!credentialFieldNames || credentialFieldNames.length === 0) {
    return [];
  }

  return credentialFieldNames.reduce((acc, fieldName) => {
    switch (fieldName) {
      case 'iban':
        return [
          ...acc,
          <FloatingInputField
            name={ParticipantField.iban}
            label={t('iban')}
            key="iban"
          />,
        ];
      case 'ibanOrAccountNumber':
        return [
          ...acc,
          <FloatingInputField
            name={ParticipantField.ibanOrAccountNumber}
            label={t('ibanOrAccountNumber')}
            key="ibanOrAccountNumber"
          />,
        ];
      case 'accountNumber':
        return [
          ...acc,

          <FloatingInputField
            name={ParticipantField.accountNumber}
            label={t('accountNumber')}
            key="accountNumber"
          />,
        ];
      case 'bic':
        return [
          ...acc,
          <FloatingInputField
            name={ParticipantField.bic}
            label={t('bic')}
            key="bic"
          />,
        ];
      case 'bicOrBankCode':
        return [
          ...acc,
          <FloatingInputField
            name={ParticipantField.bicOrBankCode}
            label={t('bicOrBankCode')}
            key="bicOrBankCode"
          />,
        ];
      case 'bankCode':
        return [
          ...acc,
          <FloatingInputField
            name={ParticipantField.bankCode}
            label={t('bankCode')}
            key="bankCode"
          />,
        ];
      case 'sortCode':
        return [
          ...acc,
          <FloatingInputField
            name={ParticipantField.sortCode}
            label={t('sortCode')}
            key="sortCode"
          />,
        ];
      case 'bankName':
        return [
          ...acc,
          <FloatingInputField
            name={ParticipantField.bankName}
            label={t('bankName')}
            key="bankName"
          />,
        ];
      case 'bankCountry':
        return [
          ...acc,
          <DropdownField
            options={COUNTRY_OPTIONS}
            fieldName={ParticipantField.bankCountry}
            label={t('bankCountry')}
            floating
            isSearchable
            autocomplete="none"
          />,
        ];
      case 'walletId':
        return [
          ...acc,
          <FloatingInputField
            name={ParticipantField.walletId}
            label={t('walletId')}
            key="walletId"
          />,
        ];
      case 'network':
        return [
          ...acc,
          <FloatingInputField
            name={ParticipantField.network}
            label={t('network')}
            key="network"
          />,
        ];
      case 'memo':
        return [
          ...acc,
          <FloatingInputField
            name={ParticipantField.memo}
            label={t('memo')}
            key="memo"
          />,
        ];
      default:
        return acc;
    }
  }, [] as JSX.Element[]);
};

export const useLegalEntitiesFormFields = (
  entityFieldNames?: TLegalEntityFieldName[],
): JSX.Element[] => {
  const { t } = useTranslation('fields');
  if (!entityFieldNames || entityFieldNames.length === 0) {
    return [];
  }

  return entityFieldNames.reduce((acc, fieldName) => {
    switch (fieldName) {
      case 'companyName':
        return [
          ...acc,
          <FloatingInputField
            name={ParticipantField.companyName}
            label={t('companyName')}
            key="companyName"
          />,
        ];
      case 'firstName':
        return [
          ...acc,
          <FloatingInputField
            name={ParticipantField.firstName}
            label={t('firstName')}
            key="firstName"
          />,
        ];
      case 'lastName':
        return [
          ...acc,
          <FloatingInputField
            name={ParticipantField.lastName}
            label={t('lastName')}
            key="lastName"
          />,
        ];
      case 'country':
        return [
          ...acc,
          <DropdownField
            options={COUNTRY_OPTIONS}
            fieldName={ParticipantField.country}
            label={t('country')}
            floating
            isSearchable
            autocomplete="none"
          />,
        ];
      case 'postalCode':
        return [
          ...acc,
          <FloatingInputField
            name={ParticipantField.postalCode}
            label={t('postalCode')}
            key="postalCode"
          />,
        ];
      case 'city':
        return [
          ...acc,
          <FloatingInputField
            name={ParticipantField.city}
            label={t('city')}
            key="city"
          />,
        ];
      case 'street':
        return [
          ...acc,
          <FloatingInputField
            name={ParticipantField.street}
            label={t('street')}
            key="street"
          />,
        ];
      default:
        return acc;
    }
  }, [] as JSX.Element[]);
};
