import React, { PropsWithChildren, FC, useMemo } from 'react';
import {
  DrawerBody,
  DrawerHeader,
  HStack,
  VStack,
  Text,
  Box,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { Alert, NoData, ThinDivider } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import { BankDrawer } from '../../components/BankDrawer/BankDrawer';
import { BankDrawerContent } from '../../components/BankDrawer/BankDrawerContent';
import { LoaderBox } from '@payler/ui/client-office';
import {
  useSetFxTransferIdToUrl,
  useFxTransferIdFromUrl,
  useFxTransferByUrl,
} from '../../hooks/transfers/queries';
import { useLanguageFeatures } from '@payler/ui/client-office';
import { useTranslation } from 'react-i18next';
import { CrossIcon } from '@payler/ui-icons';
import { InfoItem } from '../helpers/InfoItem';
import { TransferStatementButton } from '../../components/TransferStatementButton/TransferStatementButton';
import { isNil } from 'lodash';
import { getTransferStatus } from '../helpers/get-transfer-status';
import { ListIcon } from '../../components/ListIcon/ListIcon';

export const FxTransferInfoDrawer = () => {
  const fxTransferId = useFxTransferIdFromUrl();
  const isOpen = !!fxTransferId;
  const setFxTransferId = useSetFxTransferIdToUrl();

  return (
    <BankDrawer isOpen={isOpen} onClose={setFxTransferId}>
      <BankDrawerContent drawerId="fx-transfer-info-drawer">
        <React.Suspense fallback={<LoaderBox />}>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <ThinDivider />
          <Body />
        </React.Suspense>
      </BankDrawerContent>
    </BankDrawer>
  );
};

const HeaderWrapper: FC<PropsWithChildren> = ({ children }) => {
  const transfer = useFxTransferByUrl();
  const setFxTransferId = useSetFxTransferIdToUrl();

  return (
    <DrawerHeader p={3}>
      <HStack spacing={2} justifyContent="space-between">
        {children}
        <HStack spacing={2}>
          {transfer?.id && <TransferStatementButton transferId={transfer.id} />}
          <IconButton
            icon={<CrossIcon />}
            aria-label="close"
            variant="secondary"
            w={6}
            h={6}
            onClick={() => {
              setFxTransferId();
            }}
            data-testid="button_drawer-close"
          />
        </HStack>
      </HStack>
    </DrawerHeader>
  );
};

const Header = () => {
  const { serverDatetimeToDisplay } = useLanguageFeatures();
  const { t } = useTranslation('accounts');
  const transfer = useFxTransferByUrl();

  if (!transfer) {
    return <Box />;
  }

  return (
    <HStack spacing={2}>
      <Flex alignItems="center" position="relative">
        <ListIcon status={transfer.state} type="fx" />
      </Flex>
      <VStack spacing={0} alignItems="baseline">
        <Text textStyle={TextStyles.h4} color="primary.500">
          {t('exchange.selectStep.exchangeDirection', {
            senderCurrency: transfer.senderSide.currency.toUpperCase(),
            receiverCurrency: transfer.recipientSide.currency.toUpperCase(),
          })}
        </Text>
        <Text textStyle={TextStyles.Subtitle14Regular} color="primary.350">
          {serverDatetimeToDisplay(transfer?.created, 'datetime')}
        </Text>
      </VStack>
    </HStack>
  );
};

const Body = () => {
  const { t } = useTranslation('transfers');
  const transfer = useFxTransferByUrl();
  const { formatAmountByCurrency, serverDatetimeToDisplay } =
    useLanguageFeatures();

  const amountCredited = useMemo(() => {
    if (transfer) {
      return formatAmountByCurrency(
        transfer.recipientSide.totalAmount || 0,
        transfer.recipientSide.currency,
      );
    }
    return '';
  }, [transfer, formatAmountByCurrency]);

  const amountDebited = useMemo(() => {
    if (transfer) {
      return formatAmountByCurrency(
        transfer.senderSide.amount || 0,
        transfer.senderSide.currency,
      );
    }
    return '';
  }, [transfer, formatAmountByCurrency]);

  const transferAlertStatus = getTransferStatus(transfer?.state);

  const exchangeRateValue = useMemo(() => {
    const currencyInfo = {
      senderCurrency: transfer?.senderSide.currency.toUpperCase(),
      recipientCurrency: transfer?.recipientSide.currency.toUpperCase(),
      exchangeRate: transfer?.exchangeRate,
    };
    return transfer?.state === 'completed'
      ? t('certainExchangeRate', currencyInfo)
      : t('approximateExchangeRate', currencyInfo);
  }, [transfer, transferAlertStatus]);

  const alertLabel =
    transferAlertStatus === 'warning' ? t('important') : t('error');

  if (!transfer) {
    return <NoData />;
  }

  return (
    <DrawerBody p={3}>
      <VStack spacing={2} alignItems="stretch">
        {transfer.reason && transferAlertStatus && (
          <Alert
            status={transferAlertStatus}
            label={alertLabel.toUpperCase()}
            description={transfer.reason}
          />
        )}
        <InfoItem
          label={t('date')}
          value={serverDatetimeToDisplay(transfer?.created, 'datetime')}
        />
        <InfoItem label={t('amountCredited')} value={amountCredited} />
        <InfoItem label={t('amountDebited')} value={amountDebited} />
        {transfer.exchangeRate && (
          <InfoItem label={t('exchangeRate')} value={exchangeRateValue} />
        )}
        {!isNil(transfer.recipientSide.feeAmount) && (
          <InfoItem
            label={t('fee')}
            value={formatAmountByCurrency(
              transfer.recipientSide.feeAmount,
              transfer.recipientSide.currency,
            )}
          />
        )}
        <InfoItem label={t('status')} value={t(`statuses.${transfer.state}`)} />
        {transfer.sender?.legalEntity?.type === 'business' && (
          <InfoItem
            label={t('chargeAccount')}
            value={transfer.sender.legalEntity.companyName}
          />
        )}
        {transfer.recipient?.legalEntity?.type === 'business' && (
          <InfoItem
            label={t('depositAccount')}
            value={transfer.recipient.legalEntity.companyName}
          />
        )}
      </VStack>
    </DrawerBody>
  );
};
