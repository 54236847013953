import { Navigate, RouteObject } from 'react-router-dom';
import {
  NotFoundPageLazy,
  ProfilePageLazy,
  AccountsPageLazy,
  ActivityPageLazy,
} from '../components/lazy-components';
import BaseLayout from '../layouts/BaseLayout/BaseLayout';
import { SideNavMenu } from '../components/SideNavMenu/SideNavMenu';
import { TMenuLinkItem } from '../components/Menu/Menu';

export const getAppRoutesDefaultConfig = (
  links: TMenuLinkItem[],
): RouteObject[] => [
  {
    path: '/',
    element: <BaseLayout menu={<SideNavMenu links={links} />} />,
    children: [
      { index: true, element: <AccountsPageLazy /> },
      { path: 'activity', element: <ActivityPageLazy /> },
      { path: 'profile', element: <ProfilePageLazy /> },
      { path: 'onboarding', element: <Navigate to="/" replace /> },
      { path: '*', element: <NotFoundPageLazy /> },
    ],
  },
];
